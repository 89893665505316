import {Theme} from '../model/theme';

export const DARK_THEME_CLASS = 'dark';
export const LIGHT_THEME_CLASS = 'light';
export const GOOFY_LAYOUT_CLASS = 'goofy';

export class AppearanceUtils {

  public static setTheme(theme: Theme, bodyElement: HTMLBodyElement): void {
    switch (theme) {
      case Theme.DARK:
        this.safeClassRemove(LIGHT_THEME_CLASS, bodyElement.classList);
        bodyElement.classList.add(DARK_THEME_CLASS);
        break;
      case Theme.LIGHT:
        this.safeClassRemove(DARK_THEME_CLASS, bodyElement.classList);
        bodyElement.classList.add(LIGHT_THEME_CLASS);
        break;
      default:
        this.safeClassRemove(LIGHT_THEME_CLASS, bodyElement.classList);
        this.safeClassRemove(DARK_THEME_CLASS, bodyElement.classList);
    }
    this.removeClassAttributeIfEmpty(bodyElement);
  }

  public static setGoofyLayout(isGoofyLayout: boolean, bodyElement: HTMLBodyElement): void {
    if (isGoofyLayout) {
      bodyElement.classList.add(GOOFY_LAYOUT_CLASS);
    } else {
      this.safeClassRemove(GOOFY_LAYOUT_CLASS, bodyElement.classList);
    }
    this.removeClassAttributeIfEmpty(bodyElement);
  }

  private static removeClassAttributeIfEmpty(bodyElement: HTMLBodyElement): void {
    if (bodyElement.classList.length === 0) {
      bodyElement.removeAttribute('class');
    }
  }

  private static safeClassRemove(className: string, classList: DOMTokenList): void {
    if (classList.contains(className)) {
      classList.remove(className);
    }
  }

}
