import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AuthStorage} from '../core/auth/auth-storage';
import {AuthConfig} from '../core/auth/auth-config';
import {map, Observable} from 'rxjs';
import {ChatService} from '../core/component/chat/chat.service';
import {Router} from '@angular/router';
import {AlarmsWebSocketService} from '../core/ws/alarms-web-socket.service';
import {ChatWebSocketService} from '../core/ws/chat-web-socket.service';
import {AssetsStateWebSocketService} from '../core/ws/assets-state/assets-state-web-socket.service';
import {PasswordReminderRequest} from './password-reminder/model/password-reminder.request';

@Injectable()
export class AuthService {

  constructor(private http: HttpClient,
              private chatService: ChatService,
              private alarmsWSService: AlarmsWebSocketService,
              private chatWSService: ChatWebSocketService,
              private assetsStateWsService: AssetsStateWebSocketService,
              private router: Router) {
  }

  public remindPassword(request: PasswordReminderRequest, locale: string, token: string): Observable<string> {
    let headers = new HttpHeaders().set('reCaptcha-token', token);
    return this.http.post<string>(`/api/auth/password-remind?locale=${locale}`, request, {headers: headers});
  }

  public updateTokens(): Observable<string> {
    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization', 'Basic ' + AuthConfig.CLIENT)
      .set('Accept', 'application/json');
    let request = 'grant_type=refresh_token&refresh_token=' + AuthStorage.refreshToken();
    return this.http.post<string>('/oauth/token', request, {headers: headers})
      .pipe(map(data => {
          AuthStorage.persistTokens(data['access_token'], data['refresh_token']);
          AuthStorage.persistPasswordExpiration(data['password-expired'])
          if (data['requires-relog'] === true) {
            this.logOut();
          }
          if (data['password-expired']) {
            this.router.navigate([AuthConfig.CHANGE_PASSWORD_ROUTE]);
          }
          return AuthStorage.accessToken();
        }
      ))
  }

  public loginAfterVerification(request: any): Observable<Object> {
    return this.http.post<Object>(`/api/auth/verify-code?verificationCode`, request)
  }

  public logOut(): void {
    AuthStorage.clearTokens();
    this.chatService.closeChat();
    this.alarmsWSService.disconnect();
    this.chatWSService.disconnect();
    this.assetsStateWsService.disconnect();
    this.router.navigate([AuthConfig.LOGIN_ROUTE]);
  }

  public isPasswordExpired(): Observable<boolean> {
    return this.http.get<boolean>('/api/auth/is-password-expired');
  }
}
