import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormGroup, Validators} from '@angular/forms';
import {BugType} from '../../../theme/components/bug-message/model/bug.type';
import {dateValidation, phoneNumberValidator, vehicleManufactureYearValidation} from '../../validators';

const controls = ['plateNumber', 'driverName', 'phoneNumber', 'vehicleModel', 'vehicleManufactureDate',
  'simCardInGpsDevice', 'tachoModel', 'tachoNewGen', 'companyCard', 'companyCardExpirationDate',
  'tachoRegistrationDate', 'tabletModel', 'androidVersion', 'verificationCode'];

@Component({
  selector: 'app-bug-type',
  styleUrls: ['./bug-type.component.scss'],
  templateUrl: './bug-type.component.html',
})
export class BugTypeComponent implements OnInit {

  @Input()
  public bugType: BugType;

  @Input()
  public form: UntypedFormGroup;

  constructor() {
  }

  ngOnInit(): void {
    this.handleValidators(this.bugType);
  }

  private handleValidators(bugType: BugType): void {
    this.removeValidators(this.form);
    switch (bugType) {
      case BugType.TAB_DEVICE:
        this.form.controls['plateNumber'].setValidators([Validators.required, Validators.maxLength(255)]);
        this.form.controls['phoneNumber'].setValidators(
          [Validators.required, Validators.maxLength(255), phoneNumberValidator]
        );
        this.form.controls['androidVersion'].setValidators([Validators.required, Validators.maxLength(255)]);
        break;
      case BugType.DEVICE_UNAVAILABLE:
        this.form.controls['plateNumber'].setValidators([Validators.required, Validators.maxLength(255)]);
        this.form.controls['vehicleModel'].setValidators([Validators.required, Validators.maxLength(255)]);
        this.form.controls['vehicleManufactureDate'].setValidators([
          Validators.required,
          vehicleManufactureYearValidation]);
        break;
      case BugType.DDD_FILES:
        this.form.controls['plateNumber'].setValidators([Validators.required, Validators.maxLength(255)]);
        this.form.controls['vehicleModel'].setValidators([Validators.required, Validators.maxLength(255)]);
        this.form.controls['vehicleManufactureDate'].setValidators([
          Validators.required,
          vehicleManufactureYearValidation]);
        this.form.controls['phoneNumber'].setValidators(
          [Validators.required, Validators.maxLength(255), phoneNumberValidator]
        );
        this.form.controls['driverName'].setValidators([Validators.required, Validators.maxLength(255)]);
        this.form.controls['tachoModel'].setValidators([Validators.required]);
        this.form.controls['tachoNewGen'].setValidators([Validators.required]);
        this.form.controls['companyCard'].setValidators([Validators.required, Validators.maxLength(255)]);
        this.form.controls['companyCardExpirationDate'].setValidators([
          Validators.required,
          Validators.maxLength(255),
          dateValidation]);
        this.form.controls['tachoRegistrationDate'].setValidators([
          Validators.required,
          Validators.maxLength(255),
          dateValidation]);
        break;
      default:
        break;
    }
  }

  private removeValidators(form: UntypedFormGroup): void {
    controls.forEach(it => form.get(it).clearValidators())
  }
}
