import {Component, Input, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {ChatVehicleListEntry} from '../chat-vehicle-list.entry';
import {ScanFileEntry} from './scan-file.entry';
import {ScanListService} from './scan-list.service';
import {NotificationService} from '../../../notification/notification.service';
import {FilesFilter} from './files.filter';
import {ChatService} from '../chat.service';
import {ChatUtils} from '../chat.utils';
import {HttpClient} from '@angular/common/http';
import {PicturePdfRequest} from './picture-pdf.request';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {Subject, Subscription} from 'rxjs';
import {EnumValues} from 'enum-values';
import {takeUntil} from 'rxjs/operators';
import {SubscriptionUtils} from '../../../commons/subscription.utils';
import {FileUtils, PDF} from '../../../commons/file.utils';

export enum senderType {
  MANAGER, DRIVER
}

@Component({
  selector: 'app-scan-list',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './scan-list.component.html',
  styleUrls: ['./scan-list.component.scss']
})
export class ScanListComponent implements OnInit, OnDestroy {

  @Input('scanListInfo')
  set scanListInfo(info: ChatVehicleListEntry) {
    if (info.plateNumber !== '' && info.id !== 0) {
      if (this.fileUri == null) {
        this.getChatFilesUri();
      }
      this._scanListInfo = info;
    }
  }

  private _scanListInfo: ChatVehicleListEntry;

  public filter: FilesFilter = FilesFilter.empty();
  public filterForm: UntypedFormGroup;
  public fileUri: string;

  public files: ScanFileEntry[] = [];
  public filteredFiles: ScanFileEntry[] = [];
  public pictureFiles: ScanFileEntry[] = [];

  public throttle = 300;
  public scrollUpDistance = 1;
  private page = 1;

  public modalVisible: number;
  public senderType: string[] = EnumValues.getNames(senderType);

  private docsSub: Subscription;
  private subSubject = new Subject<boolean>();

  constructor(private scanListService: ScanListService,
              private chatService: ChatService,
              private notification: NotificationService,
              private http: HttpClient,
              private fb: UntypedFormBuilder) {

    this.filterForm = this.fb.group({
      'fileName': [''],
      'dateFrom': [''],
      'dateTill': [''],
      'sender': ['']
    })
  }

  public ngOnInit() {
    this.getChatFilesUri();
    this.docsSub = this.chatService.openScanList()
      .pipe(takeUntil(this.subSubject))
      .subscribe(
        (data) => {
          this._scanListInfo = data;
          this.loadList();
        }
      );
  }

  public filterFiles($event: any): void {
    this.filteredFiles = this.files
      .filter(v => v.fileName
        .toLowerCase()
        .indexOf($event.toLowerCase()) !== -1);
  }

  public onScrolledDown() {
    this.loadMoreFiles();
  }

  private loadMoreFiles() {
    this.page += 1;
    this.getFiles(this.page);
  }

  public loadList(): void {
    this.files = [];
    this.page = 1;
    this.filter = this.filterForm.value;
    this.getFiles(this.page);
  }

  public markItem(file: ScanFileEntry): void {
    let markedEl = document.getElementsByClassName('marked');
    if (markedEl.length > 0) {
      markedEl.item(0).classList.remove('marked');
    }
    document.getElementById('file-' + file.id).classList.add('marked');
  }

  public markToDownload(file: ScanFileEntry): void {
    file.marked = true;
  }

  public unmarkToDownload(file: ScanFileEntry): void {
    file.marked = false;
  }

  public isThereMarkedFiles(): boolean {
    return this.files.filter(file => file.marked).length > 0;
  }

  public downloadFiles(): void {
    let link = document.createElement('a');
    link.setAttribute('download', null);
    link.style.display = 'none';
    document.body.appendChild(link);
    for (let markedFile of this.files.filter(file => file.marked)) {
      this.chatService.generateToken(markedFile.vehicleId, markedFile.id)
        .pipe(takeUntil(this.subSubject))
        .subscribe(
          (data) => {
            let plateNumber = this._scanListInfo.plateNumber.replace(/[^a-zA-Z0-9]/g, '_');
            link.setAttribute('href', `/api/chat-files/${data.token}/${plateNumber}/download`);
            link.click();
            markedFile.marked = false;
          },
          (err) => this.notification.loadingError()
        );
    }
    setTimeout(() => document.body.removeChild(link), 10000);
  }

  public downloadPictureBunch(): void {
    let markedFiles = this.files.filter(file => file.marked);
    let pictures = markedFiles.filter(file => this.isPicture(file.path));
    let notPictures = markedFiles.filter(file => !this.isPicture(file.path));
    let currentTime = moment().format('YYYY-MM-DD_HH:mm');

    this.chatService.generateToken(this.files[0].vehicleId, this.files[0].id)
      .pipe(takeUntil(this.subSubject))
      .subscribe(
        (data) => this.chatService.getPicturesAsPdf(
          data.token,
          pictures.map(pic =>
            new PicturePdfRequest(pic.id, pic.fileName)),
          this._scanListInfo.plateNumber)
          .pipe(takeUntil(this.subSubject))
          .subscribe(
            (blob) => {
              let plateNumber = this._scanListInfo.plateNumber.replace(/[^a-zA-Z0-9]/g, '_');
              FileUtils.saveFile(blob, `${currentTime}-${plateNumber}`, PDF);
            },
            (err) => this.notification.loadingError()
          ),
        (err) => this.notification.loadingError()
      );
    this.getNotPictures(notPictures);
  }

  private getFiles(page: number) {
    this.scanListService.fetchFiles(this._scanListInfo.id, page, this.filter)
      .pipe(takeUntil(this.subSubject))
      .subscribe(
        (data) => {
          this.files = this.files.concat(data);
          this.pictureFiles = this.files
            .filter(file => this.isPicture(file.path));
        },
        (err) => this.notification.loadingError()
      );
  }

  public isPicture(file: string): boolean {
    if (file.length > 3) {
      let extension = file.substring(file.lastIndexOf('.'), file.length);
      return ChatUtils.PICTURE_EXTENSION.includes(extension);
    } else {
      return false;
    }
  }

  public downloadFile(vehicleId: number, fileId: number, fileName: string) {
    let plateNumber = this._scanListInfo.plateNumber.replace(/[^a-zA-Z0-9]/g, '_');
    let currentTime = moment().format('YYYY-MM-DD_HH:mm');
    let name = fileName != null
      ? plateNumber + '-' + fileName
      : currentTime + '-' + plateNumber
    this.chatService.generateToken(vehicleId, fileId)
      .pipe(takeUntil(this.subSubject))
      .subscribe(
        (data) => {
          this.chatService.downloadFile(data.token, plateNumber)
            .pipe(takeUntil(this.subSubject))
            .subscribe({
              next: data => FileUtils.saveFile(data, name)
            })
        },
        (err) => this.notification.loadingError()
      );
  }

  public downloadPictureAsPdf(vehicleId: number, fileName: string, fileId: number) {
    let plateNumber = this._scanListInfo.plateNumber;
    let picture: PicturePdfRequest[] = [];
    let currentTime = moment().format('YYYY-MM-DD_HH:mm');
    picture[0] = new PicturePdfRequest(fileId, fileName);
    this.chatService.generateToken(vehicleId, fileId)
      .pipe(takeUntil(this.subSubject))
      .subscribe(
        (data) => this.chatService.getPicturesAsPdf(data.token, picture, plateNumber)
          .pipe(takeUntil(this.subSubject))
          .subscribe(
            (blob) => {
              plateNumber.replace(/[^a-zA-Z0-9]/g, '_');
              FileUtils.saveFile(blob, `${currentTime}-${plateNumber}`, PDF);
            },
            (err) => this.notification.loadingError()
          ),
        (error) => this.notification.loadingError()
      );
  }

  public showModal(id: number): void {
    this.modalVisible = id;
  }

  public closeModal(): void {
    this.modalVisible = null;
  }

  public nextPicture(): void {
    if (this.modalVisible) {
      let fileIndex = this.pictureFiles.findIndex(file => file.id === this.modalVisible);
      for (++fileIndex; fileIndex < this.pictureFiles.length; fileIndex++) {
        if (fileIndex === this.pictureFiles.length - 1) {
          this.loadMoreFiles();
        }
        this.modalVisible = this.pictureFiles[fileIndex].id;
        break;
      }
    }
  }

  public previousPicture(): void {
    if (this.modalVisible) {
      let fileIndex = this.pictureFiles.findIndex(file => file.id === this.modalVisible);
      for (--fileIndex; fileIndex > -1; fileIndex--) {
        this.modalVisible = this.pictureFiles[fileIndex].id;
        break;
      }
    }
  }

  private getNotPictures(notPictures) {
    let plateNumber = this._scanListInfo.plateNumber.replace(/[^a-zA-Z0-9]/g, '_');
    let link = document.createElement('a');
    link.setAttribute('download', null);
    link.style.display = 'none';
    document.body.appendChild(link);
    for (let notPic of notPictures) {
      this.chatService.generateToken(notPic.vehicleId, notPic.id)
        .pipe(takeUntil(this.subSubject))
        .subscribe(
          (data) => {
            link.setAttribute('href', `/api/chat-files/${data.token}/${plateNumber}/download`);
            link.click();
            notPic.marked = false;
          },
          (err) => this.notification.loadingError()
        );
    }
    setTimeout(() => document.body.removeChild(link), 10000);

  }

  public expandDatePickerInput() {
    const textSearch = jQuery('.textSearch');
    const dateFrom = jQuery('.dateFrom');
    const dateTill = jQuery('.dateTill');
    const sender = jQuery('.sender');
    if (sender.hasClass('expanded-sender')) {
      sender.removeClass('expanded-sender');
      textSearch.removeClass('hidden');
    }
    dateFrom.toggleClass('expanded');
    dateTill.toggleClass('expanded');
    textSearch.toggleClass('hidden');
  }

  public expandSenderInput() {
    const textSearch = jQuery('.textSearch');
    const dateFrom = jQuery('.dateFrom');
    const dateTill = jQuery('.dateTill');
    const sender = jQuery('.sender');
    if (dateFrom.hasClass('expanded') || dateTill.hasClass('expanded')) {
      dateFrom.removeClass('expanded');
      dateTill.removeClass('expanded');
      textSearch.removeClass('hidden');
    }
    sender.toggleClass('expanded-sender');
    textSearch.toggleClass('hidden');
  }

  private getChatFilesUri() {
    this.chatService.getChatFilesUri()
      .pipe(takeUntil(this.subSubject))
      .subscribe(res => this.fileUri = res);
  }

  public ngOnDestroy(): void {
    SubscriptionUtils.safeUnsubscribe(this.docsSub);
    SubscriptionUtils.safeUnsubscribeSubject(this.subSubject);
  }

}
