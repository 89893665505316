import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable()
export class GeoHistoryStateService {

  private toggleGeoHistorySource = new Subject<string>();
  public toggleGeoHistory$ = this.toggleGeoHistorySource.asObservable();

  constructor() {
  }

  public toggleGeoHistory(latLng: string): void {
    this.toggleGeoHistorySource.next(latLng);
  }

}
