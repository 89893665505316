import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {WarningsConfigsForm} from './warnings-configs.form';

@Injectable()
export class WarningsConfigsService {

  constructor(private http: HttpClient) {
  }

  public fetch(): Observable<WarningsConfigsForm> {
    return this.http.get<WarningsConfigsForm>('/api/configs/warnings');
  }

  public update(form: WarningsConfigsForm): Observable<void> {
    return this.http.put<void>('/api/configs/warnings', form);
  }

}
