import {Injectable} from '@angular/core';

@Injectable()
export class MapInstanceService {

  private mapInstance: google.maps.Map;
  private secondMapInstance: google.maps.Map;

  public instance(parentElement, options: google.maps.MapOptions): google.maps.Map {
    if (!this.mapInstance) {
      this.mapInstance = new google.maps.Map(parentElement, options);
    } else {
      this.mapInstance.setOptions(options);
      jQuery(parentElement).replaceWith(this.mapInstance.getDiv());
    }
    return this.mapInstance;
  }

  public secondInstance(parentElement, options): google.maps.Map {
    if (!this.secondMapInstance) {
      this.secondMapInstance = new google.maps.Map(parentElement, options);
    } else {
      this.secondMapInstance.setOptions(options);
      jQuery(parentElement).replaceWith(this.secondMapInstance.getDiv());
    }
    return this.secondMapInstance;
  }

}
