import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {DocumentEntry} from './model/document.entry';
import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpUtils} from '../../page/commons/http.utils';


@Injectable()
export class DocumentsService {

  constructor(private http: HttpClient) {
  }


  public deleteDoc(id: number): Observable<Object> {
    return this.http.delete(`/api/documents?id=${id}`, {});
  }

  /*                                            Vehicles                                              */

  public saveVehicleDoc(vehicleId: number, file: any, name: string, important: boolean, showToDriver: boolean): Observable<any> {
    let headers = new HttpHeaders();
    let formData = new FormData();
    formData.append('file', file, file.name);
    return this.http.post(
      `/api/documents/vehicle?vehicle=${vehicleId}&name=${name}&important=${important}&showToDriver=${showToDriver}`,
      formData,
      {headers: headers});
  }

  public fetchVehicleDocs(vehicleId: number): Observable<DocumentEntry[]> {
    return this.http.get<DocumentEntry[]>(`/api/documents/vehicle-files?vehicle=${vehicleId}`);
  }

  public downloadVehicleDoc(id: any, documentType: string) {
    return this.http.post(`/api/documents/download-vehicle?id=${id}&documentType=${documentType}`, {}, {responseType: 'blob'})
      .pipe(map(res => <Blob>res));
  }

  public switchShowToDriver(fileId: number, showToDriver: boolean): Observable<void> {
    let params = new HttpParams();
    params = HttpUtils.appendParam(params, 'showToDriver', showToDriver);
    params = HttpUtils.appendParam(params, 'fileId', fileId);
    let headers = new HttpHeaders();
    return this.http.post<void>(`/api/documents/vehicle-file/switch-show-to-driver`, {}, {params: params});
  }

  /*                                            Groups                                                */

  public saveGroupDoc(groupId: number, file: any, name: string, important: boolean, showToDriver: boolean): Observable<any> {
    let headers = new HttpHeaders();
    let formData = new FormData();
    formData.append('file', file, file.name);
    return this.http.post(
      `/api/documents/group?group=${groupId}&name=${name}&important=${important}&showToDriver=${showToDriver}`,
      formData,
      {headers: headers});
  }

  public fetchGroupDocs(groupId: number): Observable<DocumentEntry[]> {
    return this.http.get<DocumentEntry[]>(`/api/documents/group-files?group=${groupId}`);

  }

  public downloadGroupDoc(id: number, documentType: string) {
    return this.http.post(`/api/documents/download-group?id=${id}&documentType=${documentType}`, {}, {responseType: 'blob'})
      .pipe(map(res => <Blob>res));
  }

  public fetchDocumentUri(documentType: string): Observable<string> {
    return this.http.get(`/api/documents/document-file-uri?documentType=${documentType}`, {responseType: 'text'})

  }

}
