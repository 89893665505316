import {Injectable} from '@angular/core';
import {DeviceDetectorService} from 'ngx-device-detector';

@Injectable()
export class DeviceService {

  constructor(private deviceService: DeviceDetectorService) {
  }

  public isSafariBrowser(): boolean {
    return this.deviceService.browser === 'Safari';
  }

  public isMobile(): boolean {
    return this.deviceService.isMobile();
  }

  public isChromeBrowser(): boolean {
    return this.deviceService.browser === 'Chrome';
  }

  public isEdgeChromiumBrowser(): boolean {
    return this.deviceService.browser === 'MS-Edge-Chromium';
  }

}
