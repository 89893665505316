import {Injectable} from "@angular/core";
import {Observable, Subject} from "rxjs";

@Injectable()
export class LoaderService {

  private loaderSource = new Subject<boolean>();
  private loader$ = this.loaderSource.asObservable();

  constructor() {
  }

  public setLoaderState(newState: boolean) {
    this.loaderSource.next(newState);
  }

  public isLoaderActive(): Observable<boolean> {
    return this.loader$;
  }

}
