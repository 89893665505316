import {Injectable} from "@angular/core";
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Observable} from "rxjs";
import {LoaderService} from "./loader.service";
import {finalize} from "rxjs/operators";

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {

  private totalRequests = 0;
  private timer: number;

  constructor(private loaderService: LoaderService) {
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!req.headers.has('skip')) {
      this.totalRequests++;
      this.clearTimer();
      this.timer = setTimeout(() => this.loaderService.setLoaderState(true), 500);
      return next.handle(req).pipe(
        finalize(() => {
          this.totalRequests--;
          if (this.totalRequests <= 0) {
            this.totalRequests = 0;
            this.clearTimer();
            this.loaderService.setLoaderState(false);
          }
        })
      );
    }
    req = req.clone({
      headers: req.headers.delete('skip')
    });
    return next.handle(req);
  }

  private clearTimer() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }

}
