export let warningsConfigsForm: any = {
  showVehicleServiceWarnings: true,
  showLateNotesWarnings: true,
  showTrailerWarnings: true
};

export class WarningsConfigsForm {
  constructor(public showVehicleServiceWarnings: boolean,
              public showLateNotesWarnings: boolean,
              public showTrailerWarnings: boolean) {
  }

  public static defaultValues(): WarningsConfigsForm {
    return new WarningsConfigsForm(
      true,
      true,
      true
    )
  }
}
