<header class="app-header navbar navbar-toggleable-lg">
  <button class="navbar-toggler navbar-toggler-right"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNavDropdown"
          aria-controls="navbarNavDropdown"
          aria-expanded="false"
          aria-label="Toggle navigation">
    <i class="app-header__menu-toggler"></i>
  </button>

  <a class="app-header__logo" (click)="getDefaultPage()" *ngIf="logoPath != null">
    <img id="logo" src="{{logoPath}}" alt="" class="hidden-sm-down" />
    <img *ngIf="shouldShowLogo" src="assets/img/logo/fleethand-logo-symb--ua.svg" alt="" class="hidden-md-up" />
  </a>

  <section class="app-header__main-nav navbar-menu collapse navbar-collapse" id="navbarNavDropdown">
    <app-menu></app-menu>
  </section>

  <ul class="app-header__notification-nav navbar-nav no-print">
    <li *ngIf="!isMobile" class="nav-item hidden-sm-down">
      <a class="help-trigger" (click)="triggerUserPilot()" tabindex="1">
        <i class="fa fa-question"></i>
      </a>
    </li>
    <li class="nav-item hidden-sm-down">
      <app-bug-window></app-bug-window>
    </li>
    <li class="nav-item">
      <app-language-select></app-language-select>
    </li>
    <li class="nav-item nav-item--has-indicator" *ngIf="isClientLogged">
      <app-notifications></app-notifications>
    </li>
    <li class="nav-item nav-item--has-indicator" *ngIf="hasChat && !webView">
      <div class="chat-notifications no-print">
        <a href class="dropdown-toggle info"
           title="{{'navbar.chat' | translate}}"
           data-toggle="dropdown"
           aria-expanded="false"
           (click)="toggleChat()">

          <i class="fa fa-comment-o"></i>
          <div class="notification__indicator">
            <span>
              <div>{{currentCount}}</div>
              <div>{{currentFileCount}}</div>
            </span>
            <div class="pulsate"></div>
          </div>
        </a>
      </div>
    </li>
    <li class="nav-item">
      <div class="dropdown notification-menu__user">
        <a class="dropdown-toggle user-link"
           data-toggle="dropdown"
           href="#"
           role="button"
           aria-expanded="false">

          <i class="app-header__user-userpic"></i>

          <span class="hidden-lg-down">
            {{loggedUser.fullName}} <span *ngIf="clients">({{clients.currentClient}})</span>
          </span>
        </a>

        <nav class="dropdown-menu">
          <ng-container *ngIf="clients">
            <a *ngFor="let client of clients.availableClients"
               class="dropdown-item"
               (click)="switchProfile(client.id)">
              <i *ngIf="clients.currentClient === client.value" class="fa fa-check"></i>
              {{client.value}}
            </a>
          </ng-container>

          <a class="dropdown-item"
             routerLink="/profile">
            <i class="fa fa-id-badge"></i>
            {{'navbar.profile' | translate}}
          </a>

          <a class="dropdown-item"
             routerLink="/configs/data"
             *ngIf="isClientLogged">
            <i class="fa fa-cog"></i>
            {{'navbar.settings' | translate}}
          </a>

          <div (click)="handleSupportLine($event)">
            <a href="http://wiki.fleethand.com/index.php?title=Main_Page"
               title="{{'navbar.help' | translate}}"
               class="dropdown-item"
               id="wiki"
               target="_blank">
              <i class="fa fa-life-ring"></i>
              {{'navbar.help' | translate}}
            </a>
            <a class="dropdown-item instructions"
               id="instruction"
               *ngIf="loggedUser.showInstructions"
               href="assets/instructions/fh_instruction_ru.pdf"
               target="_blank">
              <i class="fa fa-book"></i>
              {{'navbar.instructions' | translate}}
            </a>
            <span class="dropdown-item" *ngIf="customerSupport">
              <span class="info-contacts">
                <i class="fa fa-phone-square"></i>
                {{'navbar.support' | translate}}:
                <span *ngFor="let support of customerSupport">
                  {{support}}
                </span>
              </span>
            </span>
          </div>

          <a class="dropdown-item"
             (click)="logout()">
            <i class="fa fa-power-off"></i>
            {{'navbar.logout' | translate}}
          </a>

          <span class="dropdown-aside">
            <span class="info-version">
              {{version}}
            </span>
          </span>
        </nav>
      </div>
    </li>
  </ul>
</header>
