<ng-container *ngIf="bugType === 'TAB_DEVICE' || bugType === 'DEVICE_UNAVAILABLE' || bugType === 'DDD_FILES'">
  <div class="form-group" [formGroup]="form">
    <label for="plateNumber">
      {{'messages.bug-report.plateNumber' | translate}}
      <span class="required-field">*</span>
    </label>
    <input type="text"
           id="plateNumber"
           class="form-control"
           formControlName="plateNumber">
    <app-control-messages [control]="form.controls.plateNumber"></app-control-messages>
  </div>
</ng-container>

<ng-container *ngIf="bugType === 'DEVICE_UNAVAILABLE' || bugType === 'DDD_FILES'">
  <div class="form-group" [formGroup]="form">
    <label for="vehicleModel">
      {{'messages.bug-report.vehicleModel' | translate}}
      <span class="required-field">*</span>
    </label>
    <input type="text"
           id="vehicleModel"
           class="form-control"
           formControlName="vehicleModel">
    <app-control-messages [control]="form.controls.vehicleModel"></app-control-messages>
  </div>
</ng-container>

<ng-container *ngIf="bugType === 'DEVICE_UNAVAILABLE' || bugType === 'DDD_FILES'">
  <div class="form-group" [formGroup]="form">
    <label for="vehicleManufactureDate">
      {{'messages.bug-report.vehicleManufactureDate' | translate}}
      <span class="required-field">*</span>
    </label>
    <input type="text"
           id="vehicleManufactureDate"
           class="form-control"
           formControlName="vehicleManufactureDate">
    <app-control-messages [control]="form.controls.vehicleManufactureDate"></app-control-messages>
  </div>
</ng-container>

<ng-container *ngIf="bugType === 'TAB_DEVICE' || bugType === 'DDD_FILES'">
  <div class="form-group" [formGroup]="form">
    <label for="phoneNumber">
      {{'messages.bug-report.phoneNumber' | translate}}
      <span class="required-field">*</span>
    </label>
    <input type="text"
           id="phoneNumber"
           class="form-control"
           formControlName="phoneNumber">
    <app-control-messages [control]="form.controls.phoneNumber"></app-control-messages>
  </div>
</ng-container>

<ng-container *ngIf="bugType === 'TAB_DEVICE'">
  <div class="form-group" [formGroup]="form">
    <label for="tabletModel">
      {{'messages.bug-report.tabletModel' | translate}}
      <span class="required-field">*</span>
    </label>
    <input type="text"
           id="tabletModel"
           class="form-control"
           formControlName="tabletModel">
    <app-control-messages [control]="form.controls.tabletModel"></app-control-messages>
  </div>
</ng-container>

<ng-container *ngIf="bugType === 'TAB_DEVICE'">
  <div class="form-group" [formGroup]="form">
    <label for="androidVersion">
      {{'messages.bug-report.androidVersion' | translate}}
      <span class="required-field">*</span>
    </label>
    <input type="text"
           id="androidVersion"
           class="form-control"
           formControlName="androidVersion">
    <app-control-messages [control]="form.controls.androidVersion"></app-control-messages>
  </div>

  <div class="form-group" [formGroup]="form">
    <label class="custom-control custom-checkbox">
      <input class="custom-control-input checkbox-primary"
             type="checkbox"
             formControlName="tabletByFleethand"
             id="tabletByFleethand"/>
      <span class="custom-control-indicator"></span>
      <span class="custom-control-description">{{'messages.bug-report.tabletByFleethand' | translate}}</span>
    </label>
  </div>
</ng-container>

<ng-container *ngIf="bugType === 'DEVICE_UNAVAILABLE'">
  <div class="form-group" [formGroup]="form">
    <label class="custom-control custom-checkbox">
      <input class="custom-control-input checkbox-primary checkbox-circle"
             type="checkbox"
             formControlName="vehicleInMountains"
             id="vehicleInMountains"/>
      <span class="custom-control-indicator"></span>
      <span class="custom-control-description">{{'messages.bug-report.vehicleInMountains' | translate}}</span>
    </label>
    <label class="custom-control custom-checkbox">
      <input class="custom-control-input checkbox-primary checkbox-circle"
             type="checkbox"
             formControlName="vehicleInFerry"
             id="vehicleInFerry"/>
      <span class="custom-control-indicator"></span>
      <span class="custom-control-description">{{'messages.bug-report.vehicleInFerry' | translate}}</span>
    </label>
  </div>
</ng-container>

<ng-container *ngIf="bugType === 'DEVICE_UNAVAILABLE' || bugType === 'TAB_DEVICE'">
  <div class="form-group" [formGroup]="form">
    <label>
      {{'messages.bug-report.simCardInGpsDevice' | translate}}
      <span class="required-field mr-2">*</span>
    </label>
    <br>
    <label class="custom-control custom-radio">
      <input class="custom-control-input radio-primary"
             formControlName="simCardInGpsDevice"
             name="simCardInGpsDevice"
             [value]="'Fleethand'"
             type="radio">
      <span class="custom-control-indicator"></span>
      <span class="custom-control-description">{{'messages.bug-report.simCardFleethand' | translate}}</span>
    </label>
    <label class="custom-control custom-radio">
      <input class="custom-control-input radio-primary"
             formControlName="simCardInGpsDevice"
             name="simCardInGpsDevice"
             [value]="'Client'"
             type="radio"
             checked>
      <span class="custom-control-indicator"></span>
      <span class="custom-control-description">{{'messages.bug-report.simCardClient' | translate}}</span>
    </label>
  </div>
</ng-container>

<ng-container *ngIf="bugType === 'DDD_FILES'">
  <div class="form-group" [formGroup]="form">
    <label for="driverName">
      {{'messages.bug-report.driverNameCard' | translate}}
      <span class="required-field">*</span>
    </label>
    <input type="text"
           id="driverName"
           class="form-control"
           formControlName="driverName">
    <app-control-messages [control]="form.controls.driverName"></app-control-messages>
  </div>

  <div class="form-group" [formGroup]="form">
    <label>
      {{'messages.bug-report.tachoModel' | translate}}
      <span class="required-field mr-2">*</span>
    </label>
    <label class="custom-control custom-radio">
      <input class="custom-control-input radio-primary"
             formControlName="tachoModel"
             name="tachoModel"
             [value]="'VDO'"
             type="radio">
      <span class="custom-control-indicator"></span>
      <span class="custom-control-description">{{'messages.bug-report.tachoModelVdo' | translate}}</span>
    </label>
    <label class="custom-control custom-radio">
      <input class="custom-control-input radio-primary"
             formControlName="tachoModel"
             name="tachoModel"
             [value]="'Stoneridge'"
             type="radio"
             checked>
      <span class="custom-control-indicator"></span>
      <span class="custom-control-description">{{'messages.bug-report.tachoModelStoneridge' | translate}}</span>
    </label>
  </div>

  <div class="form-group" [formGroup]="form">
    <label>
      {{'messages.bug-report.tachoNewGen.title' | translate}}
      <span class="required-field mr-2">*</span>
    </label>
    <label class="custom-control custom-radio">
      <input class="custom-control-input radio-primary"
             formControlName="tachoNewGen"
             name="tachoNewGen"
             [value]="true"
             type="radio">
      <span class="custom-control-indicator"></span>
      <span class="custom-control-description">{{'messages.bug-report.tachoNewGen.true' | translate}}</span>
    </label>
    <label class="custom-control custom-radio">
      <input class="custom-control-input radio-primary"
             formControlName="tachoNewGen"
             name="tachoNewGen"
             [value]="false"
             type="radio"
             checked>
      <span class="custom-control-indicator"></span>
      <span class="custom-control-description">{{'messages.bug-report.tachoNewGen.false' | translate}}</span>
    </label>
  </div>

  <div class="form-group" [formGroup]="form">
    <label for="companyCard">
      {{'messages.bug-report.companyCard' | translate}}
      <span class="required-field">*</span>
    </label>
    <input type="text"
           id="companyCard"
           class="form-control"
           formControlName="companyCard">
    <app-control-messages [control]="form.controls.companyCard"></app-control-messages>
  </div>

  <div class="form-group" [formGroup]="form">
    <label for="companyCardExpirationDate">
      {{'messages.bug-report.companyCardExpirationDate' | translate}}
      <span class="required-field">*</span>
    </label>
    <input type="text"
           id="companyCardExpirationDate"
           class="form-control"
           formControlName="companyCardExpirationDate">
    <app-control-messages [control]="form.controls.companyCardExpirationDate"></app-control-messages>
  </div>

  <div class="form-group" [formGroup]="form">
    <label for="tachoRegistrationDate">
      {{'messages.bug-report.tachoRegistrationDate' | translate}}
      <span class="required-field">*</span>
    </label>
    <input type="text"
           id="tachoRegistrationDate"
           class="form-control"
           formControlName="tachoRegistrationDate">
    <app-control-messages [control]="form.controls.tachoRegistrationDate"></app-control-messages>
  </div>
</ng-container>

<ng-container *ngIf="bugType === 'SERVICES'">
  <div class="form-group" [formGroup]="form"></div>
</ng-container>

<ng-container *ngIf="bugType === 'OTHER'">
  <div class="form-group" [formGroup]="form">
    <label>{{'messages.bug-report.otherMessage' | translate}}</label>
  </div>
</ng-container>
