import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {VehicleGroupConfigsForm} from './model/vehicle-group-configs.form';
import {VehicleConfigsGroupEntry} from './model/vehicle-configs-group.entry';
import {HttpClient} from '@angular/common/http';
import {GroupEntry} from '../../../../../core/model/group.entry';

@Injectable()
export class VehicleGroupConfigsService {

  private changeGroupStateSource = new Subject<string>();
  private changeFormState$ = this.changeGroupStateSource.asObservable();

  constructor(private http: HttpClient) {
  }

  public changeGroupState(state: string): void {
    this.changeGroupStateSource.next(state);
  }

  public changeGroupStateListener(): Observable<string> {
    return this.changeFormState$;
  }

  public upsert(form: VehicleGroupConfigsForm): Observable<void> {
    return this.http.post<void>('/api/eco/configs', form);
  }

  public loadGroups(): Observable<VehicleConfigsGroupEntry[]> {
    return this.http.get<VehicleConfigsGroupEntry[]>('/api/eco/configs/groups');
  }

  public loadForm(groupId: number): Observable<VehicleGroupConfigsForm> {
    return this.http.get<VehicleGroupConfigsForm>(`/api/eco/configs/form?groupId=${groupId}`);
  }

  public delete(groupId: number): Observable<void> {
    return this.http.delete<void>(`/api/eco/configs?groupId=${groupId}`);
  }

  public groups(): Observable<GroupEntry[]> {
    return this.http.get<GroupEntry[]>('/api/eco/configs/groups-vehicles');
  }

}
