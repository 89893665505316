import {Injectable} from '@angular/core';
import {OlRouteLeg} from './ol-route-leg';
import {Observable, Subject} from 'rxjs';
import {OlLegPoint} from './ol-leg-point';

@Injectable()
export class OlRouteService {

  private olRoute: Map<number, OlRouteLeg> = new Map<number, OlRouteLeg>();

  private olRouteSource = new Subject<Map<number, OlRouteLeg>>();
  private olRoute$ = this.olRouteSource.asObservable();

  private olRouteReturnSource = new Subject<Map<number, OlRouteLeg>>();
  private olRouteReturn$ = this.olRouteReturnSource.asObservable();

  private olDirectionsSource = new Subject<any>();
  private olDirections$ = this.olDirectionsSource.asObservable();

  private removePointSource = new Subject<any>();
  private removePoint$ = this.removePointSource.asObservable();

  private removeWayPointSource = new Subject<any>();
  private removeWayPoint$ = this.removeWayPointSource.asObservable();

  private removeRouteMarkerSource = new Subject<any>();
  private removeRouteMarker$ = this.removeRouteMarkerSource.asObservable();

  private focusInputSource = new Subject<any>();
  private focusInput$ = this.focusInputSource.asObservable();

  private initMapSource = new Subject<any>();
  private initMap$ = this.initMapSource.asObservable();

  constructor() {
    this.getEmptyRoute();
  }

  public getOlRoute() {
    return this.olRoute;
  }

  public setOlRoute(route: Map<number, OlRouteLeg>) {
    this.olRoute = route;
    this.triggerRouteChange();
  }

  public addStartPoint(data: OlLegPoint) {
    this.olRoute.get(1).origin = data;
    this.triggerRouteChange();
  }

  public routeReturn(routeReturn: Map<number, OlRouteLeg>) {
    if (routeReturn) {
      this.olRoute = routeReturn;
    } else {
      this.olRoute.clear();
      this.getEmptyRoute();
    }
    this.olRouteReturnSource.next(routeReturn);
  }

  private getEmptyRoute() {
    this.olRoute.set(1, OlRouteLeg.empty());
  }


  public getOlRouteDirections(): Observable<Map<number, OlRouteLeg>> {
    return this.olRoute$
  }

  public triggerRouteChange() {
    this.olRouteSource.next(this.olRoute);
  }

  public getRouteReturn(): Observable<Map<number, OlRouteLeg>> {
    return this.olRouteReturn$;
  }

  public olDirection(directions: any): void {
    this.olDirectionsSource.next(directions);
  }

  public getOlDirections(): Observable<any> {
    return this.olDirections$;
  }

  public removePoint(key: number) {
   this.removePointSource.next(key)
  }

  public getRemovedPoints(): Observable<any> {
    return this.removePoint$
  }

  public removeWayPoint(indexes: number[]) {
    this.removeWayPointSource.next(indexes)
  }

  public removedWayPoints(): Observable<number[]> {
    return this.removeWayPoint$;
  }

  public removeRouteMarker(id: number) {
    this.removeRouteMarkerSource.next(id);
  }

  public removedRouteMarker(): Observable<number> {
    return this.removeRouteMarker$;
  }

  public focusInput(id: number) {
    this.focusInputSource.next(id);
  }

  public focusedInput(): Observable<number> {
    return this.focusInput$;
  }

  public initMap(map: any): void {
    this.initMapSource.next(map);
  }

  public getMapInit(): Observable<any> {
    return this.initMap$;
  }
}
