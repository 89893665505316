export class PlaceSource {

  public static GOOGLE_ADDRESS = 'GOOGLE_ADDRESS';
  public static GOOGLE_PLACES = 'GOOGLE_PLACES';
  public static GOOGLE_PLACES_PASTE = 'GOOGLE_PLACES_PASTE';
  public static GOOGLE_COORDINATES = 'GOOGLE_COORDINATES';
  public static VEHICLES = 'VEHICLES';
  public static DRIVERS = 'DRIVERS';
  public static POI = 'POI';
  public static PELIAS_ADDRESS = 'PELIAS_ADDRESS';
  public static PELIAS_COORDINATES = 'PELIAS_COORDINATES';
  public static NOMINATIM_PLACES = 'NOMINATIM_PLACES';
  public static NOMINATIM_COORDINATES = 'NOMINATIM_COORDINATES';
  public static ARCGIS_ADDRESSES = 'ARCGIS_ADDRESSES';
  public static GEONAMES = 'GEONAMES';
  public static ORDER_POINTS = 'ORDER_POINTS'

}
