import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {ReportOrderEntry} from '../orders/report-order.entry';
import {HttpUtils} from '../../../../core/page/commons/http.utils';

@Injectable()
export class ReportOrderingService {

  constructor(private http: HttpClient) {
  }

  public getOrders(type: string): Observable<ReportOrderEntry[]> {
    let params = new HttpParams();
    params = HttpUtils.appendParam(params, 'type', type);
    return this.http.get<ReportOrderEntry[]>('/api/report-ordering', {params: params});
  }

  public download(orderId: number): Observable<Blob> {
    return this.http.get(`/api/report-ordering/download?orderId=${orderId}`, {responseType: 'blob'})
      .pipe(map(res => <Blob>res));
  }

}
