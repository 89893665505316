<div class="chat-container" id="chat-g-container">
  <div class="chat__content message-list" id="message-g-list">
    <div *ngFor="let message of messages" class="message">
      <div class="message-container message-container--manager">
        <div class="message-body" [ngClass]="{'init-message': message.drawNewBox}">
          <div class="msg__heading" *ngIf="message.drawNewBox">
            <div class="msg__user-info" title="{{message.sender}}">
              <h6 class="msg__user-name">
                {{message.sender}}
              </h6>
            </div>
          </div>
          <div class="message-text" title="{{message.sentOn | date: 'yyyy-MM-dd HH:mm'}}">
            <pre>{{message.messageText}}</pre>
            <a class="message-text__action"
               *ngIf="message.file != '' && isPicture(message)"
               (click)="showFile(message)">
               <i class="ico-paperclip"></i>
             </a>
          </div>
          <img id="image-{{message.id}}"
               class="scan-doc collapse"
               src="{{fileUri + message.file}}"
               *ngIf="message.file != '' && isPicture(message)"
               (click)="showModal(message.id)">
          <app-picture-modal *ngIf="modalVisible === message.id"
                             (close)="closeModal()"
                             [fileUri]="fileUri"
                             [content]="message.file"
                             [caption]="message.messageText">
          </app-picture-modal>
        </div>
        <div class="msg__user-type">
          <i *ngIf="message.drawNewBox" class="ico-user chat-user-img"></i>
        </div>
      </div>
    </div>
  </div>
  <div class="chat__input">
    <div class="chat-templates" *ngIf="messagesTemplatesVisible" id="chat-g-templates">
      <div *ngFor="let message of messagesTemplates"
           class="chat-templates__message"
           (click)="setMessage(message)">
        <span>{{message}}</span>
      </div>
      <div class="chat-templates__info--empty" *ngIf="messagesTemplates == null || messagesTemplates.length == 0">
        <small>{{'chat.messages.no-templates' | translate}}</small>
      </div>
      <div class="chat-templates__add" (click)="navigateToSettings()">
        <i class="fa fa-plus"></i>
        {{'chat.messages.create-templates' | translate}}
      </div>
    </div>
    <div class="uploaded-file" id="upload-g-file">
      <i class="ico-paperclip" *ngIf="file"></i>
      <input type="text" value="{{file.name}}" *ngIf="file" appAutocompleteOff>
      <i class="ico-close delete-file" (click)="removeFile()" *ngIf="file"></i>
    </div>
    <div class="input-group chat-text" id="chat-g-text">
      <textarea type="text"
                class="form-control"
                rows="1"
                id="text-g-input"
                placeholder="{{'chat.messages.text' | translate}}"
                (paste)="onPaste($event)"
                (keyup.enter)="addChatItem()"
                [(ngModel)]="newChatText">
      </textarea>
      <div class="input__buttons" id="text-buttons">
        <a class="input__button templates-toggle"
           title="{{'chat.messages.templates' | translate}}"
           (click)="showMessagesTemplates()"
           [ngClass]="{'input__button--selected': messagesTemplatesVisible}"
            id="messages-templates">
          <i class="fa fa-list-ul"></i>
        </a>
        <a class="input__button send" id="send-g-button"
           (click)="addChatItem()">
           <i class=" fa fa-paper-plane"></i>
        </a>
        <div class="input__button fa fa-upload upload">
          <input id="upload"
                 class="file-upload-input"
                 type="file"
                 accept=".pdf,.txt,image/*,.xlsx,.xls,video/*"
                 (change)="fileChange(upload)"
                 #upload>
        </div>
      </div>
    </div>
  </div>
</div>
