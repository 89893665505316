import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {VehicleGroupForm} from './model/vehicle-group.form';
import {GroupEntry as GroupEntryModel} from '../../../core/model/group.entry';
import {HttpClient, HttpParams} from '@angular/common/http';
import {IdValue} from '../../../core/model/id-value';
import {VehicleGroupEntry, VehicleGroupType} from './model/vehicle-group.entry';

@Injectable()
export class VehicleGroupService {

  private groupSelectStateSource = new Subject<IdValue<VehicleGroupType>>();
  private groupSelectState$ = this.groupSelectStateSource.asObservable();

  private groupListStateSource = new Subject<number>();
  private groupListState$ = this.groupListStateSource.asObservable();

  constructor(private http: HttpClient) {
  }

  public changeGroupSelectState(state: IdValue<VehicleGroupType>): void {
    this.groupSelectStateSource.next(state);
  }

  public groupSelectState(): Observable<IdValue<VehicleGroupType>> {
    return this.groupSelectState$;
  }

  public updateGroupList(groupId: number): void {
    this.groupListStateSource.next(groupId);
  }

  public groupListState(): Observable<number> {
    return this.groupListState$;
  }

  public vehicleGroups(): Observable<VehicleGroupEntry[]> {
    return this.http.get<VehicleGroupEntry[]>('/api/vehicle-groups');
  }

  public create(form: VehicleGroupForm): Observable<number> {
    return this.http.post<number>('/api/vehicle-groups', form);
  }

  public editForm(id: number): Observable<VehicleGroupForm> {
    return this.http.get<VehicleGroupForm>(`/api/vehicle-groups/${id}`);
  }

  public update(id: number, form: VehicleGroupForm): Observable<GroupEntryModel> {
    return this.http.put<GroupEntryModel>(`/api/vehicle-groups/${id}`, form);
  }

  public delete(id: number): Observable<void> {
    return this.http.delete<void>(`/api/vehicle-groups/${id}`)
  }

  public validateTitle(title: string, group: number): Observable<boolean> {
    let params = new HttpParams();
    params = params.append('title', title);
    params = params.append('groupId', `${group}`);
    return this.http.get<boolean>(`/api/vehicle-groups/check-title/${group}`, {params: params});
  }
}
