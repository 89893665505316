import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {MapConfigsForm} from './map-configs.form';

@Injectable()
export class MapConfigsService {

  constructor(private http: HttpClient) {
  }

  public fetch(): Observable<MapConfigsForm> {
    return this.http.get<MapConfigsForm>('/api/configs/map');
  }

  public update(form: MapConfigsForm): Observable<any> {
    return this.http.put('/api/configs/map', form);
  }
}
