import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DirectivesModule} from '../theme/directives/directives.module';
import {PipesModule} from '../theme/pipes/pipes.module';
import {routing} from './pages.routing';
import {PagesComponent} from './pages.component';
import {MenuComponent} from '../theme/components/menu/menu.component';
import {NavbarComponent} from '../theme/components/navbar/navbar.component';
import {NotificationsComponent} from '../theme/components/notifications/notifications.component';
import {SharedModule} from '../core/shared.module';
import {NotificationService} from '../core/notification/notification.service';
import {GlobalService} from '../core/global/global.service';
import {IndexComponent} from './index.component';
import {BugComponent} from '../theme/components/bug-message/bug.component';
import {BugService} from '../theme/components/bug-message/bug.service';
import {MatBadgeModule} from '@angular/material/badge';
import {MatIconModule} from '@angular/material/icon';
import {RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module} from 'ng-recaptcha';
import {environment} from '../../environments/environment';

@NgModule({
  imports: [
    SharedModule,
    CommonModule,
    DirectivesModule,
    PipesModule,
    routing,
    MatIconModule,
    MatBadgeModule,
    RecaptchaV3Module
  ],
  declarations: [
    PagesComponent,
    MenuComponent,
    NavbarComponent,
    NotificationsComponent,
    IndexComponent,
    BugComponent
  ],
  providers: [
    GlobalService,
    NotificationService,
    BugService,
    {provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.captchaSiteKey}
  ],
})
export class PagesModule {
}
