import {Injectable} from '@angular/core';

@Injectable()
export class DirectionsService {

  public parse(json: any): google.maps.DirectionsResult {
    let geocodedWaypoints = json.geocodedWaypoints || json.geocoded_waypoints;
    return <google.maps.DirectionsResult> {
      geocoded_waypoints: geocodedWaypoints.map(it => this.parseDirectionsGeocodedWaypoint(it)),
      routes: json.routes.map(it => this.parseDirectionsRoute(it)),
      request: json.request ? this.parseDirectionsRequest(json.request) : {}
    };
  }

  // TODO geocoderStatus
  private parseDirectionsGeocodedWaypoint(json: any): google.maps.DirectionsGeocodedWaypoint {
    return {
      partial_match: json.partialMatch || json.partial_match,
      place_id: json.placeId || json.place_id,
      types: json.types
    };
  }

  private parseDirectionsRoute(json: any): google.maps.DirectionsRoute {
    let overviewPolyline = json.overviewPolyline || json.overview_polyline;
    return <google.maps.DirectionsRoute> {
      bounds: this.parseLatLngBounds(json.bounds),
      legs: json.legs.map(it => this.parseDirectionsLeg(it)),
      overview_polyline: overviewPolyline,
      overview_path: json.overview_path || this.asPath(overviewPolyline),
      warnings: json.warnings,
      waypoint_order: json.waypointOrder || json.waypoint_order
    };
  }

  private parseLatLng(json: any): google.maps.LatLng {
    return new google.maps.LatLng(json.lat, json.lng);
  }

  private parseLatLngBounds(json: any): google.maps.LatLngBounds {
    if (json.southwest && json.northeast) {
      return new google.maps.LatLngBounds(
        this.parseLatLng(json.southwest),
        this.parseLatLng(json.northeast)
      );
    } else {
      return new google.maps.LatLngBounds(
        new google.maps.LatLng(json.south, json.west),
        new google.maps.LatLng(json.north, json.east)
      );
    }
  }

  private parseDirectionsLeg(json: any): any {
    return {
      distance: this.parseDistance(json.distance),
      duration: this.parseDistance(json.duration),
      end_address: json.endAddress || json.end_address,
      end_location: this.parseLatLng(json.endLocation || json.end_location),
      start_address: json.startAddress || json.start_address,
      start_location: this.parseLatLng(json.startLocation || json.start_location),
      steps: json.steps.map(it => this.parseDirectionsStep(it)),
      via_waypoint: json.via_waypoint != null ? json.via_waypoint.map(it => this.parseViaWaypoint(it)) : [],
      via_waypoints: json.via_waypoints != null ? json.via_waypoints.map(it => this.parseLatLng(it)) : []
    };
  }

  private parseDistance(json: any): google.maps.Distance {
    return {
      text: json.humanReadable || json.text,
      value: json.inMeters || json.value
    };
  }

  private parseDuration(json: any): google.maps.Duration {
    return {
      text: json.humanReadable || json.text,
      value: json.inSeconds || json.value
    };
  }

  private parseDirectionsStep(json: any): any {
    return {
      distance: this.parseDistance(json.distance),
      duration: this.parseDuration(json.duration),
      end_location: json.endLocation || this.parseLatLng(json.end_location),
      instructions: json.htmlInstructions,
      polyline: json.polyline,
      path: this.asPath(json.polyline),
      start_location: json.startLocation || this.parseLatLng(json.start_location),
      travel_mode: json.travelMode || json.travel_mode
    };
  }

  private asPath(json: any): google.maps.LatLng[] {
    return google.maps.geometry.encoding.decodePath(json.encodedPath || json.points);
  }

  private parseDirectionsRequest(json: any): google.maps.DirectionsRequest {
    let waypoints = json.waypoints || [];
    return <google.maps.DirectionsRequest> {
      avoidFerries: json.avoidFerries,
      avoidTolls: json.avoidTolls,
      origin: this.parseLatLng(json.origin),
      destination: this.parseLatLng(json.destination),
      travelMode: json.travelMode,
      waypoints: waypoints.map(it => this.parseDirectionsWaypoint(it))
    };
  }

  private parseDirectionsWaypoint(json: any): google.maps.DirectionsWaypoint {
    return {
      location: json.location,
      stopover: json.stopover
    };
  }

  private parseViaWaypoint(json: any) {
    return {
      location: this.parseLatLng(json.location),
      step_index: json.step_index,
      step_interpolation: json.step_interpolation
    };
  }
}
