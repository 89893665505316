import {Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {BugService} from './bug.service';
import {NotificationService} from '../../../core/notification/notification.service';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {BugType} from './model/bug.type';
import {ReCaptchaV3Service} from 'ng-recaptcha';
import {TranslateService} from '@ngx-translate/core';
import {Subject} from 'rxjs';
import {SubscriptionUtils} from '../../../core/commons/subscription.utils';
import {takeUntil} from 'rxjs/operators';
import {dateValidation, vehicleManufactureYearValidation} from '../../../core/validators';

const VERIFICATION_NEEDED_ERROR = 'Verification needed';
const UNSUPPORTED_MEDIA_TYPE = 415;

@Component({
  selector: 'app-bug-window',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./bug.component.scss'],
  templateUrl: './bug.component.html',
  providers: [BugService]
})
export class BugComponent implements OnInit, OnDestroy {

  @ViewChild('bugDropdown', {static: true}) bugDropdown: ElementRef;
  @ViewChild('fileUpload') upload: ElementRef;

  public bugReportForm: UntypedFormGroup;
  public selectedType: BugType = null;
  public failedCaptcha: boolean;
  public availableFileFormats: string = '.xlsx,.xls,.pdf,image/*';
  public screenshot: File = null;
  public file: File = null;

  private subSubject = new Subject<boolean>();

  public get BugType(): typeof BugType {
    return BugType;
  }

  constructor(private bugService: BugService,
              private fb: UntypedFormBuilder,
              private notification: NotificationService,
              private reCaptchaService: ReCaptchaV3Service,
              private translate: TranslateService) {

  }

  public ngOnInit(): void {
    this.initForm();
  }

  public send(event: MouseEvent): void {
    event.stopPropagation();
    this.reCaptchaService.execute('bugMail').pipe(takeUntil(this.subSubject)).subscribe(
      (token) => {
        let formBlob = new Blob([JSON.stringify(this.bugReportForm.value)], {type: 'application/json'});
        let formData = new FormData();
        formData.append('file', this.file);
        formData.append('screenshot', this.screenshot);
        formData.append('form', formBlob);
        this.bugService.send(formData, token, this.translate.currentLang)
          .pipe(takeUntil(this.subSubject))
          .subscribe({
            next: () => {
              this.notification.sendSuccess();
              this.resetForm();
              this.bugDropdown.nativeElement.click();
            },
            error: (err) => this.parseError(err)
          });
      });
  }

  public addFile(event: MouseEvent): void {
    event.stopPropagation();
    this.upload.nativeElement.click();
  }

  public fileChange(event: any): void {
    let fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      this.file = fileList[0];
    }
  }

  public removeFile(event: any): void {
    event.stopPropagation();
    this.upload.nativeElement.value = '';
    this.file = null;
  }

  public addScreenshot(): void {
    this.getDisplayMedia()
      .then(captureStream => {
        new Promise((resolve, reject) => {
          const video = document.createElement('video');
          video.onloadedmetadata = () => {
            resolve(this.getSingleFrameAsCanvas(video))
          };
          video.srcObject = captureStream
        }).then((canvas: HTMLCanvasElement) => {
          this.cleanup(captureStream);
          canvas.toBlob((blob) => this.screenshot = new File([blob], 'screenshot.png'));
          this.bugDropdown.nativeElement.click();
        });
      }).catch(err => this.notification.error('messages.alarms.screenshot-failed'))
  }

  private getSingleFrameAsCanvas(video: HTMLVideoElement): HTMLCanvasElement {
    this.syncDelay(2000);
    video.play();
    video.pause();

    const canvas = document.createElement('canvas');
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    const context = canvas.getContext('2d');
    context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
    return canvas;
  }

  private syncDelay(milliseconds: number) {
    let start = new Date().getTime();
    let end = 0;
    while ((end - start) < milliseconds) {
      end = new Date().getTime();
    }
  }

  private getDisplayMedia(): Promise<MediaStream> {
    return (navigator.mediaDevices as any)
      .getDisplayMedia({
        video: {
          frameRate: 1
        }
      });
  }

  private cleanup(captureStream): void {
    captureStream.getTracks().forEach(track => track.stop());
    captureStream.srcObject = null;
  }

  public cancel(): void {
    this.resetForm();
  }

  public setBugType(event: MouseEvent, bugType: BugType): void {
    event.stopPropagation();
    this.selectedType = bugType;
    this.bugReportForm.patchValue({bugType});
  }

  public return(event: MouseEvent): void {
    event.stopPropagation();
    this.resetForm();
  }

  private initForm(): void {
    this.bugReportForm = this.fb.group({
      text: ['', Validators.required],
      bugType: [null],
      plateNumber: ['', [Validators.required, Validators.maxLength(255)]],
      vehicleModel: ['', [Validators.required, Validators.maxLength(255)]],
      vehicleManufactureDate: ['', [Validators.required, vehicleManufactureYearValidation]],
      phoneNumber: ['', [Validators.required, Validators.maxLength(255)]],
      tabletModel: ['', [Validators.required, Validators.maxLength(255)]],
      androidVersion: ['', [Validators.required, Validators.maxLength(255)]],
      tabletByFleethand: [false],
      vehicleInMountains: [false],
      vehicleInFerry: [false],
      simCardInGpsDevice: ['', Validators.required],
      driverName: ['', [Validators.required, Validators.maxLength(255)]],
      tachoModel: ['', Validators.required],
      tachoNewGen: [null, Validators.required],
      companyCard: ['', Validators.required],
      companyCardExpirationDate: ['', [Validators.required, Validators.maxLength(255), dateValidation]],
      tachoRegistrationDate: ['', [Validators.required, Validators.maxLength(255), dateValidation]],
      verificationCode: [{value: null, disabled: true}, Validators.required]
    });
  }

  private resetForm(): void {
    this.upload.nativeElement.value = '';
    this.file = null;
    this.screenshot = null;
    let controls = this.bugReportForm.controls;
    Object.keys(controls).forEach(it => controls[it].setValue(null));
    this.initForm();
    this.bugReportForm.controls.verificationCode.disable();
    this.failedCaptcha = false;
    this.selectedType = null;
    this.bugReportForm.markAsUntouched();
  }

  public removeScreenshot(event: MouseEvent): void {
    event.stopPropagation();
    this.screenshot = null;
  }

  private parseError(error) {
    const errorDescription = error != null && error.error != null
      ? error.error.message
      : '';
    if (errorDescription.includes(VERIFICATION_NEEDED_ERROR)) {
      this.failedCaptcha = true;
      this.bugReportForm.controls.verificationCode.enable();
    } else {
      this.failedCaptcha = false;
      this.bugReportForm.controls.verificationCode.disable();
    }
    if(error.status === UNSUPPORTED_MEDIA_TYPE) {
      this.notification.error(this.translate.instant('validation.unsupportedMediaType'));
    }
  }

  public ngOnDestroy(): void {
    SubscriptionUtils.safeUnsubscribeSubject(this.subSubject);
  }

}
