import {Subject, Subscription} from 'rxjs';

export class SubscriptionUtils {

  public static safeUnsubscribe(subscription: Subscription): void {
    if (subscription != null) {
      subscription.unsubscribe();
    }
  }

  public static safeUnsubscribeSubject(subscription: Subject<boolean>): void {
    if (subscription != null) {
      subscription.next(true)
      subscription.unsubscribe();
    }
  }

}
