import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {MarkedRoadGroupEntry} from '../../../pages/client/marked-roads/model/marked-road-group.entry';
import {HttpClient, HttpParams} from '@angular/common/http';
import {PageRestUtils} from '../../page/commons/page-rest.utils';
import {Pageable} from '../../page/pageable';
import {PageEntry} from '../../page/page.entry';
import {MarkedRoadEntry} from './marked-road.entry';

@Injectable()
export class MarkedRoadsService {

  constructor(private http: HttpClient) {
  }

  public loadRoadGroups(): Observable<PageEntry<MarkedRoadGroupEntry>> {
    let params = PageRestUtils.appendPageableParams(Pageable.default(), new HttpParams());
    return this.http.get<PageEntry<MarkedRoadGroupEntry>>(`/api/marked-roads/groups`, {
      params: params,
      headers: {skip: 'true'}
    });
  }

  public saveMarkedRoad(body: MarkedRoadEntry): Observable<void> {
    return this.http.post<void>(`/api/marked-roads`, body);
  }

  public loadMarkedRoadsByGroup(groupId: number): Observable<MarkedRoadEntry[]> {
    return this.http.get<MarkedRoadEntry[]>(`/api/marked-roads/by-group/${groupId}`);
  }

  public loadMarkedRoad(id: number): Observable<MarkedRoadEntry> {
    return this.http.get<MarkedRoadEntry>(`/api/marked-roads/${id}`);
  }

  public updateMarkedRoad(entry: MarkedRoadEntry): Observable<void> {
    return this.http.put<void>(`/api/marked-roads`, entry);
  }

  public deleteMarkedRoad(id: number): Observable<void> {
    return this.http.delete<void>(`/api/marked-roads/${id}`);
  }
}
