import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Pipe({name: 'duration'})
export class DurationPipe implements PipeTransform {

  private static readonly SECONDS_IN_MINUTES = 60;
  private static readonly SECONDS_IN_HOURS = 3600;

  constructor(private translate: TranslateService) {
  }

  public transform(value: number, args?: string[]): string {
    const durationType = args ? args[0] : null;
    if (durationType == null || durationType === 'HOURS_MINUTES') {
      return this.toHoursAndMinutes(value);
    } else if (durationType === 'MONTHS_DAYS') {
      return this.toMonthsAndDays(value);
    } else if (durationType === 'MILLS_TO_DAYS') {
      return this.fromMillsToDays(value);
    } else if (durationType === 'MINUTES_TO_HOURS') {
      return this.minutesToHours(value);
    }
  }

  private toHoursAndMinutes(value: number) {
    if (!value || value <= 0) {
      return '0' + this.translate.instant('unit.min');
    }

    if (value >= DurationPipe.SECONDS_IN_HOURS) {
      const hours = Math.floor(value / DurationPipe.SECONDS_IN_HOURS);
      const minutes = Math.floor((value % DurationPipe.SECONDS_IN_HOURS) / DurationPipe.SECONDS_IN_MINUTES);
      return `${hours}` + this.translate.instant('unit.h') + ` ${minutes}` + this.translate.instant('unit.min');
    } else if (value >= DurationPipe.SECONDS_IN_MINUTES) {
      const minutes = Math.floor(value / DurationPipe.SECONDS_IN_MINUTES);
      return `${minutes}` + this.translate.instant('unit.min');
    } else {
      return '0' + this.translate.instant('unit.min');
    }
  }

  private toMonthsAndDays(durationInDays: number) {
    const currentDate = new Date();
    const startMoment = moment(currentDate);
    const endMoment = moment(currentDate).add(durationInDays, 'days');

    const months = endMoment.diff(startMoment, 'months');

    startMoment.add(months, 'months');
    const days = endMoment.diff(startMoment, 'days');

    if (months !== 0) {
      return `${months } ` + this.translate.instant('unit.mth') + ` ${Math.abs(days)} ` + this.translate.instant('unit.d');
    } else {
      return `${days} ` + this.translate.instant('unit.d');
    }
  }

  private fromMillsToDays(durationInMills: number) {
    let days = Math.floor(durationInMills / (24 * 60 * 60 * 1000));
    let daysInMills = durationInMills % (24 * 60 * 60 * 1000);
    let hours = Math.floor((daysInMills) / (60 * 60 * 1000));
    let hoursInMills = durationInMills % (60 * 60 * 1000);
    let minutes = Math.floor((hoursInMills) / (60 * 1000));
    return `
     ${days} ${this.translate.instant('unit.d')} ${hours}
     ${this.translate.instant('unit.h')}
     ${minutes} ${this.translate.instant('unit.min')}`
  }

  private minutesToHours(minutes: number) {
    let hours = 0;
    let mins = 0;

    if (!minutes || minutes <= 0) {
      return `${hours}` + this.translate.instant('unit.h') + ` ${mins}` + this.translate.instant('unit.min');
    } else if (minutes > 0 && minutes < 60) {
      return `${hours}` + this.translate.instant('unit.h') + ` ${minutes}` + this.translate.instant('unit.min');
    }

    hours = Math.floor(minutes / 60);
    mins = Math.floor(minutes) % 60;

    return `${hours}` + this.translate.instant('unit.h') + ` ${mins}` + this.translate.instant('unit.min');
  }
}
