import {Injectable, OnDestroy} from '@angular/core';
import {Client as StompClient, over as overStomp} from 'stompjs';
import * as SockJS from 'sockjs-client';
import {GlobalService} from '../global/global.service';
import {UserWsInfo} from './model/user-ws-info';
import {ChatStatusInfo} from './model/chat-status.info';
import {ChatWsStateService} from './chat-ws-state.service';
import {Permissions} from '../auth/permissions';
import {Module} from '../model/profile';
import {Subject, Subscription} from 'rxjs';
import {debounceTime, delay, retryWhen} from 'rxjs/operators';

const isWebview = require("is-ua-webview");

const MESSAGE_COUNT_TOPIC = `/users/chat/chat-status`;
const CHAT_WS_ENDPOINT = `/app/chat-ws`;

const CONNECTION_TIMEOUT = 60000;
const RECONNECT_TIMEOUT = 60000;
const THROTTLE_TIME = 1000;
const HEARTBEAT_EVERY_MINUTE = 1000 * 60;

@Injectable({
  providedIn: 'root'
})
export class ChatWebSocketService implements OnDestroy {

  private debouncedUpdate = new Subject<void>();

  private debouncedUpdateSubs: Subscription;

  private stompClient: StompClient;

  constructor(private globalService: GlobalService,
              private chatWsStatusService: ChatWsStateService) {

    this.debouncedUpdateSubs = this.debouncedUpdate
      .pipe(debounceTime(THROTTLE_TIME))
      .subscribe(() => this.sendUpdateSubedVehicle());
  }

  public getUserAndConnect() {
    if (Permissions.hasModule(Module.MANAGEMENT) && !isWebview(navigator.userAgent)) {
      this.disconnect();
      this.globalService.userChatWsInfo().pipe(retryWhen(delay(RECONNECT_TIMEOUT))).subscribe(
        userInfo => this.connect(userInfo)
      );
    }
  }

  private connect(userInfo: UserWsInfo): void {
    const ws = new SockJS(userInfo.url, null, {timeout: CONNECTION_TIMEOUT});
    this.stompClient = overStomp(ws);
    this.stompClient.heartbeat.outgoing = HEARTBEAT_EVERY_MINUTE;
    this.stompClient.heartbeat.incoming = HEARTBEAT_EVERY_MINUTE;
    this.disableLogs();

    const headers = {
      employeeId: userInfo.employeeId,
      clientId: userInfo.clientId,
      token: userInfo.token,
      accountId: userInfo.accountId,
      partner: userInfo.partner,
      zoneId: userInfo.zoneId
    };

    const self = this;
    this.stompClient.connect(headers, function() {
      self.stompClient.subscribe(MESSAGE_COUNT_TOPIC, (event) => {
        self.handleMessageCount(JSON.parse(event.body));
      });
    }, this.errorCallBack);
  }

  private handleMessageCount = (chatStatus: ChatStatusInfo) => {
    this.chatWsStatusService.notifyMessageCount({
      textMessageCount: chatStatus.textMessageCount,
      fileMessageCount: chatStatus.fileMessageCount
    });
    this.chatWsStatusService.notifyCountByVehicleSource(chatStatus.countByVehicle);
    this.chatWsStatusService.notifyChatStatus(chatStatus.displayInfos);
  };

  public errorCallBack = () => {
    this.reconnect();
  }

  private reconnect() {
    setTimeout(() => this.getUserAndConnect(), RECONNECT_TIMEOUT);
  }

  public sendUpdateSubedVehicle() {
    if (this.stompClient) {
      this.stompClient.send(CHAT_WS_ENDPOINT, {});
    }
  }

  public updateSubedVehicles() {
    this.debouncedUpdate.next();
  }

  private disableLogs() {
    this.stompClient.debug = () => {};
  }

  public disconnect(): void {
    if (this.stompClient) {
      this.stompClient.disconnect();
      this.stompClient = null;
    }
  }

  public ngOnDestroy(): void {
    this.debouncedUpdateSubs.unsubscribe();
    this.disconnect();
  }
}
