<ul class="navbar-nav no-print">
  <li *ngFor="let item of menuItems; let firstIndex = index"
      class="nav-item"
      [ngClass]="{'dropdown': canView(item.roles, item.modules) && item.subMenu}">

    <a *ngIf="canView(item.roles, item.modules) && !item.routerLink && !item.subMenu"
       [attr.href]="item.url || ''"
       [attr.target]="item.target || ''"
       (click)="setMenuActive(firstIndex)"
       (mouseenter)="hoverItem($event)"
       class="nav-link menu-link"
       [ngClass]="item.active ? 'nav-link--active' : ''">
      <img *ngIf="item.newItem" src="assets/img/app/new.png" class="new-tag-first" height="25">
      <i class="fa {{ item.icon }} menu-item-icon"></i>
      <span class="menu-item-title">{{ item.title | translate }}</span>
    </a>

    <a *ngIf="canView(item.roles, item.modules, item.contractor) && item.routerLink && !item.subMenu"
       [routerLink]="[item.routerLink]"
       (click)="setMenuActive(firstIndex)"
       (mouseenter)="hoverItem($event)"
       class="nav-link menu-link"
       [ngClass]="item.active ? 'nav-link--active' : ''">
      <img *ngIf="item.newItem" src="assets/img/app/new.png" class="new-tag-first" height="25">
      <i class="fa {{ item.icon }} menu-item-icon"></i>
      <span class="menu-item-title">{{ item.title | translate }}</span>
    </a>

    <a *ngIf="canView(item.roles, item.modules, item.contractor)
              && item.subMenu
              && onlyCertainAccounts(item.onlyCertainAccounts)"
       [attr.href]="''"
       (mouseenter)="hoverItem($event)"
       [class]="' nav-link dropdown-toggle menu-link ' + item.class"
       [ngClass]="item.active ? 'nav-link--active' : ''"
       id="navbarDropdownMenuLink"
       data-toggle="dropdown"
       aria-haspopup="true"
       aria-expanded="false">
      <img *ngIf="item.newItem" src="assets/img/app/new.png" class="new-tag-first" height="25">
      <i class="fa {{ item.icon }} menu-item-icon"></i>
      <span class="menu-item-title">{{ item.title | translate }}</span>
      <i class="fa subsub-expansion-icon fa-caret-down"></i>
    </a>

    <ng-container *ngIf="canView(item.roles, item.modules) && item.subMenu">
      <nav class="dropdown-menu">
        <ng-container *ngFor="let subitem of item.subMenu; let secondIndex = index">
          <ng-container *ngIf="showItem(subitem.hideInMobile)">
            <a *ngIf="canView(subitem.roles, subitem.modules, subitem.contractor) && item.routerLink && !subitem.subMenu"
              [routerLink]="[subitem.routerLink != '/vehicles/track' ? subitem.routerLink : '/vehicles/' + getLastShownTrack() + '/track']"
              [class]="' dropdown-item menu-subitem ' + subitem.class"
              [ngClass]="subitem.active ? 'menu-subitem--active' : ''"
              (click)="setMenuActive(firstIndex, secondIndex)"
              (mouseenter)="hoverItem($event, subitem)">
              <i class="subitem-icon fa {{ subitem.icon }}"></i>
              <span class="menu-item-text">{{ subitem.title | translate }}</span>
              <img *ngIf="subitem.newItem" src="assets/img/app/new.png" class="new-tag" height="25">
            </a>
            <a *ngIf="canView(subitem.roles, subitem.modules, subitem.contractor) && !item.routerLink && !subitem.subMenu"
               [attr.href]="subitem.url || ''"
               [attr.target]="subitem.target || ''"
               (click)="setMenuActive(firstIndex, secondIndex)"
               (mouseenter)="hoverItem($event, subitem)"
               [class]="' dropdown-item menu-subitem ' + subitem.class"
               [ngClass]="subitem.active ? 'menu-subitem--active' : ''">
              <i class="subitem-icon fa {{ subitem.icon }}"></i>
              <span class="menu-item-text">{{ subitem.title | translate }}</span>
              <img *ngIf="subitem.newItem" src="assets/img/img/new.png" height="25">
            </a>
            <a *ngIf="subitem.subMenu && canView(subitem.roles, subitem.modules, subitem.contractor)"
               [attr.href]="''"
               (mouseenter)="openMenu($event, item)"
               (mouseleave)="shouldMenuCollapse($event, subitem)"
               (click)="openMobileMenu($event)"
               [class]="' dropdown-item menu-subitem subitem-wsub ' + subitem.class"
               [ngClass]="subitem.active ? 'menu-subitem--active' : ''">
              <i class="subitem-icon fa  {{ subitem.icon }}"></i>
              <span class="menu-item-text">{{ subitem.title | translate }}</span>
              <img *ngIf="subitem.newItem" src="assets/img/app/new.png" class="new-tag" height="25">
              <i class="fa subsub-expansion-icon" [ngClass]="{'fa-caret-right': !isMobile(), 'fa-caret-down': isMobile()}"></i>
            </a>
              <nav *ngIf="subitem.subMenu" class="sidebar-sublist subitem-submenu-list"
                   [ngClass]="{'sidebar-subsublist' : !isMobile(), 'mobile-subsublist' : isMobile()}"
                   [ngStyle]="{top: hoverSubMenu + 'px'}">
                <ng-container *ngFor="let subSubitem of subitem.subMenu; let thirdIndex = index">
                  <ng-container *ngIf="showItem(subSubitem.hideInMobile) && onlyForClients(subSubitem.onlyForClients)">
                    <a *ngIf="!subSubitem.routerLink && canView(subSubitem.roes, subSubitem.modules, subSubitem.contractor)"
                       (mouseleave)="collapseMenu($event, item)"
                       (mouseenter)="handleTooltip(subSubitem, $event)"
                       [attr.href]="subSubitem.url || ''"
                       [attr.target]="subSubitem.target || ''"
                       (click)="setMenuActive(firstIndex, secondIndex, thirdIndex)"
                       [class]="' dropdown-item menu-subitem ' + subSubitem.class"
                       [ngClass]="subSubitem.active ? 'menu-subitem--active' : ''">
                      <i class="subitem-icon fa {{ subSubitem.icon }}"></i>
                      <span class="menu-item-text">{{ subSubitem.title | translate }}</span>
                      <img *ngIf="subSubitem.newItem" src="assets/img/app/new.png" class="new-tag" class="new-tag" height="25">
                    </a>
                    <a *ngIf="subSubitem.routerLink && canView(subSubitem.roles, subSubitem.modules, subSubitem.contractor)"
                       [attr.target]="subSubitem.target || ''"
                       [routerLink]="[subSubitem.routerLink]"
                       (click)="setMenuActive(firstIndex, secondIndex, thirdIndex)"
                       (mouseleave)="shouldMenuCollapse($event, item)"
                       (mouseenter)="handleTooltip(subSubitem, $event)"
                       [class]="' dropdown-item menu-subitem subsubitem ' + subSubitem.class"
                       [ngClass]="subSubitem.active ? 'menu-subitem--active' : ''">
                      <i class="subitem-icon fa {{ subSubitem.icon }}"></i>
                      <span class="menu-item-text">{{ subSubitem.title | translate }}</span>
                      <img *ngIf="subSubitem.newItem" src="assets/img/app/new.png" class="new-tag" height="25">
                    </a>
                  </ng-container>
                </ng-container>
              </nav>
          </ng-container>
        </ng-container>
      </nav>
    </ng-container>
  </li>
</ul>
