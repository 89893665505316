export class MathUtils {

  public static anglesDistance(alpha: number, beta: number): number {
    let distance = Math.abs(beta - alpha) % 360;
    return distance > 180 ? 360 - distance : distance;
  }

  public static roundCoordinates(coordinate: number): number {
    return Math.round(coordinate * 1000000) / 1000000;
  }

  public static radians(degrees): number {
    return degrees * Math.PI / 180;
  }

  public static degrees(radians): number {
    return radians * Math.PI / 180;
  }

}
