import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {NoticeEntry} from '../../pages/client/alarms/notices/notice.entry';

@Pipe({name: 'violationNotice'})
export class ViolationNoticePipe implements PipeTransform {

  constructor(private translate: TranslateService) {
  }

  public transform(value: NoticeEntry): string {
    if (value != null) {
      let additionalInfos = value.additionalInfo.split(' / ');
      let rule = additionalInfos[1] != null
        ? this.translate.instant('reports.tacho-faults.rule.' + additionalInfos[1])
        : '';
      let criticality = additionalInfos[2] != null
        ? this.translate.instant('reports.tacho-faults.violations.' + additionalInfos[2])
        : '';
      return `${value.driverName}` + ' ' + `${rule}` + ' ' + `${criticality}`;
    }
    return '';
  }
}
