import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {VehicleDriverInfo} from './model/vehicle-driver.info';
import {BoschParkingInfo, BoschParkingPricing} from './bosch/model/bosch-parking.info';
import {HttpUtils} from '../../page/commons/http.utils';
import {BoschBookingForm} from './bosch/model/bosch-booking.form';
import {BoschBookingInfo} from './bosch/model/bosch-booking.info';
import {TravisParkingInfo} from './travis/model/travis-parking.info';
import {TravisAvailabilityRequest} from './travis/model/travis-availability.request';
import {TravisBookingInfo, TravisParkingAvailability} from './travis/model/travis-booking.info';
import {TravisBookingForm} from './travis/model/travis-booking.form';

@Injectable()
export class ParkingService {

  constructor(private http: HttpClient) {
  }

  public fetchVehicleDriver(vehicleId: number): Observable<VehicleDriverInfo> {
    return this.http.get<VehicleDriverInfo>(`/api/vehicles/vehicle-driver/${vehicleId}`);
  }

  //--------------------------------------------- BOSCH

  public fetchBoschParkingInfo(parkingId: string): Observable<BoschParkingInfo> {
    return this.http.get<BoschParkingInfo>(`/api/bosch-parking/area/${parkingId}`);
  }

  public fetchBoschParkingPricing(parkingId: string, startDate: string, endDate: string): Observable<BoschParkingPricing> {
    let params = new HttpParams();
    params = HttpUtils.appendParam(params, 'startDate', startDate);
    params = HttpUtils.appendParam(params, 'endDate', endDate);
    return this.http.get<BoschParkingPricing>(`/api/bosch-parking/area/${parkingId}/pricing`, {params});
  }

  public bookBoschParking(bookingForm: BoschBookingForm): Observable<BoschBookingInfo> {
    return this.http.post<BoschBookingInfo>('/api/bosch-parking/booking', bookingForm);
  }

  public fetchBoschBookingInfo(bookingId: number): Observable<BoschBookingInfo> {
    return this.http.get<BoschBookingInfo>(`/api/bosch-parking/booking/${bookingId}`);
  }

  public cancelBoschBooking(bookingId: number): Observable<boolean> {
    return this.http.post<boolean>('/api/bosch-parking/booking/cancel', bookingId);
  }

  //--------------------------------------------- TRAVIS

  public fetchTravisParkingInfo(parkingId: string): Observable<TravisParkingInfo> {
    return this.http.get<TravisParkingInfo>(`/api/travis-parking/location/${parkingId}`);
  }

  public fetchTravisParkingAvailability(request: TravisAvailabilityRequest): Observable<TravisParkingAvailability> {
    return this.http.post<TravisParkingAvailability>('/api/travis-parking/availability', request);
  }

  public fetchTravisBookingInfo(bookingId: number): Observable<TravisBookingInfo> {
    return this.http.get<TravisBookingInfo>(`/api/travis-parking/booking/${bookingId}`);
  }

  public bookTravisParking(bookingForm: TravisBookingForm): Observable<TravisBookingInfo> {
    return this.http.post<TravisBookingInfo>('/api/travis-parking/booking', bookingForm);
  }

  public cancelTravisBooking(bookingId: number): Observable<boolean> {
    return this.http.post<boolean>('/api/travis-parking/booking/cancel', bookingId);
  }

}
