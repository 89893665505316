import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {Location} from '@angular/common';
import {AppState} from '../app.state';
import {ChatService} from '../core/component/chat/chat.service';
import {Subscription} from 'rxjs';
import {NotificationService} from '../core/notification/notification.service';
import {GlobalConstants} from '../core/global/global.constants';
import {GlobalService} from '../core/global/global.service';
import {Restrictions} from '../core/model/profile';
import {SystemWarningEntry} from '../core/global/system-warning.entry';
import {TranslateService} from '@ngx-translate/core';
import {DeviceService} from '../core/commons/device.service';
import {environment} from '../../environments/environment';
import {AuthStorage} from '../core/auth/auth-storage';
import {Appearance} from './shared/profile/model/appearance';
import {AppearanceUtils} from './shared/profile/utils/appearance.utils';
import {Userpilot} from 'userpilot';

export const EDGE_USER = 'Edg/';

@Component({
  encapsulation: ViewEncapsulation.None,
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss'],
  providers: [AppState]
})
export class PagesComponent implements OnInit, OnDestroy {

  private chatStateSubscription: Subscription;
  private proTasksSubscription: Subscription;
  private oldTasksSubscription: Subscription;

  public restrictionsWarning: boolean;
  public restrictionsSuspend: boolean;
  public proTasksActive = false;
  public oldTasksActive = false;
  public showWarning = AuthStorage.isBrowserRestrictionsWarning();
  public isMobile: boolean;

  public systemWarningMessage: SystemWarningEntry;
  public interval: any;

  public chatState = 'out';

  public appearance: Appearance = AuthStorage.getAppearance();

  constructor(private _location: Location,
              private chatService: ChatService,
              private globalService: GlobalService,
              private translate: TranslateService,
              private notifications: NotificationService,
              private cd: ChangeDetectorRef,
              private deviceService: DeviceService) {
  }

  public ngOnInit(): void {
    this.setAppearanceTheme();
    this.chatStateSubscription = this.chatService.chatState$.subscribe(
      (state) => {
        this.chatState = state
      },
      (err) => this.notifications.loadingError()
    );

    this.proTasksSubscription = this.globalService.notifiedAboutProTasks().subscribe(
      data => {
        this.proTasksActive = data;
        this.cd.detectChanges();
      }
    );

    this.oldTasksSubscription = this.globalService.notifiedAboutOldTasks().subscribe(
      data => {
        this.oldTasksActive = data;
        this.cd.detectChanges();
      }
    );

    this.getCurrentPageName();
    this.subscribeLoggedProfile();
    this.checkForSystemWarning();
    this.checkForBrowserRestrictionsWarning();
    // eslint-disable-next-line
    this.isMobile = this.deviceService.isMobile();
  }

  private checkForBrowserRestrictionsWarning(): void {
    if (this.showWarning === null) {
      this.showWarning = environment.name !== 'UAT'
        && !(this.deviceService.isChromeBrowser() || this.deviceService.isEdgeChromiumBrowser());
      AuthStorage.persistIsBrowserRestrictionsWarning(this.showWarning);
    }
  }

  private setAppearanceTheme(): void {
    let elements = document.getElementsByTagName('body');
    if (elements.length > 0) {
      let body = elements[0];
      AppearanceUtils.setTheme(this.appearance.theme, body);
      AppearanceUtils.setGoofyLayout(this.appearance.isGoofyLayout, body);
    }
  }

  public getCurrentPageName(): void {
    let url = this._location.path();
    let hash = (window.location.hash) ? '#' : '';
    let self = this;
    setTimeout(function () {
      let subMenu = jQuery('a[href="' + hash + url + '"]').closest('li').closest('ul');
      window.scrollTo(0, 0);
      subMenu.closest('li').addClass('sidebar-item-expanded');
      subMenu.slideDown(250);
    });
  }

  private addKeyboardHandling(): void {
    if (window.innerWidth < GlobalConstants.MOBILE_WIDTH_BREAKPOINT) {
      jQuery(document.body).on('focus', 'textarea, input', (element) => {
        let type = element.target.getAttribute('type');
        let mediaQueryList = window.matchMedia('(display-mode: fullscreen)');
        if (mediaQueryList.matches && type !== 'checkbox') {
          document.getElementById('main-row').style.minHeight = '61%';
          document.getElementById('main-row').style.maxHeight = '61%';
        }
      }).on('blur', 'textarea, input', () => {
        document.getElementById('main-row').style.minHeight = '100%';
        document.getElementById('main-row').style.maxHeight = '100%';
      });
    }
  }

  public ngOnDestroy() {
    this.chatStateSubscription.unsubscribe();
    this.proTasksSubscription.unsubscribe();
    this.oldTasksSubscription.unsubscribe();
    clearInterval(this.interval);
  }

  private subscribeLoggedProfile() {
    this.globalService.getLoggedProfile().subscribe(
      data => {
        if (data.restrictions != null) {
          this.resolveRestrictions(data.restrictions);
        }
      },
      error => this.notifications.loadingError());
  }

  private resolveRestrictions(restrictions: Restrictions) {
    switch (restrictions.toString()) {
      case Restrictions[Restrictions.WARNING]:
        this.restrictionsWarning = true;
        break;
      case Restrictions[Restrictions.RESTRICTIONS_ON_USE]:
        this.restrictionsSuspend = true;
        break;
      default:
        this.restrictionsWarning = false;
        this.restrictionsSuspend = false;
        break;
    }
  }

  private checkForSystemWarning(): void {
    this.globalService.checkForSystemWarning()
      .subscribe(
        (data) => {
          this.systemWarningMessage = data;
          this.triggerUserpilot();
        }
      )
  }

  public getSystemWarningByLang(): string {
    return this.systemWarningMessage[this.translate.currentLang.toLowerCase() + 'Message'];
  }

  public closeWarning(): void {
    this.showWarning = false;
    AuthStorage.persistIsBrowserRestrictionsWarning(this.showWarning);
  }

  public isUserpilotEventId(): boolean {
    return this.systemWarningMessage != null
      && this.systemWarningMessage.userpilotEventId != null
      && this.systemWarningMessage.userpilotEventId !== '';
  }

  public triggerUserpilot(): void {
    if (this.isUserpilotEventId()) {
      Userpilot.trigger(this.systemWarningMessage.userpilotEventId);
    }
  }
}
