import {AuthStorage} from './auth-storage';
import {Role} from '../model/profile';

export class Permissions {

  public static hasRoles(roles: Role[]): boolean {
    if (roles) {
      let userRole: any = AuthStorage.getUserRole();
      return roles.filter(x => Role[x] === userRole).length > 0;
    }
    return true;
  }

  public static hasRole(role: Role): boolean {
    let userRole: any = AuthStorage.getUserRole();
    return Role[role] === userRole;
  }

  public static hasModules(modules: string[]): boolean {
    let userModules: string[] = AuthStorage.getModules();
    if (modules && userModules.length > 0) {
      return userModules.filter(x => x === modules[0]).length > 0;
    } else {
      return !(modules && userModules.length === 0);
    }
  }

  public static hasModule(module: string): boolean {
    let userModules: string[] = AuthStorage.getModules();
    if (userModules != null && userModules.length > 0) {
      return userModules.filter(x => x === module).length > 0;
    }
  }

  public static isSubcontractor(): boolean {
    return AuthStorage.isLoggedClientContractor();
  }
}
