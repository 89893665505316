/// <reference types="@types/googlemaps" />
import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import TravelMode = google.maps.TravelMode;
import DirectionsRequest = google.maps.DirectionsRequest;
import {OptimalPoint} from './model/optimal.point';
import {AlternativeRoute} from './model/alternative.route';

@Injectable()
export class RouteService {

  constructor(private translate: TranslateService,
              private http: HttpClient) {
  }

  public altHereRoute(request: any): Observable<AlternativeRoute> {
    return this.http.post<AlternativeRoute>(`/api/routing/alt-here-route`, request);
  }

  public getRoutingOptions(): Observable<any> {
    return this.http.get(`/api/routing/routing-params`, );
  }

  public optimalRoute(points: OptimalPoint[], strategy: string, type: string): Observable<any> {
    return this.http.post(`/api/routing/optimal-route?strategy=${strategy}&type=${type}`, points);
  }

  public simpleHereRoute(request: any, zoom: number): Observable<any> {
    return this.http.post(`/api/routing/simple-here-route?mapZoom=${zoom}`, request, {headers: {skip: 'true'}});
  }

  public getIncidents(incidentIdsMap: Map<string, string[]>): Observable<any> {
    const request = {};
    incidentIdsMap.forEach((value, key) => request[key] = value);
    return this.http.post('/api/routing/v8-incidents', request, {headers: {skip: 'true'}});
  }

  public getRouteRequest(route: any): DirectionsRequest {
    return {
      origin: route.origin,
      destination: route.destination,
      travelMode: TravelMode.DRIVING,
      waypoints: route.waypoints,
      avoidTolls: false,
      avoidFerries: true,
    };
  }

}
