import {Theme} from './theme';

export class Appearance {
  constructor(public isGoofyLayout: boolean,
              public theme: Theme) {
  }

  public static defaultValues(): Appearance {
    return new Appearance(false, Theme.DEFAULT);
  }
}
