import {Component, Input} from '@angular/core';
import {AbstractControl} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-control-messages',
  templateUrl: './control-messages.component.html'
})
export class ControlMessagesComponent {

  @Input()
  control: AbstractControl;

  @Input()
  touched = true;

  constructor(private translate: TranslateService) {
  }

  get errorMessage(): string {
    if (this.control != null) {
      for (let propertyName in this.control.errors) {
        if (this.control.errors.hasOwnProperty(propertyName) && (!this.touched || this.control.touched)) {
          return this.translate.instant(`validation.${propertyName}`);
        }
      }
    }
    return null;
  }

}
