import {Injectable} from '@angular/core';
import {NgbDatepickerI18n, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';

const I18N_VALUES = {
  'en': {
    weekdays: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
    months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
  },
  'lt': {
    weekdays: ['Pir', 'Ant', 'Tre', 'Ket', 'Pen', 'Šeš', 'Sek'],
    months: ['Sau', 'Vas', 'Kov', 'Bal', 'Geg', 'Bir', 'Lie', 'Rgp', 'Rgs', 'Spl', 'Lap', 'Grd']
  },
  'ru': {
    weekdays: ['пон', 'вто', 'сре', 'чет', 'пят', 'суб', 'вос'],
    months: ['янв', 'фев', 'мар', 'апр', 'май', 'июн', 'июл', 'авг', 'сен', 'окт', 'ноя', 'дек']
  },
  'de': {
    weekdays: ['Mon', 'Die', 'Mit', 'Don', 'Fre', 'Sam', 'Son'],
    months: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez']
  },
  'pl': {
    weekdays: ['Pon', 'Wto', 'Śro', 'Czw', 'Pią', 'Sob', 'Nie'],
    months: ['Stycz', 'Luty', 'Mar', 'Kwiec', 'Maj', 'Czerw', 'Lip', 'Sierp', 'Wrzes', 'Pazdz', 'Listop', 'Grudz']
  },
  'fr': {
    weekdays: ['Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam', 'Dim'],
    months: ['Janv', 'Févr', 'Mars', 'Avr', 'Mai', 'Juin', 'Juil', 'Août', 'Sept', 'Oct', 'Nov', 'Déc']
  },
  'lv': {
    weekdays: ['Pi', 'Otr', 'Tr', 'Cet', 'Pt', 'Sest', 'Sv'],
    months: ['janv', 'febr', 'marts', 'apr', 'maijs', 'jūn', 'jūl', 'aug', 'sept', 'okt', 'nov', 'dec']
  },
  'cz': {
    weekdays: ['Po', 'Út', 'St', 'Čt', 'Pá', 'So', 'Ne'],
    months: ['led', 'ún', 'brez', 'dub', 'kvet', 'cerv', 'cerven', 'srp', 'zár', 'ríj', 'list', 'pros']
  },
  'es': {
    weekdays: ['Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab', 'Dom'],
    months: ['enero', 'feb', 'mar', 'abr', 'mayo', 'Jun', 'Jul', 'agosto', 'sept', 'oct', 'nov', 'dic']
  },
  'ro': {
    weekdays: ['Lu', 'Ma', 'Mi', 'Jo', 'Vi', 'Sb', 'Du'],
    months: ['Ian', 'feb', 'mar', 'apr', 'mai', 'Iunie', 'Iulie', 'aug', 'sept', 'oct', 'Noiem', 'dec']
  },
  'it': {
    weekdays: ['Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab', 'Dom'],
    months: ['gen', 'feb', 'mar', 'apr', 'mag', 'giu', 'lug', 'ago', 'set', 'ott', 'nov', 'dic']
  }
};

@Injectable()
export class I18n {

  private lang = 'en';

  public constructor(private translate: TranslateService) {
  }

  public getCurrentLanguage() {
    this.lang = this.translate.currentLang;
    return this.lang;
  }
}

@Injectable()
export class DateI18nUtils extends NgbDatepickerI18n {

  public constructor(private logicService: I18n) {
    super();
  }

  public getMonthFullName(month: number): string {
    return;
  }

  public getMonthShortName(month: number): string {
    return I18N_VALUES[this.logicService.getCurrentLanguage()].months[month - 1];
  }

  public getWeekdayLabel(weekday: number): string {
    return I18N_VALUES[this.logicService.getCurrentLanguage()].weekdays[weekday - 1];
  }

  getDayAriaLabel(date: NgbDateStruct): string {
    return '';
  }

}
