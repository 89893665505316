import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ChatConfigsForm} from './model/chat-configs.form';

@Injectable()
export class ChatConfigsService {

  constructor(private http: HttpClient) {
  }

  public fetch(): Observable<ChatConfigsForm> {
    return this.http.get<ChatConfigsForm>('/api/configs/chat');
  }

  public update(form: ChatConfigsForm): Observable<any> {
    return this.http.put('/api/configs/chat', form);
  }

}
