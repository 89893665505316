import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import {HttpUtils} from '../../../core/page/commons/http.utils';
import {NotificationsNoticeEntry} from './notifications.notice.entry';
import {NotificationsAlarmsEntry} from './notifications.alarms.entry';
import {Pageable} from '../../../core/page/pageable';
import {Sort} from '../../../core/page/sort';
import {PageRestUtils} from '../../../core/page/commons/page-rest.utils';
import {NotificationsPersonalEntry, PersonalNotificationType} from './notifications.personal.entry';

@Injectable()
export class NotificationsService {

  constructor(private http: HttpClient) {
  }

  public latestAlarms(): Observable<NotificationsAlarmsEntry[]> {
    return this.http.get<NotificationsAlarmsEntry[]>(`/api/employee/alarms`);
  }

  public markAllAlarmsAsRead(): Observable<void> {
    return this.http.post<void>(`/api/employee/alarms/mark-all-as-read`, null);
  }

  public latestNotices(): Observable<NotificationsNoticeEntry[]> {
    return this.http.get<NotificationsNoticeEntry[]>('/api/notices/latest');
  }

  public markNoticesAsRead(noticeId: number): Observable<void>  {
    return this.http.post<void>(`/api/notices/mark-as-read`, noticeId);
  }

  public markAllNoticesAsRead(): Observable<void>  {
    return this.http.post<void>(`/api/notices/mark-all-as-read`, null);
  }

  public loadPersonalNotices(): Observable<NotificationsPersonalEntry[]> {
    const pageable = new Pageable(0, 10, new Sort([]));
    let params = PageRestUtils.appendPageableParams(pageable, new HttpParams());
    return this.http.get<NotificationsPersonalEntry[]>(`/api/personal/notification/popup`, {params: params});
  }

  public markPersonalNoticeAsRead(noticeId: number, type: PersonalNotificationType): Observable<void> {
    let params = new HttpParams();
    params = HttpUtils.appendParam(params, 'id', noticeId);
    params = HttpUtils.appendParam(params, 'type', type);
    return this.http.put<void>(`/api/personal/notification/mark-as-read`, {}, {params});
  }

  public markAllPersonalNoticesAsRead(): Observable<void>  {
    return this.http.put<void>(`/api/personal/notification/mark-all-as-read`, {});
  }

  public formExists(id: number): Observable<boolean> {
    return this.http.get<boolean>(`/api/change-forms/filled/` + id + '/exists');
  }
}
