import {transform} from 'ol/proj';
import {MapSettings} from '../model/map.constants';

export class MapUtils {

  public static getColor(step: number): string {
    while (step > MapSettings.POI_COLORS.length - 1) {
      step = step - MapSettings.POI_COLORS.length;
    }
    return MapSettings.POI_COLORS[step];
  }

  public static parseOLCoordinates(coordinates: any): string {
    let lonlat = transform(coordinates, 'EPSG:3857', 'EPSG:4326');
    return`${lonlat[1].toFixed(6)},${lonlat[0].toFixed(6)}`;
  }

  public static getZoomStepCoefficient(zoom: number): number {
    let poweredZoom = 2 ** (zoom - 1);
    return MapSettings.MIN_ZOOM_PIXEL_STEP / poweredZoom;
  }

  public static showRoadDescriptionElement(text: string, element: HTMLElement): void {
    if (element != null && element.style.visibility === 'hidden') {
      element.textContent = text;
      element.style.visibility = 'visible'
    }
  }

  public static setRoadDescriptionElementPosition(element: HTMLElement, event: any, padding: number): void {
    let xPosition = event.pointerEvent.clientX;
    let yPosition = event.pointerEvent.clientY;
    element.style.left = xPosition + 'px';
    element.style.top = (yPosition - padding) + 'px';
  }

  public static hideRoadDescriptionElement(element: HTMLElement): void {
    if (element?.style.visibility !== 'hidden') {
      element.style.visibility = 'hidden'
    }
  }

}
