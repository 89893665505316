import {EmployeeReadEntry} from './model/employee-read.entry';
import {MessageFileType} from './model/message-file.type';
export class MessageEntry {

  constructor(public id: number,
              public senderType: string,
              public sender: string,
              public senderPhoneNumber: string,
              public receivedOn: string,
              public sentOn: string,
              public vehicleId: number,
              public messageText: string,
              public file: string,
              public fileType: MessageFileType,
              public employeeRead: EmployeeReadEntry[],
              public driverSeen: boolean,
              public drawNewBox: boolean,
              public fileId: number,
              public openedOn: string) {

  }

}
