import {Sort} from './sort';

export class Pageable {

  public static readonly DEFAULT_PAGE_NUMBER = 1;
  public static readonly DEFAULT_PAGE_SIZE = 20;

  constructor(public pageNumber: number, public  pageSize: number, public  sort?: Sort) {
  }

  public static default(): Pageable {
    return new Pageable(Pageable.DEFAULT_PAGE_NUMBER, Pageable.DEFAULT_PAGE_SIZE);
  }

}
