<div class="chat-container">
  <form (ngSubmit)="loadList()" [formGroup]="filterForm" novalidate="novalidate" class="chat__filter file-filter form--soft">
    <div class="form-group">
      <div class="input-group">
        <app-date-time-picker id="from" class="dateFrom"
                              [controlName]="'dateFrom'"
                              [form]="filterForm"
                              [pickerType]="'DATE'"
                              [placement]="'bottom'"
                              [requiresSmallInput]="false"
                              [placeHolder]="'chat.messages.dateFrom' | translate"
                              (datePicked)="loadList()">
        </app-date-time-picker>
        <app-date-time-picker id="till" class="dateTill"
                              [controlName]="'dateTill'"
                              [form]="filterForm"
                              [pickerType]="'DATE'"
                              [placement]="'bottom'"
                              [requiresSmallInput]="false"
                              [placeHolder]="'chat.messages.dateTill' | translate"
                              (datePicked)="loadList()">
        </app-date-time-picker>
        <select class="sender" formControlName="sender" style="cursor: pointer">
          <option [ngValue]="null"></option>
          <option *ngFor="let type of senderType" [value]="type">
            {{'chat.messages.filter-type.' + type | translate}}
          </option>
        </select>
        <input type="text" name="fileName" id="fileName"
               formControlName="fileName"
               class="form-control textSearch"
               appAutocompleteOff/>
        <button class="chat-header__button" role="button" type="submit">
          <i class="fa fa-search"></i>
        </button>
        <button class="chat-header__button datePicker" (click)="expandDatePickerInput()" type="button" >
          <i class="fa fa-calendar"></i>
        </button>
        <button class="chat-header__button datePicker" (click)="expandSenderInput()" type="button" >
          <i class="ico-users"></i>
        </button>
      </div>
    </div>
  </form>

  <div class="chat__content chat-list__container file-list"
       [ngClass]="{'small-file-list': isThereMarkedFiles()}"
       infinite-scroll
       [infiniteScrollUpDistance]="scrollUpDistance"
       [infiniteScrollThrottle]="throttle"
       [scrollWindow]="false"
       (scrolled)="onScrolledDown()">

    <div *ngIf="files.length == 0" class="alert alert-info" role="alert">
      {{'chat.messages.no-scanned-documents' | translate}}
    </div>
    <div *ngFor="let file of files" class="file-item" id="file-{{file.id}}" (click)="markItem(file)">
      <div class="image-block" *ngIf="isPicture(file.path)">
        <img class="scan-doc" [src]="fileUri + file.path" (click)="showModal(file.id)">
        <app-picture-modal *ngIf="modalVisible === file.id"
                           (close)="closeModal()"
                           (next)="nextPicture()"
                           (previous)="previousPicture()"
                           (download)="downloadFile(file.vehicleId, file.id, file.name)"
                           (downloadAsPdf)="downloadPictureAsPdf(file.vehicleId, file.fileName, file.id)"
                           [isPDFDownloadVisible]="true"
                           [fileUri]="fileUri"
                           [content]="file.path"
                           [caption]="file.fileName"></app-picture-modal>
      </div>
      <div class="image-block" *ngIf="!isPicture(file.path)">
        <i class="fa fa-file-o"></i>
      </div>
        <div class="file-name">{{file.fileName}}</div>
        <div class="received-date">{{file.receivedOn}}</div>
      <div class="download-block">
        <span class="message-text__action"
           (click)="downloadFile(file.vehicleId, file.id, file.name)">
           <i class="fa fa-cloud-download"></i>
        </span>
        <span class="message-text__action" *ngIf="!file.marked" (click)="markToDownload(file)">
          <i class="fa fa-plus"></i>
        </span>
        <span class="message-text__action message-text__action--active" *ngIf="file.marked" (click)="unmarkToDownload(file)">
          <i class="fa fa-minus"></i>
        </span>
      </div>
    </div>
  </div>
  <div *ngIf="isThereMarkedFiles()" class="download-selection">
    <a class="download-selection__action"
       title="{{'chat.messages.downloadSeparately' | translate}}"
       (click)="downloadFiles()">
      <i class="fa fa-cloud-download"></i>
    </a>
    <a class="download-selection__action"
       title="{{'chat.messages.downloadPdf' | translate}}"
       (click)="downloadPictureBunch()">
      <i class="fa fa-folder"></i>
    </a>
  </div>
</div>
