import {ChangeDetectorRef, Component, OnDestroy, OnInit} from "@angular/core";
import {LoaderService} from "./loader.service";
import {takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";
import {SubscriptionUtils} from "../../../core/commons/subscription.utils";

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit, OnDestroy{

  public loading = false;

  private subSubject = new Subject<boolean>();

  constructor(private loader: LoaderService,
              private cd: ChangeDetectorRef) {
  }

  public ngOnInit(): void {
    this.loader.isLoaderActive()
      .pipe(takeUntil(this.subSubject))
      .subscribe(data => {
        this.loading = data
        this.cd.detectChanges();
      });
  }

  public ngOnDestroy(): void {
    SubscriptionUtils.safeUnsubscribeSubject(this.subSubject);
  }

}
