import {Injectable} from '@angular/core';
import {Pageable} from '../../../../core/page/pageable';
import {DepartmentFilter} from './list/department.filter';
import {Observable} from 'rxjs';
import {DepartmentEntry} from './department.entry';
import {PageEntry} from '../../../../core/page/page.entry';
import {PageRestUtils} from '../../../../core/page/commons/page-rest.utils';
import {DepartmentForm} from './department.form';
import {IdValue} from '../../../../core/model/id-value';
import {HttpClient, HttpParams, HttpRequest} from '@angular/common/http';
import {HttpUtils} from '../../../../core/page/commons/http.utils';

@Injectable()
export class DepartmentService {

  constructor(private http: HttpClient) {
  }

  public fetchDepartments(filter: DepartmentFilter,
                          pageable: Pageable): Observable<PageEntry<DepartmentEntry>> {

    let params = PageRestUtils.appendPageableParams(pageable, new HttpParams());
    params = HttpUtils.appendParam(params, 'title', filter.title);
    return this.http.get<PageEntry<DepartmentEntry>>('/api/departments', {params: params});
  }

  public delete(id: number): Observable<void> {
    return this.http.delete<void>('/api/departments/' + id, {});
  }

  public create(form: any): Observable<void> {
    return this.http.post<void>('/api/departments', form);
  }

  public getDepartment(id: number): Observable<DepartmentForm> {
    return this.http.get<DepartmentForm>('/api/departments/edit/' + id);
  }

  public update(id: number, form: any): Observable<void> {
    let params = new HttpParams();
    params = HttpUtils.appendParam(params, 'departmentId', id);
    return this.http.put<void>('/api/departments', form, {params: params});
  }

  public getDepartmentList(id: number): Observable<IdValue<string>[]> {
    return this.http.get<IdValue<string>[]>('/api/departments/list/' + id);
  }

  public getDepartments(): Observable<IdValue<string>[]> {
    return this.http.get<IdValue<string>[]>('/api/departments/client');
  }
}
