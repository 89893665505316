import {Injectable} from '@angular/core';
import {NgbDateParserFormatter, NgbDateStruct, NgbTimeStruct} from '@ng-bootstrap/ng-bootstrap';

function toInteger(value: any): number {
  return parseInt(`${value}`, 10);
}

function padNumber(value: number) {
  if (isNumber(value)) {
    return `0${value}`.slice(-2);
  } else {
    return '';
  }
}

function isNumber(value: any): boolean {
  return !isNaN(toInteger(value));
}

@Injectable()
export class DateFormatUtils extends NgbDateParserFormatter {

  public static DATE_SPLITTER = '-';

  public format(date: NgbDateStruct): string {
    let stringDate = '';
    if (date) {
      stringDate += date.year + DateFormatUtils.DATE_SPLITTER;
      stringDate += isNumber(date.month) ? padNumber(date.month) + DateFormatUtils.DATE_SPLITTER : '';
      stringDate += isNumber(date.day) ? padNumber(date.day) : '';
    }
    return stringDate;
  }

  public parse(value: string): NgbDateStruct {
    if (value) {
      let dateParts = value.trim().split(DateFormatUtils.DATE_SPLITTER);
      if (dateParts.length === 1 && isNumber(dateParts[0])) {
        return {year: toInteger(dateParts[0]), month: null, day: null};
      } else if (dateParts.length === 2 && isNumber(dateParts[0]) && isNumber(dateParts[1])) {
        return {year: toInteger(dateParts[0]), month: toInteger(dateParts[1]), day: null};
      } else if (dateParts.length === 3 && isNumber(dateParts[0]) && isNumber(dateParts[1]) && isNumber(dateParts[2])) {
        return {year: toInteger(dateParts[0]), month: toInteger(dateParts[1]), day: toInteger(dateParts[2])};
      }
    }
    return null;
  }

  public timeToModel(value: string): NgbTimeStruct {
    if (!value) {
      return null;
    }
    const split = value.split(':');
    return {
      hour: parseInt(split[0], 10),
      minute: parseInt(split[1], 10),
      second: parseInt(split[2], 10)
    };
  }

  public timeToString(time: NgbTimeStruct, valueIfNull: string): string {
    return time ? `${padNumber(time.hour)}:${padNumber(time.minute)}` : valueIfNull;
  }

  public static localDateTimeToTimeString(time: any) {
    if (time) {
      return time.length > 5
        ? this.getTimeWithSeconds(time)
        : this.getTimeWithoutSeconds(time);
    }
    return '';
  }

  private static getTimeWithoutSeconds(time: any) {
    return time[0] + this.DATE_SPLITTER + this.getMonth(time[1])
      + this.DATE_SPLITTER + time[2] + ' ' + time[3] + ':' + time[4];
  }

  private static getTimeWithSeconds(time: any) {
    return time[0] + this.DATE_SPLITTER + time[1] + this.DATE_SPLITTER
      + time[2] + ' ' + time[3] + ':' + time[4] + '.' + time[5];
  }

  private static getMonth(month): string {
    return month > 9 ? month : '0' + month;
  }

  public static localDateTimeStringToTimeString(time: string): string {
    return time.substring(0, time.indexOf('T')) + ' ' + time.substr(time.indexOf('T') + 1, 5);
  }

  public static adjustDate(fullDate: string) {
    let dateSplit = fullDate.trim().split(DateFormatUtils.DATE_SPLITTER);
    if (dateSplit[1].length === 1) {
      dateSplit[1] = '0' + dateSplit[1];
    }
    if (dateSplit[2].length === 1) {
      dateSplit[2] = '0' + dateSplit[2];
    }
    return dateSplit[0] + DateFormatUtils.DATE_SPLITTER + dateSplit[1] + DateFormatUtils.DATE_SPLITTER + dateSplit[2]
  }

}
