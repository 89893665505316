import {HttpParams} from '@angular/common/http';

export class HttpUtils {

  constructor() {
  }

  public static appendParam(params: HttpParams, name: string, value: any) {
     return value != null ? params.append(name, value) : params;
  }

  public static appendArrayParam(params: HttpParams, name: string, value: any[]) {
    if (value) {
      value.forEach(it => params = params.append(name, it));
    }
    return params;
  }

}
