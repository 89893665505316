import {KeyboardCommands} from './keyboard-commands';

export class ChatConfigsForm {
  constructor(public enter: string,
              public shiftEnter: string) {
  }

  public static defaultValues(): ChatConfigsForm {
    return new ChatConfigsForm(KeyboardCommands.ENTER, KeyboardCommands.SHIFT_ENTER);
  }
}
