export let popupConfigsForm: any = {
  plateNumber: [null],
  address: [null],
  position: [null],
  speed: [null],
  fuel: [null],
  adblueLevelPercent: [null],
  axleWeight: [null],
  axleWeight2: [null],
  time: [null],
  tabTime: [null],
  distance: [null],
  distanceToday: [null],
  taskDistance: [null],
  externalPowerV: [null],
  batteryLevel: [null],
  engineHours: [null],

  setFreezerTemperature: [null],
  supplyFreezerTemperature: [null],
  actualFreezerTemperature: [null],
  freezerEngineOn: [null],
  freezerMode: [null],

  secondCompartmentSetPoint: [null],
  secondCompartmentSupplyTemperature: [null],
  secondCompartmentTemperature: [null],
  secondCompartmentIsOn: [null],
  secondCompartmentFreezerMode: [null],

  firstSensorTemperature: [null],
  secondSensorTemperature: [null],
  thirdSensorTemperature: [null],
  fourthSensorTemperature: [null],

  ambientTemperature: [null],
  freezerPowerMode: [null],
  freezerRunMode: [null],
  freezerDieselHours: [null],
  freezerElectricHours: [null],
  freezerStandbyHours: [null],
  engineTemperature: [null],
  driverPhoneNumber: [null],
  grossCombinationVehicleWeight: [null],
  drivingLeft: [null]
};

export class PopupConfigsForm {
  constructor(public plateNumber: boolean,
              public address: boolean,
              public position: boolean,
              public speed: boolean,
              public fuel: boolean,
              public adblueLevelPercent: boolean,
              public axleWeight: boolean,
              public axleWeight2: boolean,
              public time: boolean,
              public tabTime: boolean,
              public distance: boolean,
              public distanceToday: boolean,
              public taskDistance: boolean,
              public externalPowerV: boolean,
              public batteryLevel: boolean,
              public engineHours: boolean,
              public setFreezerTemperature: boolean,
              public supplyFreezerTemperature: boolean,
              public actualFreezerTemperature: boolean,
              public freezerEngineOn: boolean,
              public freezerMode: boolean,
              public secondCompartmentSetPoint: boolean,
              public secondCompartmentSupplyTemperature: boolean,
              public secondCompartmentTemperature: boolean,
              public secondCompartmentIsOn: boolean,
              public secondCompartmentFreezerMode: boolean,
              public firstSensorTemperature: boolean,
              public secondSensorTemperature: boolean,
              public thirdSensorTemperature: boolean,
              public fourthSensorTemperature: boolean,
              public ambientTemperature: boolean,
              public freezerPowerMode: boolean,
              public freezerRunMode: boolean,
              public freezerDieselHours: boolean,
              public freezerElectricHours: boolean,
              public freezerStandbyHours: boolean,
              public engineTemperature: boolean,
              public driverPhoneNumber: boolean,
              public grossCombinationVehicleWeight: boolean,
              public drivingLeft: boolean) {
  }

  public static defaultValues(): PopupConfigsForm {
    return new PopupConfigsForm(
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true
    )
  }
}
