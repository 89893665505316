import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {TachoTableEntry} from '../model/tacho/table/tacho-table.entry';
import {TachoLatestInfoChart} from '../model/tacho/tacho-latest-info-chart';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class GlobalTachoService {

  constructor(private http: HttpClient) {
  }

  public getLatestInfoCharts(driverCard: string, days: number, vehicle: number): Observable<TachoLatestInfoChart> {
    return this.http.get<TachoLatestInfoChart>(`/api/tachograph/info-chart/${vehicle}/${driverCard}/${days}`);
  }

  public getTable(driverCards: string[]): Observable<TachoTableEntry> {
    return this.http.get<TachoTableEntry>(`/api/tachograph/table?driverCards=${driverCards.join(',')}`);
  }

}
