import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {Label} from './model/Label';
import {VehicleLabels} from './model/VehicleLabels';

@Injectable()
export class LabelConfigsService {

  constructor(private http: HttpClient) {
  }

  public fetchLabels(): Observable<Label[]> {
    return this.http.get<Label[]>('/api/labels');
  }

  public fetchVehicleLabels(vehicleId: number): Observable<VehicleLabels> {
    return this.http.get<VehicleLabels>(`/api/labels/vehicle?vehicleId=${vehicleId}`);
  }

  public saveVehicleLabels(vehicleId: number, ownedLabels: number[]): Observable<void> {
    return this.http.post<void>(`/api/labels/vehicle?vehicleId=${vehicleId}`, ownedLabels);
  }

  public save(label: Label): Observable<void> {
    return this.http.post<void>('/api/labels', label);
  }

  public delete(labelId: number): Observable<void> {
    return this.http.delete<void>(`/api/labels?labelId=${labelId}`);
  }

}
