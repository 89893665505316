<div class="chat-window" id="chat-window">
  <header class="chat-header form--soft">
    <a class="chat-header__button chat-header__list"
       [ngClass]="{'active': isListActive, 'active-link': isListActive}"
       id="chat-list-link"
       data-toggle="tab"
       href="#chat-list"
       (click)="activateList()"
       title="{{'chat.window.list' | translate}}">
      <i class="ico-list"></i>
    </a>
    <div class="chat-header__group" *ngIf="!isScanListActive && !isMessagesActive && isLoggedClient">
      <select class="form-control cursor-pointer" id="group"
              [ngClass]="{'active-group': isGroupActive}"
              (change)="activateGroup($event.target.value)">
        <option *ngIf="!isGroupGenerated" href="javascript:void(0);" disabled [value]="">
          {{'chat.window.group' | translate}}
        </option>
        <option *ngIf="isGroupGenerated" selected >
          {{generatedTitle}}
        </option>
        <option href="javascript:void(0);" [value]="null" *ngIf="canSendToAllVehicles">
          {{'chat.window.all-groups' | translate}}
        </option>
        <option *ngFor="let group of vehicleGroups"
                href="javascript:void(0);"
                [value]="group.id">
          {{group.title}}
        </option>
      </select>
    </div>
    <div class="chat-header__current-chat"
        [ngClass]="{'active': isScanListActive || isMessagesActive,
                    'active-link': isScanListActive || isMessagesActive}"
        *ngIf="isScanListActive || isMessagesActive">
      <nav *ngIf="isMessagesActive && (newChatInfo.driverName || newChatInfo.plateNumber)"
           class="current-chat__nav">
        <span class="chat-name" *ngIf="newChatInfo.driverName">
          <span class="chat-name__author">{{newChatInfo.plateNumber}}</span>
          <span class="hidden-lg-down">({{newChatInfo.driverName}})</span>
        </span>
        <span class="chat-name" *ngIf="!newChatInfo.driverName">{{newChatInfo.plateNumber}}</span>
        <a class="chat-header__button"
           data-toggle="tab"
           href="#scan-list"
           (click)="activateScanList()">
           <i class="ico-paperclip"></i>
        </a>
      </nav>
      <nav *ngIf="isScanListActive && (newChatInfo.driverName || newChatInfo.plateNumber)"
           class="current-chat__nav">
        <span class="chat-name" *ngIf="newChatInfo.driverName">
          <span class="chat-name__author">{{newChatInfo.plateNumber}}</span>
          <span class="hidden-lg-down">({{newChatInfo.driverName}})</span>
        </span>
        <span *ngIf="!newChatInfo.driverName">{{newChatInfo.plateNumber}}</span>
        <a class="chat-header__button"
           data-toggle="tab"
           href="#chat-messages"
           (click)="activateMessages()">
           <i class="fa fa-comment-o"></i>
        </a>
      </nav>
    </div>
    <a class="chat-header__button chat-header__close" href="javascript:void(0)"
       (click)="closeChat()">
       <i class="ico-close"></i>
     </a>
  </header>
  <div class="chat__main tab-content">
    <app-chat-list
      (openNewChat)="openChatForEvent($event)"
      (openDocs)="openDocsForEvent($event)"
      class="tab-pane chat-list"
      [ngClass]="{'active': isListActive}"
      id="chat-list">
    </app-chat-list>
    <app-global-message
      class="tab-pane chat-messages"
      [ngClass]="{'active': isGroupActive}"
      id="chat-group"
      [fileUri]="fileUri"
      [group]="selectedGroup">
    </app-global-message>
    <app-chat-messages
      [chatInfo]="newChatInfo"
      [fileUri]="fileUri"
      class="tab-pane chat-messages"
      [ngClass]="{'active': isMessagesActive}"
      id="chat-messages">
    </app-chat-messages>
    <app-scan-list
      [scanListInfo]="newChatInfo"
      class="tab-pane chat-list"
      [ngClass]="{'active': isScanListActive}"
      id="scan-list">
    </app-scan-list>
  </div>
</div>
