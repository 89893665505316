import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import OlMap from 'ol/Map';
import {MapSettings} from '../../../../../core/model/map.constants';
import OlView from 'ol/View';
import {fromLonLat} from 'ol/proj';
import {defaults, ScaleLine} from 'ol/control.js';
import {Observable, timer} from 'rxjs';
import {NotificationService} from '../../../../../core/notification/notification.service';
import {MapFeatureUtils} from '../../../monitor/map/map-feature.utils';
import VectorSource from 'ol/source/Vector.js';
import {Vector as VectorLayer} from 'ol/layer.js';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute, Router} from '@angular/router';
import {delay, map, retryWhen, share, switchMap} from 'rxjs/operators';
import {PartnerPositionVehicleCacheEntry} from './model/partner-position-vehicle-cache.entry';
import {HttpClient, HttpParams} from '@angular/common/http';
import {MapFeatureTypes} from '../../../monitor/map/map-feature.types';
import Overlay from 'ol/Overlay';
import {MonitorMarkerService} from '../../../../../core/map/monitor-marker.service';

@Component({
  selector: 'app-partner-positions-track',
  templateUrl: './partner-position-track.component.html',
  styleUrls: ['./partner-position-track.component.scss']
})
export class PartnerPositionTrackComponent implements OnInit {

  public supportedLanguages: any[] = [
    {display: 'English', value: 'en', flag: 'gb'},
    {display: 'Lietuvių', value: 'lt', flag: 'lt'},
    {display: 'Deutsche', value: 'de', flag: 'de'},
    {display: 'Pусский', value: 'ru', flag: 'ru'},
    {display: 'Polski', value: 'pl', flag: 'pl'},
    {display: 'Français', value: 'fr', flag: 'fr'},
    {display: 'Latviski', value: 'lv', flag: 'lv'}
  ];
  public languageIcon: string;
  public isMenuCollapsed = false;

  public map: OlMap;
  private layers: any[] = MapSettings.getOLayers();

  private vehicleInfos: PartnerPositionVehicleCacheEntry[] = [];
  private recenterMap = false;
  private updating = false;
  public markersLayer: any;
  public markersMap: Map<any, any> = new Map<number, any>();
  private singleClickEvent: any;
  private popupContainer: any;
  private popupContent: any;
  private popupCloser: any;
  private popupOverlay: Overlay;

  public infos: Observable<PartnerPositionVehicleCacheEntry[]>;
  public uuid: string;

  private static readonly REFRESH_INTERVAL = 60000;

  constructor(private notification: NotificationService,
              private cd: ChangeDetectorRef,
              private _translate: TranslateService,
              private route: ActivatedRoute,
              private router: Router,
              private http: HttpClient,
              private markerService: MonitorMarkerService) {

    this.languageIcon = this.getFlag();
    this.infos = timer(0, 60000).pipe(
      switchMap(() => this.getLatestVehicleCache()),
      retryWhen(delay(PartnerPositionTrackComponent.REFRESH_INTERVAL)),
      share());
  }

  ngOnInit(): void {
    this.infos.subscribe();
    this.uuid = this.route.snapshot.paramMap.get('uuid');
    this.initOLMap();
  }

  private initOLMap() {
    this.map = new OlMap({
      target: 'map',
      controls: defaults({
        zoom: true,
        zoomOptions: {
          className: 'zoom-control'
        }
      }),
      loadTilesWhileInteracting: true,
      layers: this.layers,
      view: new OlView({
        center: fromLonLat([MapSettings.LNG, MapSettings.LAT]),
        zoom: MapSettings.ZOOM,
        minZoom: 3,
        maxZoom: 18,
        enableRotation: false,
        extent: MapSettings.getMapExtent()
      })
    });
    this.map.addControl(new ScaleLine());
    this.initPopup();
  }

  private updateOlMarker() {
    if (!this.updating) {
      this.updating = true;
      let markers = [];
      this.vehicleInfos.filter( it => it.position != null).forEach(vehicle => {
        markers.push(MapFeatureUtils.sharedVehicleMarker(vehicle));
      });

      if (this.markersMap.size > 0) {
        for (let mapMarker of Array.from(this.markersMap.values())) {
          markers.push(mapMarker);
        }
      }

      let vectorLayer = new VectorLayer({
        source: new VectorSource({
          features: markers
        })
      });
      this.map.removeLayer(this.markersLayer);
      this.map.addLayer(vectorLayer);
      this.markersLayer = vectorLayer;
      this.cd.detectChanges();
      // eslint-disable-next-line
      if (vectorLayer.getSource().getExtent()[0] != 'Infinity' && this.recenterMap) {
        this.map.getView().fit(vectorLayer.getSource().getExtent(), this.map.getSize());
        this.recenterMap = false;
      }
      this.updating = false;
    }
  }

  private getFlag(): string {
    return this.supportedLanguages
      .find(lang => lang.value === this._translate.currentLang).flag;
  }

  public selectLang(lang: any) {
    this._translate.use(lang.value);
    this.languageIcon = lang.flag;
  }

  public showLanguages() {
    this.isMenuCollapsed = !this.isMenuCollapsed;
  }

  public getLatestVehicleCache(): Observable<PartnerPositionVehicleCacheEntry[]> {
    let params = new HttpParams();
    return this.http.post(`/api/partner-positions/vehicle-cache?uuid=${this.uuid}`, {}, {params: params})
      .pipe(map(res => {
        if (res != null) {
          let entries = <PartnerPositionVehicleCacheEntry[]>res;
          entries.map(entry => new PartnerPositionVehicleCacheEntry(entry));
          this.vehicleInfos = entries;
          this.recenterMap = this.vehicleInfos.length === 0;
          this.updateOlMarker();
          return entries;
        } else {
          this.router.navigate(['page-not-found']);
        }
      }));
  }

  private initPopup() {
    this.popupContainer = document.getElementById('popup');
    this.popupContent = document.getElementById('popup-content');
    this.popupCloser = document.getElementById('popup-closer');

    this.popupOverlay = new Overlay({
      element: this.popupContainer,
      positioning: 'bottom-center',
      stopEvent: true,
      offset: [0, -10],
      autoPan: true,
      autoPanAnimation: {
        duration: 250
      }
    });

    this.map.addOverlay(this.popupOverlay);
    this.initPointerMove();
    this.initClickOnMarker();
  }

  private initClickOnMarker() {
    this.singleClickEvent = this.map.on('singleclick', (evt) => {
      let feature = this.map.forEachFeatureAtPixel(evt.pixel,
        function (feature) {
          return feature;
        });
      if (feature) {
        if (feature.values_.type === MapFeatureTypes.TRUCK) {
          this.triggerPopUp(feature, evt);
        }
      }
    });
  }

  private triggerPopUp(feature, evt?) {
    let popup = this.markerService.getInfoWindowContentForSharedVehicle(feature.values_.name);
    if (popup != null) {
      popup.subscribe(
        (data) => {
          let coordinate = null;
          if (evt) {
            coordinate = evt.coordinate;
          } else {
            coordinate = feature.getGeometry().getCoordinates();
          }
          this.popupContent.innerHTML = data.content;
          this.popupOverlay.setPosition(coordinate);
        },
        (err) => this.notification.loadingError()
      );
    }
  }

  public closePopup(): void {
    this.popupOverlay.setPosition(null);
    this.popupCloser.blur();
  }

  private initPointerMove() {
    this.map.on('pointermove', (evt) => {
      if (evt.dragging) {
        this.closePopup();
        return;
      }
      let feature = this.map.forEachFeatureAtPixel(evt.pixel,
        function (feature) {
          return feature;
        });
      evt.map.getTargetElement().style.cursor = feature ? 'pointer' : '';
    })
  }
}
