export let tachoTableConfigsForm: any = {
  latestActivity: [null],
  latestActivityStart: [null],
  latestActivityDuration: [null],

  restedBeforeDriving: [null],
  lastWeekRest: [null],
  shorterRestsCount: [null],
  nextDayRest: [null],
  nextWeekRest: [null],
  nextShortRest: [null],
  nextLongRest: [null],

  todayAtWork: [null],
  thisWeekAtWork: [null],

  drivingDayStarted: [null],
  drivingPeriodStarted: [null],
  drivingThisPeriod: [null],
  todayDriving: [null],
  yesterdayDriving: [null],
  thisWeekDrivingAllowed: [null],
  longerDrivingCount: [null],
  twoWeeksDriving: [null],

  todayWorking: [null],
  thisWeekWorking: [null]
};

export class TachoTableConfigsForm {
  constructor(public latestActivity: boolean,
              public latestActivityStart: boolean,
              public latestActivityDuration: boolean,
              public restedBeforeDriving: boolean,
              public lastWeekRest: boolean,
              public shorterRestsCount: boolean,
              public nextDayRest: boolean,
              public nextWeekRest: boolean,
              public nextShortRest: boolean,
              public nextLongRest: boolean,
              public todayAtWork: boolean,
              public thisWeekAtWork: boolean,
              public drivingDayStarted: boolean,
              public drivingPeriodStarted: boolean,
              public drivingThisPeriod: boolean,
              public todayDriving: boolean,
              public yesterdayDriving: boolean,
              public thisWeekDrivingAllowed: boolean,
              public longerDrivingCount: boolean,
              public twoWeeksDriving: boolean,
              public todayWorking: boolean,
              public thisWeekWorking: boolean) {
  }

  public static defaultValues(): TachoTableConfigsForm {
    return new TachoTableConfigsForm(
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true
    )
  }
}
