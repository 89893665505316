import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {IdValue} from '../../model/id-value';
import {EmployeePanelPartnerEntry} from './employee-panel-partner.entry';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class EmployeePanelService {

  private employeeClickedSource = new Subject<number>();
  public employeeClicked$ = this.employeeClickedSource.asObservable();

  private allEmployeesClickedSource = new Subject<number[]>();
  public allEmployeesClicked$ = this.allEmployeesClickedSource.asObservable();

  private employeesListSource = new Subject<number[]>();
  public employeesList$ = this.employeesListSource.asObservable();

  constructor(private http: HttpClient) {
  }

  public clickedEmployee(employeeId: number) {
    this.employeeClickedSource.next(employeeId);
  }

  public clickedAllEmployees(employeesIds: number[]) {
    this.allEmployeesClickedSource.next(employeesIds);
  }

  public changedEmployeesList(employeesIds: number[]) {
    this.employeesListSource.next(employeesIds);
  }

  public employees(): Observable<IdValue<string>[]> {
    return this.http.get<IdValue<string>[]>('/api/employee-panel/employees');
  }

  public partnersEmployees(): Observable<EmployeePanelPartnerEntry[]> {
    return this.http.get<EmployeePanelPartnerEntry[]>('/api/employee-panel/partners-employees');
  }

}
