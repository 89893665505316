import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {MatLegacySnackBar as MatSnackBar, MatLegacySnackBarRef as MatSnackBarRef} from '@angular/material/legacy-snack-bar';
import {NotificationPopupComponent, PopupType} from './notification-popup/notification-popup.component';

@Injectable()
export class NotificationService {

  constructor(private translate: TranslateService,
              private snackBar: MatSnackBar) {
  }

  public info(msg: string): any {
    this.openPopup(PopupType.INFO, this.translate.instant(msg));
  }

  public success(msg: string): any {
    this.openPopup(PopupType.SUCCESS, this.translate.instant(msg));
  }

  public successWithParams(msg: string, params: any): any {
    this.openPopup(PopupType.SUCCESS, this.translate.instant(msg, params));
  }

  public error(msg: string): any {
    this.openPopup(PopupType.ERROR, this.translate.instant(msg));
  }

  public errorWithParams(msg: string, params: any): any {
    this.openPopup(PopupType.ERROR, this.translate.instant(msg, params));
  }

  public alert(msg: string): any {
    this.openPopup(PopupType.WARN, this.translate.instant(msg, this.translate.instant(msg)));
  }

  public sendSuccess(): void {
    this.openPopup(PopupType.SUCCESS, this.translate.instant('global.success.send'));
  }

  public sendError(): void {
    this.openPopup(PopupType.ERROR, this.translate.instant('global.error.send'));
  }

  public loadingError(): void {
    this.openPopup(PopupType.ERROR, this.translate.instant('global.error.loading'));
  }

  public loadingNoData(): void {
    this.openPopup(PopupType.WARN, this.translate.instant('global.error.noData'));
  }

  public loadingSuccess(): void {
    this.openPopup(PopupType.SUCCESS, this.translate.instant('global.success.loadData'));
  }

  public storingError(): void {
    this.openPopup(PopupType.ERROR, this.translate.instant('global.error.storing'));
  }

  public deletingError(): void {
    this.openPopup(PopupType.ERROR, this.translate.instant('global.error.deleting'));
  }

  public periodError(): void {
    this.openPopup(PopupType.WARN, this.translate.instant('global.alert.period'));
  }

  public savingSuccess(): void {
    this.openPopup(PopupType.SUCCESS, this.translate.instant('global.success.saving'));
  }

  public updatingSuccess(): void {
    this.openPopup(PopupType.SUCCESS, this.translate.instant('global.success.updating'));
  }

  public deletingSuccess(): void {
    this.openPopup(PopupType.SUCCESS, this.translate.instant('global.success.deleting'));
  }

  public fileLimitExceeded(): void {
    this.openPopup(PopupType.WARN, this.translate.instant('chat.file.limit-exceeded'));
  }

  public uploadSuccess(count: number) {
    this.openPopup(PopupType.SUCCESS, this.translate.instant('global.success.uploading') + count);
  }

  public uploadNothingNew() {
    this.openPopup(PopupType.WARN, this.translate.instant('global.alert.nothingNew'));
  }

  public truckRouteCalculationFailed(): void {
    this.openPopup(PopupType.WARN, this.translate.instant('global.error.map.route.calculationFailed'));
  }

  public mapParametersUnavailable(): void {
    this.openPopup(PopupType.WARN, this.translate.instant('global.error.map.unavailable'));
  }

  public mapRouteNotFound(): void {
    this.openPopup(PopupType.WARN, this.translate.instant('global.error.map.route.notFound'));
  }

  public copiedSuccessfully() {
    this.openPopup(PopupType.SUCCESS, this.translate.instant('global.success.copied'));
  }

  public nothingFoundAlert() {
    this.openPopup(PopupType.WARN, this.translate.instant('global.alert.foundNothing'));
  }

  private openPopup(type: PopupType, message: string, duration = 5000): MatSnackBarRef<NotificationPopupComponent> {
    return this.snackBar.openFromComponent(NotificationPopupComponent, {
      data: {message, type},
      duration: duration,
      panelClass: ('popup-' + type),
      verticalPosition: 'bottom',
      horizontalPosition: 'end'
    });
  }

}
