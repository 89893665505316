import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {GroupNotificationEntry} from './model/group-notification.entry';
import {GroupNotificationForm} from './model/group-notification.form';

@Injectable()
export class GroupNotificationService {

  constructor(private http: HttpClient) {
  }

  public fetchGroupNotifications(groupId: number): Observable<GroupNotificationEntry[]> {
    return this.http.get<GroupNotificationEntry[]>('/api/group-notification?groupId=' + groupId);
  }

  public createGroupNotification(form: GroupNotificationForm, groupId: number): Observable<void> {
    return this.http.post<void>('/api/group-notification?groupId=' + groupId, form);
  }

  public updateGroupNotification(form: GroupNotificationForm, id: number, groupId: number): Observable<void> {
    return this.http.put<void>(`/api/group-notification/${id}?groupId=${groupId}`, form);
  }

  public deleteGroupNotification(id: number, groupId: number): Observable<void> {
    return this.http.delete<void>(`/api/group-notification/${id}?groupId=${groupId}`);
  }

}
