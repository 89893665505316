import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {NewGroupMessageForm} from './new-group-message.form';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Injectable()
export class GroupMessageService {

  constructor(private http: HttpClient) {
  }

  public sendMessages(vehicles: number[], messageText: string): Observable<string> {
    let form = new NewGroupMessageForm(vehicles, messageText);
    return this.http.post('/api/messages/group-message', form, {responseType: 'text'});
  }

  public saveMultipartMessage(group: number, message: string, file: any): Observable<string> {
    let formData: FormData = new FormData();
    formData.append('file', file, file.name);
    let headers = new HttpHeaders().append('Accept', 'application/json');
    return this.http.post
    (`/api/chat-files/group-multipart?message=${message}&group=${group}`, formData,
      {headers: headers, responseType: 'text'});
  }

}
