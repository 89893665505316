import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import {MessageCountInfo} from './model/message-count.info';
import {DisplayInfoEntry} from '../component/chat/model/display-info.entry';

@Injectable()
export class ChatWsStateService {

  private messageCountSource = new BehaviorSubject<MessageCountInfo>({textMessageCount: 0, fileMessageCount: 0});
  public messageCount$ = this.messageCountSource.asObservable();

  private countByVehicleStatelessSource = new Subject<Map<number, number>>();
  public countByVehicleStateless$ = this.countByVehicleStatelessSource.asObservable();

  private countByVehicleSource = new BehaviorSubject<Map<number, number>>(null);
  public countByVehicle$ = this.countByVehicleSource.asObservable();

  private displayInfoSource = new BehaviorSubject<Map<number, DisplayInfoEntry>>(new Map<number, DisplayInfoEntry>());
  public displayInfo$ = this.displayInfoSource.asObservable();

  constructor() {
  }

  public notifyMessageCount(messageCountInfo: MessageCountInfo) {
    this.messageCountSource.next(messageCountInfo);
  }

  public notifyCountByVehicleSource(countByVehicle: Map<number, number>) {
    this.countByVehicleStatelessSource.next(countByVehicle);
    this.countByVehicleSource.next(countByVehicle);
  }

  public notifyChatStatus(displayInfos: Map<number, DisplayInfoEntry>) {
    this.displayInfoSource.next(displayInfos);
  }
}
