import {Injectable} from '@angular/core';
import {Subject, Observable} from 'rxjs';
import DirectionsResult = google.maps.DirectionsResult;

@Injectable()
export class DirectionsStateService {

  private directionsSource = new Subject<any>();
  private directions$ = this.directionsSource.asObservable();

  private directionsDetailsSource = new Subject<DirectionsResult>();
  private directionsDetails$ = this.directionsDetailsSource.asObservable();

  private directionsPointSource = new Subject<any>();
  private directionsPoint$ = this.directionsPointSource.asObservable();

  private directionsStartPointSource = new Subject<any>();
  private directionsStartPoint$ = this.directionsStartPointSource.asObservable();

  private nearestObjectSource = new Subject<any>();
  private nearestObject$ = this.nearestObjectSource.asObservable();

  private directionsInterceptionsSource = new Subject<any>();
  private directionsInterceptions$ = this.directionsInterceptionsSource.asObservable();

  private directionsPointsSource = new Subject<any>();
  private directionsPoints$ = this.directionsPointsSource.asObservable();

  private directionsEndPointSource = new Subject<any>();
  private directionsEndPoint$ = this.directionsEndPointSource.asObservable();

  private removePoitnsSource = new Subject<any>();
  private removePoint$ = this.removePoitnsSource.asObservable();

  private olDirectionsStartPointSource = new Subject<any>();
  private olDirectionsStartPoint$ = this.olDirectionsStartPointSource.asObservable();

  constructor() {
  }

  public direction(directions: any): void {
    this.directionsSource.next(directions);
  }

  public directions(): Observable<any> {
    return this.directions$;
  }

  public directionDetails(details: any): void {
    this.directionsDetailsSource.next(details);
  }

  public getDirectionDetails(): Observable<any> {
    return this.directionsDetails$;
  }

  public directionPoint(point: any): void {
    this.directionsPointSource.next(point);
  }

  public getDirectionPoint(): Observable<any> {
    return this.directionsPoint$;
  }

  public directionInterceptions(points: any): void {
    this.directionsInterceptionsSource.next(points);
  }

  public getDirectionInterceptions(): Observable<any> {
    return this.directionsInterceptions$
  }

  public directionPoints(point: any): void {
    this.directionsPointsSource.next(point);
  }

  public getDirectionPoints(): Observable<any> {
    return this.directionsPoints$;
  }

  public directionStartPoint(point: any): void {
    this.directionsStartPointSource.next(point);
  }

  public getDirectionStartPoint(): Observable<any> {
    return this.directionsStartPoint$;
  }

  public directionEndPoint(point: any): void {
    this.directionsEndPointSource.next(point);
  }

  public getDirectionEndPoint(): Observable<any> {
    return this.directionsEndPoint$;
  }

  public removePoint(point: any): void {
    this.removePoitnsSource.next(point);
  }

  public getRemovePoints(): Observable<any> {
    return this.removePoint$;
  }

  public nearestObject(object: string): void {
    this.nearestObjectSource.next(object);
  }

  public getNearestObject(): Observable<string> {
    return this.nearestObject$;
  }

}
