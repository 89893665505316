export class ControlPanelSettingsEntry {

  constructor(public driver: boolean,
              public tachoTime: boolean,
              public vehicleGroups: boolean,
              public service: boolean,
              public connections: boolean,
              public ignoreMarkAllCookies: boolean) {
  }

  public static defaultValues(isClientEmployee: boolean): ControlPanelSettingsEntry {
    return new ControlPanelSettingsEntry(
      isClientEmployee,
      isClientEmployee,
      false,
      false,
      false,
      isClientEmployee
    )
  }

}
