import {VehiclePosition} from '../../../../../../core/component/latest-vehicles-info/vehicle-position';

export class PartnerPositionVehicleCacheEntry {
  public time: string;
  public position: VehiclePosition;
  public speed: number;
  public plateNumber: string;
  public status: string;
  public direction: number;

  constructor(item: any) {
    this.time = item.time;
    this.position = item.position;
    this.speed = item.speed;
    this.plateNumber = item.plateNumber;
    this.status = item.status;
    this.direction = item.direction;
  }
}


