<header class="app-header navbar navbar-toggleable-lg fixed-top">
  <a class="app-header__logo">
    <img src="assets/img/logo/fleethand-logo--ua.svg" alt="" class="logo-img hidden-sm-down" />
    <img src="assets/img/logo/fleethand-logo-symb--ua.svg" alt="" class="logo-img hidden-md-up" />
  </a>
  <ul class="app-header__notification-nav navbar-nav no-print">
    <li class="nav-item">
      <div class="notification-menu__lang dropdown no-print">
        <a href class="dropdown-toggle" data-toggle="dropdown" aria-expanded="false"
           (click)="showLanguages()">
          <i class="flag-icon flag-icon-{{languageIcon}}"></i>
        </a>

        <div class="dropdown-menu notification-menu__lang-list">
          <a *ngFor="let lang of supportedLanguages"
             class="cursor-pointer"
             (click)="selectLang(lang)">
            <i class="flag-icon flag-icon-{{lang.flag}}"></i>
          </a>
        </div>
      </div>
    </li>
  </ul>
</header>
<div id="map" class="map"></div>
<div id="popup" class="ol-popup">
  <a href="javascript:void(0)" id="popup-closer" class="ol-popup-closer" (click)="closePopup()"></a>
  <div id="popup-content"></div>
</div>
