import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {StationPrice} from '../../../core/model/station-price';
import {TextValue} from '../../../core/model/text-value';
import {HttpClient} from '@angular/common/http';

export const CRT_GROUP_IDS = [-10, -11];

@Injectable()
export class PoiCrtStationsService {

  constructor(private http: HttpClient) {
  }

  public stationPrices(stationIds: string []): Observable<StationPrice[]> {
    return this.http.post<StationPrice[]>(`/api/crt-prices`, stationIds, {headers: {skip: 'true'}});
  }

  public parallelStationPrices(stationIds: string []): Observable<TextValue<number>[]> {
    return this.http.post<TextValue<number>[]>(`/api/crt-prices/parallel`, stationIds, {headers: {skip: 'true'}});
  }

}
