import {Component, EventEmitter, HostListener, Input, Output} from '@angular/core';

export enum KEY_CODE {
  RIGHT_ARROW = 39,
  LEFT_ARROW = 37,
  ESCAPE = 27
}

@Component({
  selector: 'app-picture-modal',
  templateUrl: './picture-modal.component.html',
  styleUrls: ['./picture-modal.component.scss']
})
export class PictureModalComponent {

  public rotation = 0;

  public zoomed = false;

  @Input()
  public fileUri: string;

  @Input()
  public content: string;

  @Input()
  public caption: string;

  @Input()
  public isArrowVisible = true;

  @Input()
  public isDownloadVisible = true;

  @Input()
  public isPDFDownloadVisible: boolean;

  @Input()
  public isRotateVisible = true;

  @Input()
  public isCloseVisible = true;

  @Input()
  public isZoomAvailable = true;

  @Input('defectCode')
  set defectCode(code: number) {
    if (code != null) {
      setTimeout(() => this.drawGrid(+code), 500);
    }
  }

  @Output()
  public close: EventEmitter<any> = new EventEmitter();

  @Output()
  public download: EventEmitter<any> = new EventEmitter();

  @Output()
  public downloadAsPdf: EventEmitter<any> = new EventEmitter();

  @Output()
  public next: EventEmitter<any> = new EventEmitter();

  @Output()
  public previous: EventEmitter<any> = new EventEmitter();

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.keyCode === KEY_CODE.ESCAPE) {
      this.closeModal();
    }
    if (event.keyCode === KEY_CODE.LEFT_ARROW) {
      this.previousPicture();
    }
    if (event.keyCode === KEY_CODE.RIGHT_ARROW) {
      this.nextPicture();
    }
  }

  public downloadFile() {
    this.download.emit();
  }

  public downloadFileAsPdf() {
    this.downloadAsPdf.emit();
  }

  public closeModal(): void {
    this.close.emit();
  }

  public nextPicture(): void {
    this.next.emit();
  }

  public previousPicture(): void {
    this.previous.emit();
  }

  public rotate(): void {
    if (this.rotation < 270) {
      this.rotation += 90;
    } else {
      this.rotation = 0;
    }
  }

  public drawGrid(defectCode: number): void {
    const rows = 4;
    const columns = 6;
    let cells = rows * columns;
    let table = '<table cellspacing="0" border="1" cellpadding="0" class="grxd" style="width: 100%; height: 100%;">';
    for (let i = 1; i <= cells; i++) {
      table += (i === 1 ? '<tr>' : '');
      if (i === (defectCode + 1)) {
        table += '<td style="background-color: rgba(96,170,209,0.53) !important;"></td>';
      } else {
        table += '<td></td>';
      }
      if (i === cells) {
        table += '</tr>';
      } else {
        table += (i % columns === 0 ? '</tr><tr>' : '');
      }
    }
    table += '</table>';
    document.getElementById('img-no-zoom').innerHTML = table;
  }

  public getContent(): string {
    return this.fileUri != null ? this.fileUri + this.content : this.content;
  }
}
