import {MapPosition} from '../../route/metamodel';
import {TaskEntry} from '../tasks-queue/model/task.entry';
import {DriverTaskPointEntry} from '../tasks-queue/driver-task-point.entry';
import {DateFormatUtils} from '../../../../../core/component/date-time-picker/date-format.utils';
import {AdditionalInfoEntry} from '../tasks-queue/model/additional-info.entry';
import {AdditionalInfoForm} from '../task-form/additional-info-form';
import DirectionsResult = google.maps.DirectionsResult;
import {TaskFormData} from '../task-form/task-form.data';
import {ContactlessType, ParkingType} from '../../pro-tasks-manager/form/pro-tasks-form-state.service';

export interface SimplifiedRoute {
  origin: MapPosition;
  destination: MapPosition;
  waypoints: MapPosition[];
}

export const CRT_CARDLESS_ID = -11;
export const CIRCLE_K_CONTACTLESS_ID = -251;
export const SHELL_CONTACTLESS_ID = -151;
export const BOSCH_PARKING_ID = -35;
export const TRAVIS_PARKING_ID = -40;

export class TaskUtils {


  public static simplifiedRoute(route: any): SimplifiedRoute {
    let startLocation = route.legs[0].start_location;
    return {
      origin: this.toMapPosition(startLocation ? startLocation : route.legs[0].startLocation),
      destination: this.routeDestination(route),
      waypoints: this.waypoints(route)
    };
  }

  public static routeDestination(route: any): MapPosition {
    let leg = route.legs[route.legs.length - 1];

    let endLoc = leg.end_location;
    let endLocation = this.toMapPosition(endLoc ? endLoc : leg.endLocation);
    return {
      lat: endLocation.lat,
      lng: endLocation.lng,
      address: leg.end_address
    };
  }

  public static routeOrigin(route: any): MapPosition {
    let leg = route.legs[0];
    let startLoc = leg.start_location;
    let startLocation = this.toMapPosition(startLoc ? startLoc : leg.startLocation);
    return {
      lat: startLocation.lat,
      lng: startLocation.lng,
      address: leg.start_address
    };
  }

  public static toMapPositions(waypoints: string): MapPosition[] {
    let wpts = [];
    if (waypoints) {
      let stringPoints = waypoints.split('|');
      for (let point of stringPoints) {
        let latLng = point.split(',');
        wpts.push({lat: latLng[0], lng: latLng[1]});
      }
    }
    return wpts;
  }

  public static latLngToMapPositions(waypoints: google.maps.LatLng[]): MapPosition[] {
    return waypoints.map(it => {
      return {
        lat: it.lat(),
        lng: it.lng(),
        address: null}
    });
  }

  public static toMapPosition(latLng: any): MapPosition {
    if (latLng instanceof google.maps.LatLng) {
      return {lat: latLng.lat(), lng: latLng.lng()};
    }
    return {lat: latLng.lat, lng: latLng.lng};
  }

  public static toOlMapPosition(lonLat: any): MapPosition {
    return {lat: lonLat[1], lng: lonLat[0]}
  }

  public static googleWaypoints(directions: DirectionsResult): any {
    let waypoints: google.maps.LatLng[] = [];
    let legs = directions.routes[0] ? directions.routes[0].legs : [];
    for (let leg of legs) {
      if (legs.indexOf(leg) !== 0) {
        waypoints.push(leg.start_location);
      }
      leg.via_waypoints.forEach(w => waypoints.push(w));
    }
    return waypoints;
  }

  public static waypoints(route: any): any {
    let waypoints: MapPosition[] = [];
    for (let leg of route.legs) {
      if (route.legs.indexOf(leg) !== 0) {
        waypoints.push(this.toMapPosition(leg.start_location ? leg.start_location : leg.startLocation));
      }
      if (leg.via_waypoints || leg.viaWaypoints) {
        leg.via_waypoints
          ? leg.via_waypoints.forEach(w => waypoints.push(this.toMapPosition(w)))
          : leg.viaWaypoints.forEach(w => waypoints.push(this.toMapPosition(w)));
      }
    }
    return waypoints;
  }

  public static getRoadsString(route: any): string {
    let roads: string[] = [];
    for (let leg of route.legs) {
      let roadNum = new RegExp(/[ANEDB]\d{1,4}/);
      leg.steps.forEach(step => {
        if (step.instructions) {
          let match = step.instructions.toString().match(roadNum);
          if (match != null && !roads.includes(match[0])) {
            roads.push(match[0]);
          }
        }
      });
    }
    return roads.join(', ');
  }

  public static distanceWithoutFerry(route: any): number {
    let distance = 0;
    for (let leg of route.legs) {
      leg.steps.forEach(step => {
        if (step.instructions.toString().match('ferry')) {
          distance += step.distance.value;
        }
      })
    }
    return distance;
  }

  public static typeTranslation(type: string, translations: any): string {
    if (type) {
      return translations.tasks.type[type]
    }
    return translations.tasks.type.null;

  }

  public static stringifyPoints(waypoints: MapPosition[]): string {
    return waypoints
      .map(it => TaskUtils.stringifyPoint(it))
      .join('|');
  }

  private static stringifyPoint(it: MapPosition) {
    return `${it.lat},${it.lng}`;
  }

  public static toTaskFormData(entry: TaskEntry, taskRoute, routeParams): TaskFormData {
    return {
      id: entry.id,
      type: entry.type,
      comments: entry.comments,
      routeInfo: entry.routeInfo,
      sendMessage: entry.sendMessage,
      originSearch: taskRoute.origin.address === null
          ? taskRoute.origin.latitude + ', ' + taskRoute.origin.longitude
          : taskRoute.origin.address,
      searchText: taskRoute.searchText,
      origin: this.toTaskMapPosition(taskRoute.origin),
      destination: this.toTaskMapPosition(taskRoute.destination),
      polyline: taskRoute.polyline,
      waypoints: taskRoute.waypoints,
      distance: taskRoute.distance,
      duration: taskRoute.duration,
      timeRemaining: entry.timeRemaining,
      distanceRemaining: entry.distanceRemaining,
      eta: entry.eta,
      distancePassed: entry.distancePassed,
      tolls: taskRoute.tolls,
      avoids: routeParams.avoids,
      strategy: routeParams.strategy,
      vehicleType: routeParams.vehicleType,
      weight: routeParams.weight,
      height: routeParams.height,
      width: routeParams.width,
      infoSource: entry.infoSource,
      hazardous: routeParams.hazardous,
      hazardousType: routeParams.hazardousType,
      finishedRoute: entry.finishedRoute,
      additionalInfo: this.toAdditionalInfoForm(entry.additionalInfoEntries),
      fileName: entry.fileName,
      externalPoiId: entry.externalPoiId,
      contactlessType: entry.contactlessType,
      dispatcherAddress: taskRoute.dispatcherAddress,
      startedAt: entry.startedAt
    };
  }

  private static toTaskMapPosition(entry: DriverTaskPointEntry) {
    return {lat: entry.latitude, lng: entry.longitude, address: entry.address};
  }

  private static toAdditionalInfoForm(infoEntries: AdditionalInfoEntry[]): AdditionalInfoForm[] {
    let infoForm = [];
    for (let entry of infoEntries) {
      infoForm.push({
        optionId: entry.optionId,
        value: entry.value,
        type: entry.type,
      });
    }
    return infoForm;
  }

  private static getValue(entry: AdditionalInfoEntry) {
    switch (entry.type) {
      case 'LONG' || 'DOUBLE':
        return +entry.value;
      case 'DATE_TIME':
        return DateFormatUtils.localDateTimeStringToTimeString(entry.value);
    }
    return entry.value;
  }

  public static pointsAreDifferent(pointA: MapPosition, pointB: MapPosition): boolean {
    return pointA.lat.toFixed(3) !== pointB.lat.toFixed(3)
      || pointA.lng.toFixed(3) !== pointB.lng.toFixed(3);
  }

  public static isCRTStation(externalId: string): boolean {
    return externalId != null ? externalId.includes('drv') : false;
  }

  public static resolveContactlessType(poi: any): ContactlessType {
    if (poi?.externalId != null && poi.poiGroup?.id != null) {
      switch (poi.poiGroup.id) {
        case CIRCLE_K_CONTACTLESS_ID:
          return 'CIRCLE_K';
        case CRT_CARDLESS_ID:
          return 'CRT';
        case SHELL_CONTACTLESS_ID:
          return 'SHELL';
        default:
          return null;
      }
    }
    return null;
  }

  public static resolveParkingType(poi: any): ParkingType {
    if (poi?.externalId != null) {
      if (poi.poiGroup?.id === BOSCH_PARKING_ID) {
        return 'BOSCH';
      } else if (poi.poiGroup?.id === TRAVIS_PARKING_ID) {
        return 'TRAVIS'
      }
    }
    return null;
  }
}
