export class MapFeatureTypes {

  public static TRUCK = 'truck';
  public static MAP_MARKER = 'map-marker';
  public static POI = 'poi';
  public static STOP = 'stop';
  public static TAIL = 'tail';

  public static RULER_MARKER = 'ruler-marker';
  public static RULER_MARKER_ORIGIN = 'ruler-marker-origin';
  public static RULER_MARKER_DESTINATION = 'ruler-marker-destination';
  public static RULER_PLAN = 'ruler-plan';
  public static RULER_ROUTE_POINT = 'ruler-route-point';
  public static RULER_ROUTE = 'ruler-route';

  public static ROUTE_DESTINATION_MARKER = 'route-destination-marker';
  public static ROUTE_ORIGIN_MARKER = 'route-origin-marker';
  public static ROUTE_POINT = 'route-point';
  public static ROUTE_PLAN = 'route-plan';
  public static ROUTE = 'route';

  public static MARKED_ROAD_MARKER = 'marked-road-marker';
  public static MARKED_ROAD_ORIGIN_MARKER = 'marked-road-origin-marker';
  public static MARKED_ROAD_DESTINATION_MARKER = 'marked-road-destination-marker';
  public static MARKED_ROAD_PLAN = 'marked-road-plan';
  public static MARKED_ROAD_ROUTE_POINT = 'marked-road-route-point';
  public static MARKED_ROAD_ROUTE = 'marked-road-route';
  public static MARKED_ROAD_EDIT_ROUTE = 'marked-road-edit-route';

  public static TASK_POLYLINE = 'task';
  public static TASK_ACTUAL_POLYLINE = 'task-actual-polyline';
  public static TASK_PREVIEW_POLYLINE = 'task-preview-polyline';
  public static TASK_QUEUE_MARKER = 'task-queue-marker';
  public static TASK_PREVIEW_MARKER = 'task-preview-marker';
  public static TASK_LAST_DONE = 'task-last-done-marker';
  public static TASK_ORIGIN_MARKER = 'task-origin-marker';
  public static TASK_DESTINATION_MARKER = 'task-destination-marker';
  public static TASK_TRAFFIC_POLYLINE = 'task-traffic-polyline';
  public static TASK_TRAFFIC_MARKER = 'task-traffic-marker';
  public static TASK_WAYPOINT_INDICATOR_MARKER = 'task-waypoint-indicator-marker';
  public static TASK_WAYPOINT_MARKER = 'task-waypoint-marker';
  public static TASK_ALTERNATIVES = 'task-alternatives';
  public static TASK_POI_STATIONS = 'task-poi-stations';
  public static TASK_UNVERO_STATIONS = 'task-unvero-stations';
  public static TASK_REST_MARKERS = 'task-rest-markers';
  public static INTERSECTING_ROAD_POLYLINE = 'intersecting-road-polyline';
  public static INTERSECTING_ROAD_MARKER = 'intersecting-road-marker';
  public static TASK_FAVORITE_POI = 'task-poi-stations';
}
