import {Component, OnDestroy, ViewChild} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {NotificationService} from '../../../../core/notification/notification.service';
import {CountdownComponent} from 'ngx-countdown';

@Component({
  selector: 'app-vehicle-check',
  templateUrl: './vehicle-check.component.html',
  styleUrls: ['./vehicle-check.component.scss']
})
export class VehicleCheckComponent implements OnDestroy {

  @ViewChild('countdown', {static: true}) counter: CountdownComponent;
  public imei: string;
  public type: string;
  public result: any;
  public imeiIsValid = '';
  public selectedType = 'truck';
  private interval: any;
  public updatingData = false;
  public tachoSent = false;

  public gpsTypes: any[] = [
    {display: 'Trailer-Ref', value: 'ref'},
    {display: 'GPS only', value: 'gps'},
    {display: 'GPS+CAN', value: 'gpsCAN'}
  ];

  public truckTypes: any[] = [
    {display: 'MAN', value: 'man'},
    {display: 'MB', value: 'mb'},
    {display: 'Volvo', value: 'volvo'},
    {display: 'DAF', value: 'daf'},
    {display: 'IVECO', value: 'iveco'},
    {display: 'Scania', value: 'scania'}
  ];

  constructor(private http: HttpClient,
              private notification: NotificationService) {
  }

  public ngOnDestroy(): void {
    clearInterval(this.interval);
    this.counter.stop();
    this.updatingData = false;
  }

  public clear() {
    clearInterval(this.interval);
    this.imei = null;
  }

  public submit() {
    if (this.imei) {
      this.reload();
      this.interval = setInterval(() => this.reload(), 3000);
    }
  }

  public sendTachoCheck() {
    this.tachoSent = true;
    this.http.get<any>('/api/check-device/send-tacho-check?imei=' + this.imei).subscribe(
      data => {
      }
    )
  }

  public checkIMEI() {
    if (this.imei.length === 15) {
      this.http.get('/api/check-device/check-imei?imei=' + this.imei, {responseType: 'text'}).subscribe(
        data => {
          this.imeiIsValid = data;
          if (data) {
            this.clearAllInfo();
          }
        }
      )
    }

  }

  private reload() {
    this.http.get<any>('/api/check-device?imei=' + this.imei).subscribe(
      data => {
        if (data.errorMessage) {
          this.notification.error(data.errorMessage);
          clearInterval(this.interval);
        } else {
          this.result = data;
          this.updatingData = true;
          if (this.tachoSent && this.result.tachoCheck) {
            this.tachoSent = false;
          }

          setTimeout(() => this.counter.begin(), 200);
        }
      }
    );

  }

  public correctTacho() {
    if (this.result.tachoCheck) {
      return this.result.tachoCheck.indexOf('111') >= 0 && this.result.tachoCheck.indexOf('Line:1') >= 0
    }
    return false;
  }

  public clearAllInfo() {
    if (this.imei) {
      this.http.put<any>('/api/check-device/clear-device-data?imei=' + this.imei, {}).subscribe(
        success => this.notification.savingSuccess(),
        error => this.notification.loadingError());
    }
  }

  public finishCountdown() {
    setTimeout(() => this.counter.restart());
  }

  public hasCan(): boolean {
    return this.selectedType === 'truck' || this.selectedType === 'gpsCAN';
  }

  public truck(): boolean {
    return this.selectedType === 'truck';
  }

  public ref(): boolean {
    return this.selectedType === 'ref';
  }

  public changeGPS($event) {
    this.selectedType = $event.target.value;
  }
}
