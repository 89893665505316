import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ScanFileEntry} from './scan-file.entry';
import {FilesFilter} from './files.filter';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';

@Injectable()
export class ScanListService {

  constructor(private http: HttpClient) {
  }

  public fetchFiles(vehicleId: number, page: number, filter: FilesFilter): Observable<Array<ScanFileEntry>> {
    return this.http.post(`/api/chat-files/fetch-files?vehicleId=${vehicleId}&page=${page}`, filter)
      .pipe(map(res => <Array<ScanFileEntry>>res));
  }

}
