import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {CookieService} from 'ngx-cookie';
import {ChatService} from './core/component/chat/chat.service';
import {UpdateService} from './update.service';
import {VersionCheckService} from './version-check.service';
import {environment} from '../environments/environment';
import {addRussianFont} from '../assets/fonts/Lora-ru';
import {AlarmsWebSocketService} from './core/ws/alarms-web-socket.service';
import {ChatWebSocketService} from './core/ws/chat-web-socket.service';
import {AssetsStateWebSocketService} from './core/ws/assets-state/assets-state-web-socket.service';
import {LocaleService} from './core/global/locale.service';
import {Router, Event, NavigationStart} from '@angular/router';
import {Userpilot} from 'userpilot';
import {FM_LANG} from './core/global/cookies.constants';
import {AngularFireMessaging} from '@angular/fire/compat/messaging';

@Component({
  selector: 'app-root',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  public currentURL = '';

  constructor(private router: Router,
              private translate: TranslateService,
              private cookieService: CookieService,
              private chatService: ChatService,
              private versionCheckService: VersionCheckService,
              private update: UpdateService,
              private angularFireMessaging: AngularFireMessaging,
              private localeService: LocaleService) {
  }

  public ngOnInit(): void {
    this.reloadUserPilot();
    this.update.checkForUpdates();
    if (environment.production) {
      this.versionCheckService.initVersionCheck(environment.versionCheckURL);
    }
    this.translate.setDefaultLang('en');
    let lang = this.cookieService.get(FM_LANG);
    if (lang) {
      this.translate.use(lang);
      this.localeService.loadLocale(lang);
    } else {
      this.translate.use('en');
      this.localeService.loadLocale('en');
    }
    this.addJsPDFFonts();

    this.angularFireMessaging.messages.subscribe((message) => {
      // TODO: send message through our notification service. (never worked in production, need to check for priority)
    })
  }

  private reloadUserPilot(): void {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        if (this.currentURL === '') {
          this.currentURL = event.url;
        } else if (this.currentURL !== event.url && event.url !== '/') {
          this.currentURL = event.url;
          Userpilot.reload();
        }
      }
    });
  }

  private addJsPDFFonts(): void {
    addRussianFont();
  }
}
