export let mapConfigsForm: any = {
  showTwoDaysTail: [null],
  showTachoTableFirst: [null],
  nextDayRest: [null],
  latestActivity: [null],
  drivingBeforeBreak: [null],
  drivingLeft: [null],
  showDriverInMarker: [null],
  showAxleWeight: [null],
  showAdBlue: [null]
};

export class MapConfigsForm {
  constructor(public showTwoDaysTail: boolean,
              public showTachoTableFirst: boolean,
              public nextDayRest: number,
              public latestActivity: number,
              public drivingBeforeBreak: number,
              public drivingLeft: number,
              public showDriverInMarker: boolean,
              public showAxleWeight: boolean,
              public showAdBlue: boolean) {
  }

  public static defaultValues(isClientEmployee: boolean): MapConfigsForm {
    return new MapConfigsForm(
      isClientEmployee,
      false,
      0,
      1,
      2,
      3,
      false,
      true,
      false
    );
  }
}
