import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {DeviceService} from '../commons/device.service';
import {DVRModel, LatestVehicleEntry, Status} from '../component/latest-vehicles-info/model/latest-vehicle-entry';
import {EnumValues} from 'enum-values';
import {AuthStorage} from '../auth/auth-storage';
import {Module, Role} from '../model/profile';
import {MapPOIEntry} from '../../pages/client/tasks/tasks-manager/tasks-poi/model/map-poi.entry';
import {Permissions} from '../auth/permissions';
import {PlacesService} from '../component/places/places.service';
import {Observable} from 'rxjs';
import {PlaceSource} from '../component/places/model/place-source';
import {MathUtils} from '../commons/math-utils';
import {StationPrice} from '../model/station-price';
import {DurationPipe} from '../directives/duration.pipe';
import {DueService} from '../component/latest-vehicles-info/model/due-service';
// eslint-disable-next-line max-len
import {PartnerPositionVehicleCacheEntry} from '../../pages/client/partners/positions/partner-position-track/model/partner-position-vehicle-cache.entry';
import {PopupConfigsForm} from '../../pages/client/settings/global-config/popup-configs/popup-configs.form';
import {POIParkingDetails} from '../../pages/client/tasks/pro-tasks-manager/form/model/poi-parking-details.entry';
import {GlobalTankPoiPricesEntry} from '../../pages/client/tasks/tasks-manager/tasks-poi/model/global-tank-poi-prices.entry';
import {TrailerWarnings} from '../component/latest-vehicles-info/trailer-warnings';

interface InfoWindowContent {
  content: string;
  bubbleHeight: number;
  bubbleWidth: number
}

// CRT, CRT contactless, Bosch parking, Shell, AS 24, Circle K, Circle K contactless
export const GENERAL_STATION_GROUP_IDS = [-10, -11, -35, -150, -200, -250, -251];
export const AS24_GROUP_ID = -200;

const LINE_LENGTH = 40;
const NUMBER_OF_NOTES_TO_SHOW = 2;
const FIVE_STEPS_GROUP_NAME = '5_STEPS_HOTELS';

const isWebview = require("is-ua-webview");

@Injectable()
export class MonitorMarkerService {

  constructor(private translate: TranslateService,
              private deviceService: DeviceService,
              private placesService: PlacesService,
              private durationPipe: DurationPipe) {
  }

  public getInfoWindowContent(vehicle: LatestVehicleEntry,
                              containsButtons: boolean,
                              google?: boolean,
                              googleButtons?: boolean): Observable<InfoWindowContent> {
    if (vehicle.warnings != null) {
      let sources = [PlaceSource.NOMINATIM_COORDINATES];
      let coordinates = `${vehicle.position.latitude.toFixed(6)},${vehicle.position.longitude.toFixed(6)}`;
      return new Observable((infoWindowData) => {
        let info: InfoWindowContent = {
          content: '',
          bubbleHeight: this.deviceService.isSafariBrowser() ? 74 : 64,
          bubbleWidth: 245
        };
        this.placesService.getPlaces(coordinates, sources).subscribe(
          (data) => {
            if (data.length > 1) {
              vehicle.address = data[1].name;
            }
            info = this.setupInfoWindowContent(vehicle, info, google, containsButtons, googleButtons);
            infoWindowData.next(info);
          },
          (err) => {
            vehicle.address = '';
            info = this.setupInfoWindowContent(vehicle, info, google, containsButtons, googleButtons);
            infoWindowData.next(info);
          }
        );
      });
    }
  }

  private setupInfoWindowContent(
    vehicle: LatestVehicleEntry,
    info: InfoWindowContent,
    google: boolean,
    containsButtons: boolean,
    googleButtons: boolean) {

    this.addHeader(vehicle, info, google);
    if (this.showLabels()) {
      this.addHashtags(vehicle, info);
    }
    this.addInfoData(vehicle, info, google);
    this.setupWarnings(vehicle, info);
    if (containsButtons) {
      this.addButtons(vehicle, info);
    }
    if (google && googleButtons) {
      info.content += MonitorMarkerService.getIconWithLink(`showTrackHistory(${vehicle.id}, '${vehicle.plateNumber}')`,
        'fa-long-arrow-right', 'drivingHistory', this.translate);
      info.bubbleHeight += 15;
      if (vehicle.driver && vehicle.hasTacho && Permissions.hasModule(Module.HEAVY_TRACK)) {
        let secondDriverText = vehicle.secondDriver ? vehicle.secondDriver.text : null;
        let secondDriverValue = vehicle.secondDriver ? vehicle.secondDriver.value : null;
        info.content += MonitorMarkerService.getIconWithLink('showHideTacho(\'' + vehicle.plateNumber + '\', '
          + vehicle.id + ', '
          + '\'CHART\', \'' + vehicle.driver.text + '\', \'' + vehicle.driver.value + '\', \''
          + secondDriverText + '\', \'' + secondDriverValue + '\')',
          'fa-bar-chart',
          'charts',
          this.translate);
        info.content += MonitorMarkerService.getIconWithLink('showHideTacho(\'' + vehicle.plateNumber + '\', '
          + vehicle.id + ', '
          + '\'TABLE\', \'' + vehicle.driver.text + '\', \'' + vehicle.driver.value + '\', \''
          + secondDriverText + '\', \'' + secondDriverValue + '\')',
          'fa-table',
          'tables',
          this.translate);
      }
      info.content += '</div></div>';
    }
    return info;
  }

  public getClusterInfoWindowContent(features: any) {
    return new Observable((infoWindowData) => {
      let info: InfoWindowContent = {
        content: '',
        bubbleHeight: this.deviceService.isSafariBrowser() ? 74 : 64,
        bubbleWidth: 245
      };
      this.addClusterHeader(info);
      this.addClusterInfoData(features, info);
      infoWindowData.next(info)
    });
  }

  private addHeader(vehicle: LatestVehicleEntry, info: InfoWindowContent, google?: boolean): void {
    let plateNumber = vehicle.plateNumber;
    if (google) {
      info.content += '<div class="popup-content"><div class="popup-header">'
        + '<div class="vehicle-plate-number">' + plateNumber + '</div>';
    } else {
      info.content += this.showPlateNumberLabel(vehicle)
        + this.showPlateNumberInput(vehicle)
        + '<span>' + this.getCopyElement(plateNumber, 'copy-content') + '</span>';
      this.addDriver(vehicle, info);
      info.content += '</div>';
    }
  }

  private addHashtags(vehicle: LatestVehicleEntry, info: InfoWindowContent): void {
    if (vehicle.labels?.length > 0) {
      let lang = this.translate.currentLang;
      info.content += '<div class="popup-content"><span class="hashtags-content">'
      vehicle.labels.forEach(label => info.content += this.getHashtag(label.hashTag, label[lang]))
      info.content += '</span><div class="ol-popup-header"></div>'
    }
  }

  private getHashtag(hashtag: string, label: string): string {
    return '<div class="hashtag" title="'
      + label
      + '">#'
      + hashtag
      + '</div>'
  }

  private showPlateNumberLabel(vehicle: LatestVehicleEntry) {
    return '<div class="popup-content"><div class="ol-popup-header">'
      + '<div id="plate-number-div" class="vehicle-plate-number">' + vehicle.plateNumber + '</div>'
      + '<span id="plate-number-span">' + '<a class="map-popup__link" '
      + 'onclick="window.angularMapRef.zone.run(() => '
      + '{window.angularMapRef.component.' + `editVehiclePlateNumber()` + '})">'
      + '<i class="fa ' + 'fa-pencil' + '"></i>' + '</a>'
      + '</span>'
  }

  private showPlateNumberInput(vehicle: LatestVehicleEntry) {
    return '<div id="plate-number-edit-div" class="vehicle-plate-number hide-plate-number">'
      + '<input id="plate-number-edit-input" class="hide-plate-number" type="text" '
      + `value="${vehicle.plateNumber}" `
      + 'style="border: none;">'
      + '</div>'
      + '<span id="plate-number-edit-span" class="hide-plate-number">'
      + '<a class="map-popup__link" '
      + 'onclick="window.angularMapRef.zone.run(() => '
      + '{window.angularMapRef.component.'
      + `saveVehiclePlateNumber(${vehicle.id}, '${vehicle.plateNumber}')`
      + '})">'
      + '<i class="fa ' + 'fa-floppy-o' + '"></i>' + '</a>'
      + '</span>'
  }

  private addClusterHeader(info: InfoWindowContent) {
    info.content += '<div class="popup-content"><div class="ol-popup-header">'
      + '<span class="map-popup__title">' + this.translate.instant('monitor.map.title.cluster-title') + '</span>';
    info.content += '</div>';
  }

  private addDriver(vehicle: LatestVehicleEntry, info: InfoWindowContent): void {
    if (vehicle.driver) {
      if (vehicle.hasTacho) {
        let secondDriverText = vehicle.secondDriver ? vehicle.secondDriver.text : null;
        let secondDriverValue = vehicle.secondDriver ? vehicle.secondDriver.value : null;
        info.content += '<div class="driver-text"> '
          + this.getStringWithLink('showHideTachoTableChart(\'' + vehicle.plateNumber + '\', '
            + vehicle.id + ', \''
            + vehicle.driver.text + '\', \'' + vehicle.driver.value + '\', \'' + secondDriverText
            + '\', \'' + secondDriverValue + '\', ' + true + ')',
            vehicle.driver.value)
          + this.getCopyElement(vehicle.driver.value, 'copy-content')
          + '</div>';
      } else {
        info.content += '<span class="driver-text"> (' + vehicle.driver.value + ')</span>';
      }
    }
  }

  private getCopyElement(text: string, title: string) {
    return '<a class="map-popup__link" '
      + 'title ="' + this.translate.instant('monitor.map.title.' + title) + '" '
      + 'onclick="window.angularMapRef.zone.run(() => '
      + '{window.angularMapRef.component.' + `copyTextToClipboard('${text}')` + '})">'
      + '<i class="fa ' + 'fa fa-copy' + '"></i></a>';
  }

  private setupWarnings(vehicle: LatestVehicleEntry, info: InfoWindowContent): void {
    const lateOrSoonNotes = vehicle.noteStatuses != null
      ? vehicle.noteStatuses.filter(v => v.noteStatus === 'LATE' || v.noteStatus === 'SOON')
      : [];
    if ((vehicle.warnings.gpsOffline
      || vehicle.warnings.alarms.length > 0
      || vehicle.hasDvr && vehicle.warnings.dvrOffline
      || vehicle.serviceInRed !== null
      || lateOrSoonNotes.length > 0)
      && AuthStorage.isLoggedClientEmployee()) {

      info.content += '<div class="map-popup__status">';
      info.bubbleHeight += 13;
      if (vehicle.warnings.gpsOffline) {
        info.bubbleHeight += 13;
        info.content += '<div class="status--warning">'
          + this.translate.instant('monitor.vehicles.gpsDeviceOff')
          + '</div>';
      }

      if (vehicle.warnings.alarms) {
        vehicle.warnings.alarms.forEach(alarm => {
          info.bubbleHeight += 13;
          info.content += '<div class="status--warning">'
            + this.translate.instant('monitor.vehicles.alarm.' + alarm) + '</div>';
        });
      }

      if (vehicle.hasDvr && vehicle.warnings.dvrOffline) {
        info.bubbleHeight += 29;
        info.content += '<div class="status--warning">'
          + this.translate.instant('monitor.vehicles.dvrOff')
          + '<span class="status__details">'
          + this.translate.instant('monitor.vehicles.dvrOffDate')
          + vehicle.lastDVRTime
          + '</span>';
        if (vehicle.canRestartDVR && AuthStorage.isLoggedClientEmployee()) {
          info.content += '<div class="restart-button">'
            + MonitorMarkerService.getIconWithLink('restartDVR('
              + vehicle.id
              + ')', 'fa-refresh', 'dvrRestart',
              this.translate)
            + '</div></div>';
        }
      }
      if (vehicle.serviceInRed !== null && vehicle.dueServices !== null) {
        for (let service of vehicle.dueServices) {
          info.bubbleHeight += 13;
          if (service.serviceInRed) {
            info.content += '<div class="status--warning">'
              + this.translate.instant('monitor.vehicles.serviceInRed')
              + service.serviceName;
          } else {
            info.content += '<div class="status--alert">'
              + this.translate.instant('monitor.vehicles.serviceInYellow')
              + service.serviceName;
          }
          info.content += '<div class="service-button">'
            + MonitorMarkerService.getIconWithLink(
              'showServices(\'' + vehicle.plateNumber + '\', \'' + service.serviceName + '\')',
              'fa-wrench',
              'leftTillService',
              this.translate,
              '',
              this.getDurationTooltipValue(service),
              '',
              AuthStorage.canManageServices())
            + '</div></div>';
        }
      }
      const noteCount = lateOrSoonNotes.length;
      if (noteCount > 0) {
        lateOrSoonNotes.sort((note1, note2) => note1.noteStatus === 'LATE' ? -1 : 0)
          .splice(0, NUMBER_OF_NOTES_TO_SHOW)
          .forEach(note => {
            const noteMessage = note.dueNote.value && note.dueNote.value !== ''
            ? note.dueNote.value
            : this.translate.instant('monitor.vehicles.noteIsNull')
            info.bubbleHeight += 13;
            info.content += '<div Class="status--' + note.noteStatus + '">'
              + this.translate.instant('monitor.vehicles.noteIs' + note.noteStatus)
              + noteMessage;
            info.content += '</div>';
          });
        if (noteCount > NUMBER_OF_NOTES_TO_SHOW) {
          info.content += '<div class="status__more">'
            + this.translate.instant('monitor.vehicles.moreDueNotes');
          info.content += '</div>';
        }
      }

      info.content += '</div>';
    }
  }

  private getDurationTooltipValue(service: DueService): string {
    if (service.type === 'MONTHS') {
      return `${this.durationPipe.transform(service.leftTillService, ['MONTHS_DAYS'])}`;
    }
    return `${service.leftTillService} ${this.translate.instant('monitor.vehicles.service.type.' + service.type)}`;
  }

  private addInfoData(vehicle: LatestVehicleEntry, info: InfoWindowContent, google?: boolean): void {
    let popupConfigs = AuthStorage.getPopupConfigs();
    if (google) {
      info.content += '<div class="info-data">';
    } else {
      info.content += '<div class="ol-info-data">';
    }

    if (vehicle.driver && vehicle.secondDriver && Permissions.hasModule(Module.HEAVY_TRACK)) {
      info.content += '<div class="info-line"><span class="info-label">'
        + this.translate.instant('monitor.vehicles.secondDriver')
        + '</span>: '
        + '<span class="secondDriver-text"> '
        + this.getStringWithLink('showHideTachoTableChart(\'' + vehicle.plateNumber + '\', '
          + vehicle.id + ', \''
          + vehicle.driver.text + '\', \'' + vehicle.driver.value + '\', \''
          + vehicle.secondDriver.text + '\', \'' + vehicle.secondDriver.value + '\', ' + false
          + ')', vehicle.secondDriver.value)
        + '</span>'
        + '</div>';
      info.bubbleHeight += 13;
    }
    if (popupConfigs.plateNumber) {
      info.content += '<div class="info-line">'
        + '<span class="info-label">'
        + this.translate.instant('vehicles.list.licensePlate')
        + '</span>'
        + '<span class="info-line" id="info-plate-number">' + ': ' + vehicle.plateNumber + ' ' + '' + '</span>'
        + '</div>';
      info.bubbleHeight += 13;
    }
    if (vehicle.address && popupConfigs.address) {
      info.content += '<div class="info-line">'
        + '<span class="info-label">' + this.translate.instant('monitor.vehicles.address') + '</span>: '
        + vehicle.address + ' '
        + this.getCopyElement(vehicle.address, 'copy-content')
        + '</div>';
      info.bubbleHeight += 13;
    }
    if (vehicle.position.latitude && vehicle.position.longitude && popupConfigs.position) {
      let positionContent = vehicle.hasTab && vehicle.trackByTab
        ? '<span class="info-label--error" title="' + this.translate.instant('monitor.vehicles.trackByTab') + '">'
          + this.translate.instant('vehicles.dvr.track.infoWindow.location')
          + '<i class="fa fa-tablet"></i></span>'
        : '<span class="info-label">' + this.translate.instant('vehicles.dvr.track.infoWindow.location')
          + '</span>'
      info.content += '<div class="info-line">' + positionContent
        + ': <a target="_blank" href="http://www.google.com/maps/place/'
        + vehicle.position.latitude + ',' + vehicle.position.longitude + '" style="margin-right: 5px">'
        + vehicle.position.latitude + ', ' + vehicle.position.longitude + '</a>'
        + '<a style="padding-left: 10px" target="_blank" href="https://www.google.com/maps?q=&layer=c&cbll='
        + vehicle.position.latitude + ',' + vehicle.position.longitude + '">'
        + '<i class="fa fa-street-view"></i></a>'
        + this.getCopyElement((vehicle.position.latitude + ',' + vehicle.position.longitude), 'copy')
        + '</div>';
      info.bubbleHeight += 13;
    }

    if (vehicle.speed && popupConfigs.speed) {
      MonitorMarkerService.createInfoLine(
        info,
        this.translate.instant('monitor.vehicles.speed'),
        vehicle.speed,
        this.translate.instant('unit.kmph'));
    }
    if (vehicle.fuel && Permissions.hasModule(Module.HEAVY_TRACK) && popupConfigs.fuel) {
      MonitorMarkerService.createInfoLine(
        info,
        this.translate.instant('monitor.vehicles.fuel'),
        vehicle.fuel,
        this.translate.instant('unit.l'));
    }
    if (vehicle.float1Liters && Permissions.hasModule(Module.HEAVY_TRACK) && popupConfigs.fuel) {
      let liters: string | number;
      if (vehicle.float2Liters) {
        let sum: number = vehicle.float1Liters + vehicle.float2Liters;
        liters = '(' + vehicle.float1Liters + '+' + vehicle.float2Liters + ')=' + sum;
      } else {
        liters = vehicle.float1Liters;
      }
      MonitorMarkerService.createInfoLine(
        info,
        this.translate.instant('monitor.vehicles.floatLiters'),
        liters,
        this.translate.instant('unit.l'));
      MonitorMarkerService.createInfoLine(
        info,
        this.translate.instant('monitor.vehicles.float1Liters'),
        vehicle.float1Liters,
        this.translate.instant('unit.l'));
      if (vehicle.float2Liters) {
        MonitorMarkerService.createInfoLine(
          info,
          this.translate.instant('monitor.vehicles.float2Liters'),
          vehicle.float2Liters,
          this.translate.instant('unit.l'));
      }
    }
    if (vehicle.adblueLevelPercent && Permissions.hasModule(Module.HEAVY_TRACK) && popupConfigs.adblueLevelPercent) {
      let adBlue = vehicle.adBlueTankCapacity
        ? vehicle.adBlueTankCapacity / 100 * vehicle.adblueLevelPercent
        : vehicle.adblueLevelPercent;
      MonitorMarkerService.createInfoLine(
        info,
        this.translate.instant('monitor.vehicles.adblueLevel'),
        adBlue.toFixed(2),
        this.translate.instant(vehicle.adBlueTankCapacity ? 'unit.l' : 'unit.percent'));
    }
    if (vehicle.axleWeight && Permissions.hasModule(Module.HEAVY_TRACK) && popupConfigs.axleWeight) {
      MonitorMarkerService.createInfoLine(
        info,
        this.translate.instant('monitor.vehicles.axleWeight'),
        vehicle.axleWeight,
        this.translate.instant('unit.kg'));
    }
    if (vehicle.axleWeight2 && Permissions.hasModule(Module.HEAVY_TRACK) && popupConfigs.axleWeight2) {
      MonitorMarkerService.createInfoLine(
        info,
        this.translate.instant('monitor.vehicles.axleWeight2'),
        vehicle.axleWeight2,
        this.translate.instant('unit.kg'));
    }
    if (vehicle.grossCombinationVehicleWeight != null
      && Permissions.hasModule(Module.HEAVY_TRACK)
      && popupConfigs.grossCombinationVehicleWeight) {
      MonitorMarkerService.createInfoLine(
        info,
        this.translate.instant('monitor.vehicles.grossCombinationVehicleWeight'),
        vehicle.grossCombinationVehicleWeight,
        this.translate.instant('unit.kg'));
    }
    if (vehicle.tabTime && popupConfigs.tabTime) {
      MonitorMarkerService.createInfoLine(
        info,
        this.translate.instant('monitor.vehicles.tab-gps-update'),
        vehicle.tabTime,
        '');
    }
    if (vehicle.time && popupConfigs.time) {
      MonitorMarkerService.createInfoLine(
        info,
        this.translate.instant('monitor.vehicles.gps-update'),
        vehicle.time,
        '');
    }
    if (vehicle.distance && Permissions.hasModule(Module.HEAVY_TRACK) && popupConfigs.distance) {
      MonitorMarkerService.createInfoLine(
        info,
        this.translate.instant('monitor.vehicles.distance'),
        vehicle.distance,
        this.translate.instant('unit.km'));
    }
    if (vehicle.gpsOdometer && Permissions.hasModule(Module.HEAVY_TRACK) && popupConfigs.distance) {
      MonitorMarkerService.createInfoLine(
        info,
        this.translate.instant('monitor.vehicles.gpsOdometer'),
        vehicle.gpsOdometer,
        this.translate.instant('unit.km'));
    }
    if (vehicle.distanceToday && Permissions.hasModule(Module.HEAVY_TRACK) && popupConfigs.distanceToday) {
      MonitorMarkerService.createInfoLine(
        info,
        this.translate.instant('monitor.vehicles.distanceToday'),
        vehicle.distanceToday,
        this.translate.instant('unit.km'));
    }
    if (vehicle.taskDistance
      && Permissions.hasModule(Module.HEAVY_TRACK)
      && vehicle.taskCount > 0
      && popupConfigs.taskDistance) {
      MonitorMarkerService.createInfoLine(
        info,
        this.translate.instant('monitor.vehicles.taskDistance'),
        (vehicle.taskDistance / 1000).toFixed(1),
        this.translate.instant('unit.km'));
    }
    if (vehicle.externalPowerV && Permissions.hasModule(Module.HEAVY_TRACK) && popupConfigs.externalPowerV) {
      MonitorMarkerService.createInfoLine(
        info,
        this.translate.instant('monitor.vehicles.externalPowerV'),
        vehicle.externalPowerV / 1000,
        this.translate.instant('unit.V'));
    }
    if (vehicle.batteryLevel != null && Permissions.hasModule(Module.HEAVY_TRACK) && popupConfigs.batteryLevel) {
      MonitorMarkerService.createInfoLine(
        info,
        this.translate.instant('monitor.vehicles.batteryLevel'),
        vehicle.batteryLevel,
        '%');
    }
    if (vehicle.engineHours && Permissions.hasModule(Module.HEAVY_TRACK) && popupConfigs.engineHours) {
      MonitorMarkerService.createInfoLine(
        info,
        this.translate.instant('monitor.vehicles.engineHours'),
        vehicle.engineHours,
        this.translate.instant('unit.h'));
    }
    if (vehicle.connectedTrailer != null && Permissions.hasModule(Module.HEAVY_TRACK)) {
      this.addConnectedTrailerData(vehicle, popupConfigs, info);
    }
    this.addVehicleTemperatureData(vehicle, popupConfigs, info);
    if (vehicle.driverSimNo && popupConfigs.driverPhoneNumber) {
      MonitorMarkerService.createInfoLine(
        info,
        this.translate.instant('monitor.vehicles.driverSimNo'),
        vehicle.driverSimNo,
        '');
    }
    if (vehicle.latestActivity != null && vehicle.latestActivity.drivingLeft != null && popupConfigs.drivingLeft) {
      MonitorMarkerService.createInfoLine(
        info,
        this.translate.instant('monitor.vehicles.drivingLeft'),
        vehicle.latestActivity.drivingLeft,
        '');
    }
    info.content += '</div>';
  }

  private addQuickLinkToConfigs(): string {
    if (AuthStorage.isLoggedClientEmployee()) {
      return MonitorMarkerService.getActionLink(`navigateToInfoPopupConfigs()`, 'fa fa-wrench',
        'navigate-to-info-window-configs', this.translate, 'quick-configs-btn')
    }
    return '';
  }

  private addVehicleTemperatureData(vehicle: LatestVehicleEntry, popupSettings: PopupConfigsForm, info: InfoWindowContent) {
    // ---------------------------- First compartment -----------------------------------
    if (vehicle.setFreezerTemperature != null && popupSettings.setFreezerTemperature) {
      MonitorMarkerService.createInfoLine(
        info,
        this.translate.instant('monitor.vehicles.setFreezerTemperature'),
        vehicle.setFreezerTemperature,
        this.translate.instant('unit.t'));
    }
    const connectedTrailer = vehicle.connectedTrailer;
    if (popupSettings.supplyFreezerTemperature && (vehicle.supplyFreezerTemperature != null && connectedTrailer == null)
      || (vehicle.supplyFreezerTemperature != null
          && connectedTrailer != null
          && connectedTrailer.supplyFreezerTemperature == null)) {
      MonitorMarkerService.createInfoLine(
        info,
        this.translate.instant('monitor.vehicles.supplyFreezerTemperature'),
        vehicle.supplyFreezerTemperature,
        this.translate.instant('unit.t'));
    }
    if (vehicle.actualFreezerTemperature != null && popupSettings.actualFreezerTemperature) {
      MonitorMarkerService.createInfoLine(
        info,
        this.translate.instant('monitor.vehicles.actualFreezerTemperature'),
        vehicle.actualFreezerTemperature,
        this.translate.instant('unit.t'));
    }
    if (vehicle.freezerEngineOn != null && popupSettings.freezerEngineOn) {
      MonitorMarkerService.createInfoLine(
        info,
        this.translate.instant('monitor.vehicles.freezerEngineOn'),
        vehicle.freezerEngineOn
          ? this.translate.instant('monitor.vehicles.ON')
          : this.translate.instant('monitor.vehicles.OFF'),
        '');
    }
    if (vehicle.freezerMode != null && popupSettings.freezerMode) {
      MonitorMarkerService.createInfoLine(
        info,
        this.translate.instant('monitor.vehicles.freezerMode'),
        this.translate.instant('monitor.vehicles.freezerModes.' + vehicle.freezerMode),
        '');
    }
    // ---------------------------- Second compartment -----------------------------------
    if (vehicle.secondCompartmentSetPoint != null && popupSettings.secondCompartmentSetPoint) {
      MonitorMarkerService.createInfoLine(
        info,
        this.translate.instant('monitor.vehicles.secondCompartmentSetPoint'),
        vehicle.secondCompartmentSetPoint,
        this.translate.instant('unit.t'));
    }
    if (vehicle.secondCompartmentSupplyTemperature != null && popupSettings.secondCompartmentSupplyTemperature) {
      MonitorMarkerService.createInfoLine(
        info,
        this.translate.instant('monitor.vehicles.secondCompartmentSupplyTemperature'),
        vehicle.secondCompartmentSupplyTemperature,
        this.translate.instant('unit.t'));
    }
    if (vehicle.secondCompartmentTemperature != null && popupSettings.secondCompartmentTemperature) {
      MonitorMarkerService.createInfoLine(
        info,
        this.translate.instant('monitor.vehicles.secondCompartmentTemperature'),
        vehicle.secondCompartmentTemperature,
        this.translate.instant('unit.t'));
    }
    if (vehicle.secondCompartmentFreezerMode != null && popupSettings.secondCompartmentFreezerMode) {
      MonitorMarkerService.createInfoLine(
        info,
        this.translate.instant('monitor.vehicles.secondCompartmentFreezerMode'),
        vehicle.secondCompartmentFreezerMode,
        '');
    }
    if (vehicle.secondCompartmentIsOn != null && popupSettings.secondCompartmentIsOn) {
      MonitorMarkerService.createInfoLine(
        info,
        this.translate.instant('monitor.vehicles.secondCompartmentIsOn'),
        vehicle.secondCompartmentIsOn
          ? this.translate.instant('monitor.vehicles.ON')
          : this.translate.instant('monitor.vehicles.OFF'),
        '');
    }
    // ---------------------------- Sensor temps ----------------------------------------
    if (vehicle.firstSensorTemperature != null && popupSettings.firstSensorTemperature) {
      MonitorMarkerService.createInfoLine(
        info,
        this.translate.instant('monitor.vehicles.firstSensorTemperature'),
        vehicle.firstSensorTemperature,
        this.translate.instant('unit.t'));
    }
    if (vehicle.secondSensorTemperature != null && popupSettings.secondSensorTemperature) {
      MonitorMarkerService.createInfoLine(
        info,
        this.translate.instant('monitor.vehicles.secondSensorTemperature'),
        vehicle.secondSensorTemperature,
        this.translate.instant('unit.t'));
    }
    if (vehicle.thirdSensorTemperature != null && popupSettings.thirdSensorTemperature) {
      MonitorMarkerService.createInfoLine(
        info,
        this.translate.instant('monitor.vehicles.thirdSensorTemperature'),
        vehicle.thirdSensorTemperature,
        this.translate.instant('unit.t'));
    }
    if (vehicle.fourthSensorTemperature != null && popupSettings.fourthSensorTemperature) {
      MonitorMarkerService.createInfoLine(
        info,
        this.translate.instant('monitor.vehicles.fourthSensorTemperature'),
        vehicle.fourthSensorTemperature,
        this.translate.instant('unit.t'));
    }
    // ---------------------------- Other -----------------------------------------------
    if (vehicle.ambientTemperature != null && popupSettings.ambientTemperature) {
      MonitorMarkerService.createInfoLine(
        info,
        this.translate.instant('monitor.vehicles.ambientTemperature'),
        vehicle.ambientTemperature,
        this.translate.instant('unit.t'));
    }
    if (vehicle.freezerPowerMode != null && popupSettings.freezerPowerMode) {
      MonitorMarkerService.createInfoLine(
        info,
        this.translate.instant('monitor.vehicles.freezerPowerMode'),
        this.translate.instant('monitor.vehicles.freezerPowerModes.' + vehicle.freezerPowerMode),
        '');
    }
    if (vehicle.freezerRunMode != null && popupSettings.freezerRunMode) {
      MonitorMarkerService.createInfoLine(
        info,
        this.translate.instant('monitor.vehicles.freezerRunMode'),
        this.translate.instant('monitor.vehicles.freezerRunModes.' + vehicle.freezerRunMode),
        '');
    }
    if (vehicle.freezerDieselHours != null && popupSettings.freezerDieselHours) {
      MonitorMarkerService.createInfoLine(
        info,
        this.translate.instant('monitor.vehicles.freezerDieselHours'),
        vehicle.freezerDieselHours,
        this.translate.instant('unit.h'));
    }
    if (vehicle.freezerElectricHours != null && popupSettings.freezerElectricHours) {
      MonitorMarkerService.createInfoLine(
        info,
        this.translate.instant('monitor.vehicles.freezerElectricHours'),
        vehicle.freezerElectricHours,
        this.translate.instant('unit.h'));
    }
    if (vehicle.freezerStandbyHours != null && popupSettings.freezerStandbyHours) {
      MonitorMarkerService.createInfoLine(
        info,
        this.translate.instant('monitor.vehicles.freezerStandbyHours'),
        vehicle.freezerStandbyHours,
        this.translate.instant('unit.h'));
    }
    if (vehicle.engineTemperature != null && popupSettings.engineTemperature) {
      MonitorMarkerService.createInfoLine(
        info,
        this.translate.instant('monitor.vehicles.engineTemperature'),
        vehicle.engineTemperature,
        this.translate.instant('unit.t'));
    }
  }

  private addConnectedTrailerData(vehicle: LatestVehicleEntry, popupSettings: PopupConfigsForm, info: InfoWindowContent) {
    // ---------------------------- First compartment -----------------------------------
    const trailer = vehicle.connectedTrailer;
    if (trailer.setFreezerTemperature != null
      && vehicle.setFreezerTemperature == null && popupSettings.setFreezerTemperature) {
      MonitorMarkerService.createInfoLine(
        info,
        this.translate.instant('monitor.vehicles.setFreezerTemperature'),
        trailer.setFreezerTemperature,
        this.translate.instant('unit.t'));
    }
    if (trailer.supplyFreezerTemperature != null
      && popupSettings.supplyFreezerTemperature) {
      MonitorMarkerService.createInfoLine(
        info,
        this.translate.instant('monitor.vehicles.supplyFreezerTemperature'),
        trailer.supplyFreezerTemperature,
        this.translate.instant('unit.t'));
    }
    if (trailer.actualFreezerTemperature != null
      && vehicle.actualFreezerTemperature == null && popupSettings.actualFreezerTemperature) {
      MonitorMarkerService.createInfoLine(
        info,
        this.translate.instant('monitor.vehicles.actualFreezerTemperature'),
        trailer.actualFreezerTemperature,
        this.translate.instant('unit.t'));
    }
    if (trailer.freezerMode != null && popupSettings.freezerMode) {
      MonitorMarkerService.createInfoLine(
        info,
        this.translate.instant('monitor.vehicles.freezerMode'),
        this.translate.instant('monitor.vehicles.freezerModes.' + trailer.freezerMode),
        '');
    }
    if (trailer.freezerEngineOn != null
      && vehicle.freezerEngineOn == null && popupSettings.freezerEngineOn) {
      MonitorMarkerService.createInfoLine(
        info,
        this.translate.instant('monitor.vehicles.freezerEngineOn'),
        trailer.freezerEngineOn
          ? this.translate.instant('monitor.vehicles.ON')
          : this.translate.instant('monitor.vehicles.OFF'),
        '');
    }
    // ---------------------------- Second compartment -----------------------------------
    if (trailer.secondCompartmentSetPoint != null
      && vehicle.secondCompartmentSetPoint == null && popupSettings.secondCompartmentSetPoint) {
      MonitorMarkerService.createInfoLine(
        info,
        this.translate.instant('monitor.vehicles.secondCompartmentSetPoint'),
        trailer.secondCompartmentSetPoint,
        this.translate.instant('unit.t'));
    }
    if (trailer.secondCompartmentSupplyTemperature != null
      && vehicle.secondCompartmentSupplyTemperature == null && popupSettings.secondCompartmentSupplyTemperature) {
      MonitorMarkerService.createInfoLine(
        info,
        this.translate.instant('monitor.vehicles.secondCompartmentSupplyTemperature'),
        trailer.secondCompartmentSupplyTemperature,
        this.translate.instant('unit.t'));
    }
    if (trailer.secondCompartmentTemperature != null
      && vehicle.secondCompartmentTemperature == null && popupSettings.secondCompartmentTemperature) {
      MonitorMarkerService.createInfoLine(
        info,
        this.translate.instant('monitor.vehicles.secondCompartmentTemperature'),
        trailer.secondCompartmentTemperature,
        this.translate.instant('unit.t'));
    }
    if (trailer.secondCompartmentFreezerMode != null
      && vehicle.secondCompartmentFreezerMode == null && popupSettings.secondCompartmentFreezerMode) {
      MonitorMarkerService.createInfoLine(
        info,
        this.translate.instant('monitor.vehicles.secondCompartmentFreezerMode'),
        trailer.secondCompartmentFreezerMode,
        this.translate.instant('unit.t'));
    }
    if (trailer.secondCompartmentIsOn != null
      && vehicle.secondCompartmentIsOn == null && popupSettings.secondCompartmentIsOn) {
      MonitorMarkerService.createInfoLine(
        info,
        this.translate.instant('monitor.vehicles.secondCompartmentIsOn'),
        trailer.secondCompartmentIsOn
          ? this.translate.instant('monitor.vehicles.ON')
          : this.translate.instant('monitor.vehicles.OFF'),
        '');
    }
    // ---------------------------- Sensor temps ----------------------------------------
    if (trailer.firstSensorTemperature
      && vehicle.firstSensorTemperature == null && popupSettings.firstSensorTemperature) {
      MonitorMarkerService.createInfoLine(
        info,
        this.translate.instant('monitor.vehicles.firstSensorTemperature'),
        trailer.firstSensorTemperature,
        this.translate.instant('unit.t'));
    }
    if (trailer.secondSensorTemperature
      && vehicle.secondSensorTemperature == null && popupSettings.secondSensorTemperature) {
      MonitorMarkerService.createInfoLine(
        info,
        this.translate.instant('monitor.vehicles.secondSensorTemperature'),
        trailer.secondSensorTemperature,
        this.translate.instant('unit.t'));
    }
    if (trailer.thirdSensorTemperature
      && vehicle.thirdSensorTemperature == null && popupSettings.thirdSensorTemperature) {
      MonitorMarkerService.createInfoLine(
        info,
        this.translate.instant('monitor.vehicles.thirdSensorTemperature'),
        trailer.thirdSensorTemperature,
        this.translate.instant('unit.t'));
    }
    if (trailer.fourthSensorTemperature
      && vehicle.fourthSensorTemperature == null && popupSettings.fourthSensorTemperature) {
      MonitorMarkerService.createInfoLine(
        info,
        this.translate.instant('monitor.vehicles.fourthSensorTemperature'),
        trailer.fourthSensorTemperature,
        this.translate.instant('unit.t'));
    }
    // ---------------------------- Other -----------------------------------------------
    if (trailer.ambientTemperature != null && popupSettings.ambientTemperature) {
      MonitorMarkerService.createInfoLine(
        info,
        this.translate.instant('monitor.vehicles.ambientTemperature'),
        trailer.ambientTemperature,
        this.translate.instant('unit.t'));
    }
    if (trailer.freezerPowerMode != null && popupSettings.freezerPowerMode) {
      MonitorMarkerService.createInfoLine(
        info,
        this.translate.instant('monitor.vehicles.freezerPowerMode'),
        this.translate.instant('monitor.vehicles.freezerPowerModes.' + trailer.freezerPowerMode),
        '');
    }
    if (trailer.freezerRunMode != null && popupSettings.freezerRunMode) {
      MonitorMarkerService.createInfoLine(
        info,
        this.translate.instant('monitor.vehicles.freezerRunMode'),
        this.translate.instant('monitor.vehicles.freezerRunModes.' + trailer.freezerRunMode),
        '');
    }
    if (trailer.freezerDieselHours != null && popupSettings.freezerDieselHours) {
      MonitorMarkerService.createInfoLine(
        info,
        this.translate.instant('monitor.vehicles.freezerDieselHours'),
        trailer.freezerDieselHours,
        this.translate.instant('unit.h'));
    }
    if (trailer.freezerElectricHours != null && popupSettings.freezerElectricHours) {
      MonitorMarkerService.createInfoLine(
        info,
        this.translate.instant('monitor.vehicles.freezerElectricHours'),
        trailer.freezerElectricHours,
        this.translate.instant('unit.h'));
    }
    if (trailer.freezerStandbyHours != null && popupSettings.freezerStandbyHours) {
      MonitorMarkerService.createInfoLine(
        info,
        this.translate.instant('monitor.vehicles.freezerStandbyHours'),
        trailer.freezerStandbyHours,
        this.translate.instant('unit.h'));
    }
  }

  private addClusterInfoData(features: any, info: InfoWindowContent) {
    let popupSettings = AuthStorage.getPopupConfigs();
    info.content += '<div class="ol-cluster-info-data">';
    for (const feature of features) {
      let vehicle = feature.values_.name;
      if (popupSettings.plateNumber) {
        info.content += MonitorMarkerService.getIconWithString(
          'fa-circle',
          vehicle.plateNumber,
          this.getIconColor(vehicle.status),
          `showVehicle(${vehicle.id})`);
        info.bubbleHeight += 13;
      }
    }
  }

  public static createInfoLine(info: InfoWindowContent, name: string, value: any, units: string) {
    info.content += '<div class="info-line"><span class="info-label">'
      + name + '</span>: ' + value + ' ' + units + '</div>';
    info.bubbleHeight += 13;
  }

  public static showStationPrices(stationPrices: StationPrice[], info: InfoWindowContent) {
    if (stationPrices && stationPrices.length > 0 && stationPrices !== null) {
      info.content += '<div class="fuel-prices">';
      for (let i = 0; i < stationPrices.length; i++) {
        let product: any = stationPrices[i];
        if (product.price > 0) {
          let color = product.product === 'DIESEL' ? '#000000' : '#3153bf';
          info.content +=
            '<span class="fuel-type" style="background-color: ' + color + '">'
            + product.product + '</span>' +
            '<span class="info-label">' + product.price + ' ' + product.currency + '</span>';
          info.bubbleHeight += 13;
        }
      }
      info.content += '</div>';
    }
  }

  public static showGlobalTankPrices(prices: GlobalTankPoiPricesEntry, info: InfoWindowContent) {
    info.content += '<div class="fuel-prices">';
    if (prices.dieselPrice != null && prices.dieselPrice > 0) {
      info.content +=
        '<span class="fuel-type" style="background-color: ' + '#000000' + '">'
        + 'DIESEL' + '</span>' +
        '<span class="info-label">' + prices.dieselPrice + ' ' + 'EUR' + '</span>';
    }
    if (prices.adBluePrice != null && prices.adBluePrice > 0) {
      info.content +=
        '<span class="fuel-type" style="background-color: ' + '#3153bf' + '">'
        + 'AD_BLUE' + '</span>' +
        '<span class="info-label">' + prices.adBluePrice + ' ' + 'EUR' + '</span>';
    }
    info.bubbleHeight += 13;
    info.content += '</div>';
  }

  private addButtons(vehicle: LatestVehicleEntry, info: InfoWindowContent): void {
    info.content += '<div class="popup-buttons">';
    info.content += this.addQuickLinkToConfigs();
    if (vehicle.hasTab && AuthStorage.isLoggedClientEmployee()) {
      info.content += MonitorMarkerService.getIconWithLink('previewTasks(' + vehicle.id + ')',
        'fa-check',
        'tasks',
        this.translate);
      if (!isWebview(navigator.userAgent)) {
        let driver = vehicle.driver ? vehicle.driver.value : null;
        info.content += MonitorMarkerService.getIconWithLink('openChat(' + vehicle.id + ', \''
          + vehicle.plateNumber + '\', \'' + driver + '\', \''
          + vehicle.warnings.tabOffline + '\')',
          'fa-comment-o',
          'chat',
          this.translate);
      }
      info.bubbleWidth += 5
    }

    if (vehicle.hasDvr && AuthStorage.isLoggedClientEmployee()) {
      info.content += MonitorMarkerService.getIconWithLink('previewVideo(' + vehicle.id + ')',
        'fa-play',
        'dvrLive',
        this.translate);
      info.bubbleWidth += 5;
      if (vehicle.dvrModel === EnumValues.getNameFromValue(DVRModel, DVRModel.NEW_DVR)) {
        info.content += MonitorMarkerService.getIconWithLink(
          'previewVideos(' + vehicle.id + ')',
          'fa-history',
          'dvrHistory',
          this.translate);
      } else if (vehicle.dvrModel === EnumValues.getNameFromValue(DVRModel, DVRModel.OLD_DVR)) {
        info.content += MonitorMarkerService.getIconWithLink(
          'previewHistory(' + vehicle.id + ')',
          'fa-history',
          'dvrHistory',
          this.translate);
      }
      info.bubbleWidth += 5;
    }

    if (vehicle.hasGPSDevice && (AuthStorage.isLoggedClientEmployee()
      || Permissions.hasModule(Module.HEAVY_TRACK))) {
      info.content += MonitorMarkerService.getIconWithLink(`previewTrack(${vehicle.id})`,
        'fa-road',
        'drivingHistory',
        this.translate);
      info.content += MonitorMarkerService.getIconWithLink(`addToStartRoute(
        ${vehicle.position.latitude}, ${vehicle.position.longitude}, '${vehicle.plateNumber}')`,
        'fa-location-arrow', 'route',
        this.translate);
      info.bubbleWidth += 5;
    }

    if (vehicle.hasElectricLock) {
      if (vehicle.electricLockStatus === 'LOCKED') {
        info.content += MonitorMarkerService.getIconWithLink(`openElectricLock(${vehicle.id})`,
          'fa-lock-open',
          'open-lock',
          this.translate);
      } else if (vehicle.electricLockStatus === 'UNLOCKED') {
        info.content += MonitorMarkerService.getIconWithLink(`closeElectricLock(${vehicle.id})`,
          'fa-lock',
          'lock',
          this.translate);
      }
      // if (vehicle.keyboardLocked) {
      //   info.content += MonitorMarkerService.getIconWithLink(`unlockKeyboard(${vehicle.id})`,
      //     'fa-keyboard-o',
      //     'unlock-keyboard',
      //     this.translate);
      // } else {
      //   info.content += MonitorMarkerService.getIconWithLink(`lockKeyboard(${vehicle.id})`,
      //     'fa-keyboard-o',
      //     'lock-keyboard',
      //     this.translate);
      // }
      info.bubbleWidth += 5;
    }

    if (vehicle.hasGPSDevice && vehicle.tapa && AuthStorage.isLoggedClientEmployee()
      && Permissions.hasModule(Module.HEAVY_TRACK)) {
        info.content += MonitorMarkerService.getIconWithLink(`openTapa(${vehicle.id})`,
          'fa-shield',
          'open-tapa',
          this.translate);
      info.bubbleWidth += 5;
    }

    if (vehicle.hasGPSDevice && !AuthStorage.isLoggedClientEmployee()
      && !Permissions.hasModule(Module.HEAVY_TRACK)) {
      info.content += MonitorMarkerService.getIconWithLink(`previewPartnerTrack(${vehicle.id})`,
        'fa-road',
        'track',
        this.translate);
      info.bubbleWidth += 5;
    }

    if (vehicle.driver && vehicle.hasTacho && Permissions.hasModule(Module.HEAVY_TRACK)) {
      let secondDriverText = vehicle.secondDriver ? vehicle.secondDriver.text : null;
      let secondDriverValue = vehicle.secondDriver ? vehicle.secondDriver.value : null;
      info.content += MonitorMarkerService.getIconWithLink('showHideTacho(\'' + vehicle.plateNumber + '\', '
        + vehicle.id + ', '
        + '\'CHART\', \'' + vehicle.driver.text + '\', \'' + vehicle.driver.value + '\', \''
        + secondDriverText + '\', \'' + secondDriverValue + '\')',
        'fa-bar-chart',
        'charts',
        this.translate);
      info.content += MonitorMarkerService.getIconWithLink('showHideTacho(\'' + vehicle.plateNumber + '\', '
        + vehicle.id + ', '
        + '\'TABLE\', \'' + vehicle.driver.text + '\', \'' + vehicle.driver.value + '\', \''
        + secondDriverText + '\', \'' + secondDriverValue + '\')',
        'fa-table',
        'tables',
        this.translate);
      info.bubbleWidth += 5;
    }

    if (this.showTasksBtn(vehicle.taskCount)) {
      info.content += MonitorMarkerService.getIconWithLink(
        'showTasksPolyline(\'' + vehicle.id + '\')',
        'fa-eye',
        'tasks',
        this.translate);
      info.bubbleWidth += 5;
    }

    info.content += MonitorMarkerService.getActionLink(`showTrackHistory(${vehicle.id}, '${vehicle.plateNumber}')`,
      'fa-long-arrow-right', 'drivingHistory', this.translate);
    info.bubbleWidth += 5;

    info.content += MonitorMarkerService.getIconWithLink('showGpsMoveChart(' + vehicle.id + ', ' +
      '\'' + vehicle.plateNumber + '\')',
      'fa-area-chart',
      'gps-move',
      this.translate);
    info.bubbleWidth += 5;

    if (!AuthStorage.isLoggedPartnerEmployee()) {
      this.addLinkNotesButton(info, vehicle);
      this.addLinkDriverNotificationButton(info, vehicle);
    }

    if (vehicle.connectedVehicle && vehicle.connectedTrailer) {
      const warnings = new TrailerWarnings(vehicle.connectedTrailer);
      if (warnings.isActive()) {
        this.addLinkConnectedTrailerButton(info, vehicle.connectedVehicle, 'warning')
      } else {
        this.addLinkConnectedTrailerButton(info, vehicle.connectedVehicle, 'okay');
      }
    }

    if (vehicle.connectedVehicle && !vehicle.connectedTrailer) {
      this.addBackToTrailerButton(info, vehicle.connectedVehicle, 'okay');
    }

    if (vehicle.shellCardId != null && AuthStorage.isLoggedClientEmployee()) {
      info.content += MonitorMarkerService.getIconWithLink(`shellCardBlock(${vehicle.shellCardId})`,
        'fa-credit-card-alt',
        'manage-shell-card',
        this.translate);
      info.bubbleWidth += 5;
    }

    if (vehicle.showTellTale && AuthStorage.isLoggedClientEmployee() && Permissions.hasModule(Module.TELL_TALE)) {
      this.addLinkDashboardButton(info, vehicle);
    }

    if (this.showLabels()) {
      this.addLabelsButton(info, vehicle, 'okay');
    }

    info.content += '</div></div>';
  }

  private showLabels(): boolean {
    return !Permissions.hasRole(Role.ROLE_PARTNER_EMPLOYEE)
      && (Permissions.hasModule(Module.PLANNING) || Permissions.hasModule(Module.TRAILER_SWAP));
  }

  private showTasksBtn(taskCount: number): boolean {
    return AuthStorage.isLoggedPartnerEmployee()
      ? taskCount > 0 && (Permissions.hasModule(Module.VIEW_TASKS) || Permissions.hasModule(Module.MANAGEMENT))
      : taskCount > 0;
  }

  private addLinkNotesButton(info: InfoWindowContent, vehicle: LatestVehicleEntry) {
    info.content += MonitorMarkerService.getIconWithLink(`showVehicleNotes(${vehicle.id},
    '${vehicle.plateNumber}', '${vehicle.driver}', '${vehicle.driverId}')`,
      'fa fa-sticky-note-o',
      'vehicle-notes',
      this.translate,
      vehicle.noteStatuses ? `${vehicle.noteStatuses.length} ` : undefined);
  }

  private addLinkDriverNotificationButton(info: InfoWindowContent, vehicle: LatestVehicleEntry) {
    info.content += MonitorMarkerService.getIconWithLink(`showDriverNotifications(${vehicle.id},
    '${vehicle.plateNumber}')`,
      'fa-bell-o',
      'drivers-notifications',
      this.translate);
  }

  private addLinkDashboardButton(info: InfoWindowContent, vehicle: LatestVehicleEntry) {
    info.content += MonitorMarkerService.getIconWithLink(`showVehicleDashboard(${vehicle.id},
    '${vehicle.deviceKey}',
    '${vehicle.plateNumber}',
    '${vehicle.speed}',
    '${vehicle.engineRpm}',
    '${vehicle.fuel}',
    '${vehicle.fuelTankCapacity}',
    '${vehicle.adblueLevelPercent}',
    '${vehicle.adBlueTankCapacity}',
    '${vehicle.distance}',
    '${vehicle.distanceToday}')`,
      'fa-tachometer',
      'dashboard',
      this.translate);
  }

  private getStringWithLink(action: string, text: string): string {
    return '<a class="cursor-pointer" ' + 'onclick="window.angularComponentRef.zone.run(() => ' +
      '{window.angularComponentRef.component.' + action + '})">' + text + '</a>';
  }

  public static getIconWithLink(action: string,
                                icon: string,
                                tooltip: string,
                                translate: TranslateService,
                                valueNearIcon: string = '',
                                tooltipValue: string = '',
                                buttonColour?: string,
                                isClickable?: boolean): string {

    let onClickEvent = isClickable || isClickable == null
      ? 'onclick="window.angularComponentRef.zone.run(() => ' +
      '{window.angularComponentRef.component.' + action + '})">'
      : 'onclick="return false;">';
    return '<a class="map-popup__button map-popup__button--' + buttonColour + '" ' +
      'title ="' + translate.instant('monitor.map.title.' + tooltip) + tooltipValue + '"' +
      onClickEvent + valueNearIcon + '<i class="fa ' + icon + '"></i></a>';
  }

  public static getIconWithString(icon: string, text: string, color: string, action: string) {
    return '<a style="cursor: pointer"' + 'onclick="window.angularComponentRef.zone.run(() => ' +
      '{window.angularComponentRef.component.' + action + '})"><div><i style="color:' + color +
      '" class="fa ' + icon + '"></i>' + ' ' + text + '</div></a>';
  }

  private getIconColor(status: string) {
    switch (status) {
      case Status[Status.MOVING]:
        return '#77C712';
      case Status[Status.NOT_MOVING]:
        return '#5F8ABB';
      case Status[Status.WARNING]:
        return '#B73C1A';
      case Status[Status.OFFLINE]:
        return '#8F8F8F';
      case Status[Status.ENGINE_ON]:
        return '#F8C410';
      case Status[Status.IDLE]:
        return '#AC59BC';
      case Status[Status.LOADING_UNLOADING_DELAY]:
        return '#b63019';
      default:
        return '#8F8F8F';
    }
  }

  public getPOIWindowContent(poi: MapPOIEntry, uuid?: string, stationPrices?: StationPrice[], type?: string): InfoWindowContent {
    let info: InfoWindowContent = {
      content: '',
      bubbleHeight: this.deviceService.isSafariBrowser() ? 80 : 70,
      bubbleWidth: 300
    };
    info.content += '<div class="popup-content"><div class="ol-popup-header"><span>'
      + poi.name + '</span></div>';
    info.content += '<div class="ol-info-data">';
    if (stationPrices) {
      MonitorMarkerService.showStationPrices(stationPrices, info);
    }
    if (poi.globalTankPrices != null) {
      MonitorMarkerService.showGlobalTankPrices(poi.globalTankPrices, info);
    }
    if (MonitorMarkerService.isAS24Station(poi.poiGroup.id)) {
      MonitorMarkerService.showGlobalTankPrices({dieselPrice: poi.fuelPrice, adBluePrice: poi.adbluePrice}, info);
    }
    MonitorMarkerService.addPoiGroupInfo(poi, info, this.translate);

    info.content += '<div class="popup-buttons">';
    info.content += MonitorMarkerService
      .getActionLink(`addToRoute('${uuid}', '${type}')`, 'fa-location-arrow', 'route', this.translate);
    if (this.showEditPOI(poi)) {
      info.content += MonitorMarkerService.getIconWithLink('editPOI(' + poi.id + ')',
        'fa-pencil',
        'editPoi',
        this.translate);
    }

    info.content += '</div></div>';
    return info;
  }

  private showEditPOI(poi: MapPOIEntry) {
    if (poi.securelyLocked || GENERAL_STATION_GROUP_IDS.includes(poi.poiGroup.id)) {
      return false;
    }
    return poi.department == null || poi.department === AuthStorage.getUserDepartment();
  }

  public static addPoiGroupInfo(poi: MapPOIEntry, info: InfoWindowContent, translate: TranslateService) {
    if (poi.network) {
      MonitorMarkerService.createInfoLine(info, translate.instant('monitor.poi.network'), poi.network, '');
    }
    MonitorMarkerService.createInfoLine(
      info,
      translate.instant('monitor.poi.address'),
      MonitorMarkerService.splitToLines(poi.address, info),
      '');
    if (poi.address && poi.address.length > 30) {
      info.bubbleHeight += 13;
    }
    info.content += '<div class="info-line"><span class="info-label">'
      + translate.instant('vehicles.dvr.track.infoWindow.location')
      + '</span>: <a target="_blank" href="http://www.google.com/maps/place/'
      + poi.latitude + ',' + poi.longitude + '">' + poi.latitude
      + ', ' + poi.longitude + '</a>       '
      + '<a style="padding-left: 10px" target="_blank" href="https://www.google.com/maps?q=&layer=c&cbll='
      + poi.latitude + ',' + poi.longitude + '">'
      + '<i class="fa fa-street-view"></i></a></div>';
    info.bubbleHeight += 13;

    if (poi.poiGroup.name !== 'CRT_FUEL_STATIONS') {
      MonitorMarkerService.createInfoLine(info, translate.instant('monitor.poi.group'), poi.poiGroup.name, '');
    }
    if (poi.quantity) {
      MonitorMarkerService.createInfoLine(
        info,
        translate.instant('monitor.poi.quantity'),
        poi.quantity,
        '');
    }
    if (poi.comment) {
      MonitorMarkerService.createInfoLine(
        info,
        translate.instant('monitor.poi.comment'),
        MonitorMarkerService.splitToLines(poi.comment, info),
        '');
    }
  }

  static addPoiParkingDetails(parkingDetails: POIParkingDetails, info: InfoWindowContent, translate: TranslateService) {
    let values = parkingDetails.paid
      ? translate.instant('monitor.poi.parking-details.paid')
      : translate.instant('monitor.poi.parking-details.free');
    values += parkingDetails.fence ? ', ' + translate.instant('monitor.poi.parking-details.fence') : '';
    values += parkingDetails.monitoring ? ', ' + translate.instant('monitor.poi.parking-details.monitoring') : '';
    values += parkingDetails.security ? ', ' + translate.instant('monitor.poi.parking-details.security') : '';
    values += parkingDetails.lightedStop ? ', ' + translate.instant('monitor.poi.parking-details.lightedStop') : '';
    values += parkingDetails.dangerCargo ? ', ' + translate.instant('monitor.poi.parking-details.dangerCargo') : '';
    values += parkingDetails.toilet ? ', ' + translate.instant('monitor.poi.parking-details.toilet') : '';
    values += parkingDetails.electricity ? ', ' + translate.instant('monitor.poi.parking-details.electricity') : '';
    values += parkingDetails.water ? ', ' + translate.instant('monitor.poi.parking-details.water') : '';
    values += parkingDetails.wifi ? ', ' + translate.instant('monitor.poi.parking-details.wifi') : '';
    values += parkingDetails.medical ? ', ' + translate.instant('monitor.poi.parking-details.medical') : '';
    values += parkingDetails.kitchen ? ', ' + translate.instant('monitor.poi.parking-details.kitchen') : '';
    values += parkingDetails.gym ? ', ' + translate.instant('monitor.poi.parking-details.gym') : '';
    values += parkingDetails.sleep ? ', ' + translate.instant('monitor.poi.parking-details.sleep') : '';
    values += parkingDetails.laundry ? ', ' + translate.instant('monitor.poi.parking-details.laundry') : '';
    MonitorMarkerService.createInfoLine(
      info,
      translate.instant('monitor.poi.details'),
      values,
      ''
    );
  }

  public createOLInfoWindow(lat: any, lng: any, text: string, uuid: any, zoom, type?: string): any {
    let latitude = MathUtils.roundCoordinates(lat);
    let longitude = MathUtils.roundCoordinates(lng);
    let location = `(${lat},${lng})`;

    let coordinates = '<div class="info-line"><span class="info-label">'
      + this.translate.instant('address-search.coordinates')
      + `</span><a target="_blank" href="http://www.google.com/maps/place/${latitude},${longitude}">`
      + `: ${latitude}, ${longitude}</a>`
      + `<a style="padding-left: 10px" target="_blank" href="https://www.google.com/maps?q=&layer=c&cbll=${latitude},${longitude}">`
      + '<i class="fa fa-street-view"></i></a>'
      + '<a style="color:#0275d8; padding-left: 10px;" class="cursor-pointer"'
      + 'title ="' + this.translate.instant('monitor.map.title.' + 'copy') + '"'
      + 'onclick="window.angularMapRef.zone.run(() => '
      + '{window.angularMapRef.component.' + `copyToClipboard('${latitude},${longitude}')` + '})">'
      + '<i class="fa ' + 'fa fa-copy' + '"></i></a></div>';

    let address = '<div class="info-line"><span class="info-label">'
      + this.translate.instant('address-search.address')
      + `</span>: ${text}</div>`;
    let zoomPlusLink = MonitorMarkerService
      .getActionLink(`zoomMarker('${uuid}', ${zoom})`, 'fa-search-plus', 'zoomIn', this.translate);
    let zoomMinusLink = MonitorMarkerService
      .getActionLink(`zoomMarker('${uuid}', ${-zoom})`, 'fa-search-minus', 'zoomOut', this.translate);
    let hideLink = MonitorMarkerService
      .getActionLink(`hideMarker('${uuid}')`, 'fa-close', 'close', this.translate);
    let route = MonitorMarkerService
      .getActionLink(`addToRoute('${uuid}', '${type}')`, 'fa-location-arrow', 'route', this.translate);
    let poi = MonitorMarkerService
      .getIconWithLink('newPOI(\'' + latitude + ', ' + longitude + '\')',
        'fa-map-marker',
        'poi',
        this.translate);
    let nearest = MonitorMarkerService
      .getActionLink(`findNearest('${location}')`, 'fa-crosshairs', 'map', this.translate);
    let geoHistory = MonitorMarkerService
      .getActionLink(`findGeoHistory('${location}')`, 'fa-fast-backward', 'geo-history', this.translate);
    // let trailer = MonitorMarkerService.getActionLink(`changeTrailers('${latitude}','${longitude}')`,
    //   'fa-exchange-alt',
    //   'trailer',
    //   this.translate);

    let content = '<div class="popup-content">';
    content += `<div class="ol-info-data">${coordinates}</div>`;
    content += `<div class="ol-info-data">${address}</div>`;
    content += `<div class="popup-buttons">${zoomPlusLink}${zoomMinusLink}`;
    if (AuthStorage.isLoggedClientEmployee()) {
      content += `${route}${poi}`;
      content += `${nearest}`;
      // content += `${trailer}`
      content += `${geoHistory}`;
    }
    content += `${hideLink}</div></div>`;
    return content;
  }

  public createSimpleOlWindow(lat: any, lng: any, uuid: string): any {
    let latitude = MathUtils.roundCoordinates(lat);
    let longitude = MathUtils.roundCoordinates(lng);
    let coordinates = '<div class="info-line"><span class="info-label">'
      + this.translate.instant('address-search.coordinates')
      + `</span><a target="_blank" href="http://www.google.com/maps/place/${latitude},${longitude}">`
      + `: ${latitude}, ${longitude}</a>`
      + `<a style="padding-left: 10px" target="_blank" href="https://www.google.com/maps?q=&layer=c&cbll=${latitude},${longitude}">`
      + '<i class="fa fa-street-view"></i></a></div>';
    let hideLink = MonitorMarkerService
      .getActionLink(`hideMarker('${uuid}')`, 'fa-close', 'close', this.translate);
    let content = '<div class="popup-content">';
    content += `<div class="ol-info-data">${coordinates}</div>`;
    content += `<div class="popup-buttons"> ${hideLink}</div>`;
    content += `</div>`;
    return content;
  }

  public static getActionLink(
    action: string,
    icon: string,
    tooltip: string,
    translate: TranslateService,
    customClass?: string): string {

    const linkStart = customClass
      ? `<a class="${customClass}"`
      : '<a class="map-popup__button" ';
    return linkStart
      + 'title ="' + translate.instant('monitor.map.title.' + tooltip) + '"'
      + 'onclick="window.angularMapRef.zone.run(() => '
      + '{window.angularMapRef.component.' + action + '})"><i class="fa ' + icon + '"></i></a>';
  }

  public static splitToLines(text: string, info: InfoWindowContent): string {
    if (text == null || text.length <= LINE_LENGTH) {
      return text == null ? '' : text;
    }
    let words = text.split(' ');
    let result = '<br/>';
    info.bubbleHeight += 13;
    let lineLength = 0;
    words.forEach(word => {
      if (lineLength + word.length <= LINE_LENGTH) {
        result += word + ' ';
        lineLength += word.length + 1;
      } else if (word.length <= LINE_LENGTH) {
        result += '<br/>' + word + ' ';
        lineLength = word.length + 1;
        info.bubbleHeight += 13;
      } else {
        while (word.length > LINE_LENGTH) {
          info.bubbleHeight += 13;
          result += word.substring(0, LINE_LENGTH) + '<br/>';
          word = word.substring(LINE_LENGTH);
        }
        result += word + ' ';
        lineLength = word.length + 1;
      }
    });
    return result;
  }

  public getInfoWindowContentForSharedVehicle(vehicle: PartnerPositionVehicleCacheEntry): Observable<InfoWindowContent> {
    return new Observable((infoWindowData) => {
      let info: InfoWindowContent = {
        content: '',
        bubbleHeight: this.deviceService.isSafariBrowser() ? 74 : 64,
        bubbleWidth: 245
      };
      this.addSharedVehicleHeader(vehicle, info);
      this.addSharedVehicleInfoData(vehicle, info);
      infoWindowData.next(info)
    })
  }

  private addSharedVehicleHeader(vehicle: PartnerPositionVehicleCacheEntry, info: InfoWindowContent) {
    info.content += '</div>';
  }

  private addSharedVehicleInfoData(vehicle: PartnerPositionVehicleCacheEntry, info: InfoWindowContent) {
    info.content += '<div class="ol-cluster-info-data">';
    if (vehicle.position.latitude && vehicle.position.longitude) {
      info.content += '<div class="info-line"><span class="info-label">'
        + this.translate.instant('vehicles.dvr.track.infoWindow.location')
        + '</span>: <a target="_blank" href="http://www.google.com/maps/place/'
        + vehicle.position.latitude + ',' + vehicle.position.longitude + '" style="margin-right: 5px">'
        + vehicle.position.latitude + ', ' + vehicle.position.longitude + '</a>'
        + '<a style="padding-left: 10px" target="_blank" href="https://www.google.com/maps?q=&layer=c&cbll='
        + vehicle.position.latitude + ',' + vehicle.position.longitude + '">'
        + '<i class="fa fa-street-view"></i></a></div>';
      info.bubbleHeight += 13;
    }

    if (vehicle.speed) {
      MonitorMarkerService.createInfoLine(
        info,
        this.translate.instant('monitor.vehicles.speed'),
        vehicle.speed,
        this.translate.instant('unit.kmph'));
    }
    if (vehicle.time) {
      MonitorMarkerService.createInfoLine(
        info,
        this.translate.instant('monitor.vehicles.gps-update'),
        vehicle.time,
        '');
    }
    if (vehicle.plateNumber) {
      MonitorMarkerService.createInfoLine(info, this.translate.instant('vehicles.list.licensePlate'), vehicle.plateNumber, '');
    }
    info.content += '</div>';
  }

  private addLinkConnectedTrailerButton(info: InfoWindowContent, connectedVehicle: number, buttonColour: string) {
    info.content += MonitorMarkerService.getIconWithLink('toggleConnectedVehicle('
      + connectedVehicle
      + ')',
      'fa fa-link',
      'show-connected-vehicle',
      this.translate,
      '',
      '',
      buttonColour);
  }

  private addBackToTrailerButton(info: InfoWindowContent, vehicle: number, buttonColour: string) {
    info.content += MonitorMarkerService.getIconWithLink('toggleConnectedVehicle('
      + vehicle
      + ')',
      'fa fa-truck',
      'back-to-truck',
      this.translate,
      '',
      '',
      buttonColour);
  }

  private addLabelsButton(info: InfoWindowContent, vehicle: LatestVehicleEntry, buttonColour: string): void {
    info.content += MonitorMarkerService.getIconWithLink(`manageVehicleLabels(${vehicle.id},'${vehicle.plateNumber}')`,
      'fa fa-hashtag',
      'manage-labels',
      this.translate,
      '',
      '',
      buttonColour);
  }

  public static isCRTStation(externalId: string): boolean {
    return externalId.includes('drv');
  }

  public static isAS24Station(groupName: number): boolean {
    return groupName != null && groupName === AS24_GROUP_ID;
  }

  public static is5StepsHotel(groupName: string): boolean {
    return groupName != null && groupName === FIVE_STEPS_GROUP_NAME;
  }

}
