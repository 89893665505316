import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {IdValue} from '../../../model/id-value';
import {ConnectedTrailerEntry} from '../../latest-vehicles-info/model/connected-trailer.entry';

@Injectable()
export class ConnectionsService {

  constructor(private http: HttpClient) {
  }

  public trailers(): Observable<IdValue<string>[]> {
    return this.http.get<IdValue<string>[]>(`/api/vehicle-connection/not-connected-trailers`);
  }

  public connect(vehicle: number, trailer: number) {
    return this.http.post(
      `/api/vehicle-connection/connect-trailer?vehicleId=${vehicle}&trailerId=${trailer}`,
      {});
  }

  public disconnect(vehicle: number, trailer: number) {
    return this.http.post<Response>(
      `/api/vehicle-connection/disconnect-trailer?vehicleId=${vehicle}&trailerId=${trailer}`,
      {});
  }
}
