export class FilesFilter {

  constructor(public fileName: string,
              public dateFrom: string,
              public dateTill: string,
              public sender: string) {
  }

  public static empty(): FilesFilter {
    return new FilesFilter(null, null, null, null);
  }

}
