import {ConnectedTrailerEntry} from './model/connected-trailer.entry';

export class TrailerWarnings {

  private hasLateNotes: boolean;
  private hasServicesInRed: boolean;

  constructor(trailer: ConnectedTrailerEntry) {
    this.hasLateNotes = trailer.noteStatuses != null &&
      trailer.noteStatuses.map(it => it.noteStatus).includes('LATE');

    this.hasServicesInRed = trailer.dueServices != null &&
      trailer.dueServices.map(it => it.serviceInRed).includes(true);
  }

  public isActive(): boolean {
    return this.hasLateNotes || this.hasServicesInRed;
  }

}
