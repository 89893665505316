import {saveAs} from 'file-saver';

export const XLS = '.xls';
export const PDF = '.pdf';
export const XML = '.xml';
export const CSV = '.csv';
export const ZIP = '.zip';

export class FileUtils {

  public static generateImgUrl(data: Blob) {
    if (data != null) {
      let blob;
      if (data.type === 'application/json') {
        blob = data.slice(0, data.size, 'image/svg+xml');
      }
      return URL.createObjectURL(blob);
    }
    return null;
  }

  public static saveFile(theBlob: Blob, fileName: string, suffix?: string): void {
    suffix == null
      ? saveAs(theBlob, fileName)
      : this.saveWithSuffix(theBlob, fileName, suffix);
  }

  private static saveWithSuffix(theBlob: Blob, filePrefix: string, suffix: string): void {
    let name = this.removeSpaces(filePrefix);
    suffix !== PDF
      ? saveAs(theBlob, name + suffix)
      : saveAs(this.toPdf(theBlob, name), name + PDF);
  }

  public static removeSpaces(fileName: string): string {
    return fileName.replace(/ /g, '_');
  }

  private static toPdf(theBlob: Blob, fileName: string): File {
    return new File(
      [theBlob as any],
      fileName,
      {
        lastModified: new Date().getTime(),
        type: 'pdf'
      }
    );
  }

}
