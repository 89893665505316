import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {IdValue} from '../../model/id-value';
import {DriverPanelEntry} from './model/driver-panel.entry';
import {DriverInfo} from './model/driver.info';
import {DriverArrow} from '../../../pages/client/reports/eco/driver/driver-arrow';

@Injectable()
export class DriverPanelService {

  private checkedDriversSource = new Subject<DriverInfo[]>();
  public checkedDrivers$ = this.checkedDriversSource.asObservable();

  private driverClickedSource = new Subject<string>();
  public driverClicked$ = this.driverClickedSource.asObservable();

  private checkedDepartmentSource = new Subject<number[]>();
  public checkedDepartments$ = this.checkedDepartmentSource.asObservable();

  private selectDriverCardSource = new Subject<string>();
  public selectDriverCard$ = this.selectDriverCardSource.asObservable();

  private driverArrowSource = new Subject<DriverArrow>();
  public driverArrow$ = this.driverArrowSource.asObservable();

  constructor(private http: HttpClient) {
  }

  public clickedDriver(driverCard: string) {
    this.driverClickedSource.next(driverCard);
  }

  public checkedDrivers(drivers: DriverInfo[]): void {
    this.checkedDriversSource.next(drivers);
  }

  public checkedDepartments(departments: number[]) {
    this.checkedDepartmentSource.next(departments);
  }

  public setDriverArrow(driverArrow: DriverArrow): void {
    this.driverArrowSource.next(driverArrow);
  }

  public driversEntry(): Observable<DriverPanelEntry[]> {
    return this.http.get<DriverPanelEntry[]>('/api/drivers/drivers-entry');
  }

  public getDepartments(): Observable<IdValue<string>[]> {
    return this.http.get<IdValue<string>[]>('/api/departments/client');
  }

  public loadSubClients(): Observable<IdValue<string>[]> {
    return this.http.get<IdValue<string>[]>('/api/drivers/sub-clients');
  }
}
