export let trackPopupConfigsForm: any = {
  gpsUploadTime: [null],
  address: [null],
  location: [null],
  driver: [null],
  secondDriver: [null],
  speed: [null],
  distance: [null],
  gpsOdometer: [null],
  fuelLevel: [null],
  adblueLevelPercent: [null],
  axleWeight: [null],
  axleWeight2: [null],
  externalPowerV: [null],
  engineRpm: [null],
  engineHours: [null],
  engineTemperature: [null],
  trackDistance: [null],
  trackFuel: [null],
  gpsTrackDistance: [null],
  stopTime: [null],
  satellite: [null],
  gsmSignalLevel: [null]
};

export class TrackPopupConfigsForm {
  constructor(public gpsUploadTime: boolean,
              public address: boolean,
              public location: boolean,
              public driver: boolean,
              public secondDriver: boolean,
              public speed: boolean,
              public distance: boolean,
              public gpsOdometer: boolean,
              public fuelLevel: boolean,
              public adblueLevelPercent: boolean,
              public axleWeight: boolean,
              public axleWeight2: boolean,
              public externalPowerV: boolean,
              public engineRpm: boolean,
              public engineHours: boolean,
              public engineTemperature: boolean,
              public trackDistance: boolean,
              public trackFuel: boolean,
              public gpsTrackDistance: boolean,
              public stopTime: boolean,
              public satellite: boolean,
              public gsmSignalLevel: boolean) {
  }

  public static defaultValues(): TrackPopupConfigsForm {
    return new TrackPopupConfigsForm(
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true
    )
  }
}
