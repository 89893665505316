<app-navbar></app-navbar>
<div class="app__container">
  <main *ngIf="!restrictionsSuspend"
        class="main"
        [ngClass]="{'main--system-warnings': restrictionsWarning
                   || systemWarningMessage && !systemWarningMessage.userpilotEventId
                   || showWarning}"
        id="main-wrapper">
    <ng-container *ngIf="restrictionsWarning
                         || systemWarningMessage && !systemWarningMessage.userpilotEventId
                         || showWarning">
      <div class="system-warning">
        <ng-container *ngIf="restrictionsWarning">
           <div class="system-warning--restriction">
            <span><i class="ico-attention-triangle sz-s"></i>
            {{'navbar.restrictions-warning' | translate}}</span>
          </div>
        </ng-container>

        <ng-container *ngIf="!isMobile">
          <ng-container *ngIf="systemWarningMessage && !systemWarningMessage.userpilotEventId">
            <div class="system-warning--warning">
              <span><i class="ico-attention-triangle sz-s"></i>
              {{getSystemWarningByLang()}}</span>
            </div>
          </ng-container>

          <ng-container *ngIf="showWarning">
            <div class="system-warning--alert">
              <span><i class="ico-attention-triangle sz-s"></i>
              {{'navbar.restrictions-chrome' | translate}}</span>
              <button type="button"
                      class="system-warning__close"
                      aria-label="Close"
                      (click)="closeWarning()">
                <i class="ico-close sz-xs"></i>
              </button>
            </div>
          </ng-container>
        </ng-container>
      </div>
  </ng-container>
    <router-outlet></router-outlet>
  </main>

  <main *ngIf="restrictionsSuspend" class="main main--suspend">
    <div class="suspend-message">
      <i class="ico-attention-triangle sz-xl"></i>
      {{'navbar.restrictions-suspended' | translate}}
    </div>
  </main>

  <aside class="main__chat">
    <app-chat-window></app-chat-window>
  </aside>
</div>
