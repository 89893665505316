import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SlimScroll} from './slim-scroll/slim-scroll.directive';
import {Widget} from './widget/widget.directive';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    SlimScroll,
    Widget
  ],
  exports: [
    SlimScroll,
    Widget
  ]
})
export class DirectivesModule {
}
