import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Injectable()
export class BugService {

  constructor(private http: HttpClient) {
  }

  public send(formData: FormData, token: string, currentLang: string): Observable<void> {
    let headers = new HttpHeaders().set('reCaptcha-token', token);
    return this.http.post<void>(`/api/bug/send?locale=${currentLang}`, formData, {headers: headers});
  }

}
