import {Component, Inject} from '@angular/core';
import {MAT_LEGACY_SNACK_BAR_DATA as MAT_SNACK_BAR_DATA, MatLegacySnackBarRef as MatSnackBarRef} from '@angular/material/legacy-snack-bar';

export interface PopupData {
  message: string;
  type: PopupType;
}

export enum PopupType {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  WARN = 'WARN',
  INFO = 'INFO'
}

@Component({
  selector: 'app-notification-popup',
  templateUrl: './notification-popup.component.html',
  styleUrls: ['./notification-popup.component.scss']
})
export class NotificationPopupComponent {

  constructor(public sbRef: MatSnackBarRef<NotificationPopupComponent>,
              @Inject(MAT_SNACK_BAR_DATA) public data: PopupData) {
  }

}
