<div class="notification-menu__lang dropdown no-print">
  <a href class="dropdown-toggle"
     title="{{'navbar.language' | translate}}"
     data-toggle="dropdown"
     aria-expanded="false"
     (click)="showLanguages()">
    <i class="flag-icon flag-icon flag-icon-{{languageIcon}}"></i>
  </a>

  <nav class="dropdown-menu notification-menu__lang-list">
    <a *ngFor="let lang of supportedLanguages"
       class="cursor-pointer"
       (click)="selectLang(lang)">
      <i class="flag-icon flag-icon flag-icon-{{lang.flag}}"></i>
    </a>
  </nav>
</div>
