import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {IdValue} from '../model/id-value';
import {HttpClient, HttpParams} from '@angular/common/http';
import {HttpUtils} from '../page/commons/http.utils';

@Injectable()
export class GlobalVehiclesService {

  constructor(private http: HttpClient) {
  }

  public vehicles(): Observable<IdValue<string>[]> {
    return this.http.get<IdValue<string>[]>('/api/global/vehicles');
  }

  public getVehiclesForSubTasks(vehicleType: string,
                                objectType: string,
                                objectId: number,
                                vehicleGroupId: number): Observable<IdValue<string>[]> {

    let params = new HttpParams();
    params = HttpUtils.appendParam(params, 'vehicleType', vehicleType != null ? vehicleType : '');
    params = HttpUtils.appendParam(params, 'objectType', objectType);
    params = HttpUtils.appendParam(params, 'objectId', objectId);
    params = HttpUtils.appendParam(params, 'group', vehicleGroupId != null ? vehicleGroupId : 0);
    return this.http.get<IdValue<string>[]>(`/api/global/sub-tasks-vehicles`, {params: params});
  }
}
