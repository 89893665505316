import {MessageEntry} from './message.entry';
import {GlobalConstants} from '../../global/global.constants';

export class ChatUtils {

  public static PICTURE_EXTENSION = ['.png', '.jpg', '.gif', '.jpeg'];

  public static responsiveTextarea(textInput: string, list: string, button: string, file: string, template: string): void {
    let textarea = document.getElementById(textInput);
    textarea.addEventListener('change', () => this.resize(list, textInput, button, file, template), false);
    textarea.addEventListener('cut', () => this.delayedResize(list, textInput, button, file, template), false);
    textarea.addEventListener('paste', () => this.delayedResize(list, textInput, button, file, template), false);
    textarea.addEventListener('drop', () => this.delayedResize(list, textInput, button, file, template), false);
    textarea.addEventListener('keydown', () => this.delayedResize(list, textInput, button, file, template), false);
  }

  private static delayedResize(list: string, textInput: string, button: string, file: string, template: string): void {
    window.setTimeout(() => this.resize(list, textInput, button, file, template), 0);
  }

  public static resize(list: string, textInput: string, button: string, file: string, template: string): void {
    let textarea = document.getElementById(textInput);
    let sendButton = document.getElementById(button);
    if (textarea.scrollHeight < 400) {
      textarea.style.height = 'auto';
      textarea.style.height = textarea.scrollHeight + 2 + 'px';
      sendButton.style.height = textarea.scrollHeight + 2 + 'px';
      this.resizeMediaList(textarea.scrollHeight + 25, list, file, template);
      this.scrollDown(list);
    }
  }

  private static resizeMediaList(textareaHeight: number, list: string, file: string, template: string): void {
    let mediaList = document.getElementById(list);
    let chatHeaderHeight = document.getElementsByClassName('chat-header').item(0).scrollHeight;
    let uploadedFile = document.getElementById(file);
    let messagesTemplate = document.getElementById(template);
    let uploadedFileHeight = uploadedFile != null ? uploadedFile.scrollHeight : 0;
    let messagesTemplateHeight = messagesTemplate != null ? messagesTemplate.scrollHeight : 0;
    setTimeout(() => {
      mediaList.style.height =
        window.innerHeight
        - textareaHeight
        - chatHeaderHeight
        - uploadedFileHeight
        - messagesTemplateHeight
        - 75
        + 'px';
    }, 0);
  }

  public static scrollDown(list: string): void {
    setTimeout(() => {
      let element = document.getElementById(list);
      element.scrollTop = element.scrollHeight;
    }, 300);
  }

  public static silentInterval(till: string, messages: MessageEntry[]): boolean {
    return messages.length - 1 > 0
      ? moment(till).diff(moment(messages[(messages.length - 1)].sentOn), 'minutes') > 15
      : true;
  }

  public static addKeyboardHandling(container: string, list: string, text: string): void {
    if (window.innerWidth < GlobalConstants.MOBILE_WIDTH_BREAKPOINT) {
      jQuery(document.body).on('focus', 'textarea, input', () => {
        setTimeout(() => {
          if (window.matchMedia('(display-mode: fullscreen)').matches) {
            document.getElementById(container).style.height = '52%';
          }
          document.getElementById(list).style.height = '63%';
          document.getElementById(text).style.height = '14%';
          ChatUtils.scrollDown('message-m-list');
        }, 500);
      }).on('blur', 'textarea, input', () => {
        document.getElementById(container).style.height = '100%';
        document.getElementById(list).style.height = '87%';
        document.getElementById(text).style.height = '13%';
      });
    }
  }

}
