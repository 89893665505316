<section class="p404__container">
  <article class="p404">
    <header>
      <h1>{{'not-found.title' | translate}}</h1>
    </header>
    <div>
      <p>{{'not-found.msg' | translate}}</p>
      <p><a (click)="goHome()" href="javascript:void(0);">{{'not-found.go-home' | translate}}</a></p>
    </div>
  </article>
</section>
