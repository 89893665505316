import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {SubscriptionTypesEntry} from './subscription-types.entry';

@Injectable()
export class SubscribeService {

  public constructor(private http: HttpClient) {
  }

  public addPushSubscriber(token: any): Observable<any> {
    return this.http.post<any>(`/api/subscribe?token=${token}`, {});
  }

  public removeSubscription(token: string): Observable<any> {
    return this.http.post<any>(`/api/subscribe/remove?token=${token}`, {});
  }

  public isSubscribed(token: string): Observable<boolean> {
    return this.http.get<boolean>(`/api/subscribe/is-subscribed?token=${token}`);
  }

  public getSubscriptionTypes(token: string): Observable<SubscriptionTypesEntry> {
    return this.http.get<SubscriptionTypesEntry>(`/api/subscribe/subscriptions?token=${token}`);
  }

  public updateSubscriptionTypes(data: any, token: string) {
    return this.http.put(`/api/subscribe/subscriptions?token=${token}`, data);
  }

}
