import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {PlacesEntry} from './model/places.entry';
import {PlaceSource} from './model/place-source';
import {HttpClient, HttpParams} from '@angular/common/http';
import {HttpUtils} from '../../page/commons/http.utils';
import {RoutePointData} from '../ol-route/ol-route.component';

@Injectable()
export class PlacesService {

  private markersSource = new Subject<any>();
  private markers$ = this.markersSource.asObservable();

  private olMarkersSource = new Subject<any>();
  private olMarkers$ = this.olMarkersSource.asObservable();

  constructor(private http: HttpClient) {
  }

  public getPlaces(query: string, sources: PlaceSource[]): Observable<PlacesEntry[]> {
    let params = new HttpParams();
    params = HttpUtils.appendParam(params, 'query', query);
    params = HttpUtils.appendParam(params, 'sources', `${sources}`);
    return this.http.get<PlacesEntry[]>('/api/map/places', {params: params, headers: {skip: 'true'}})
  }

  public getPlacesNearLocation(query: string, sources: PlaceSource[], location: string): Observable<PlacesEntry[]> {
    let params = new HttpParams();
    params = HttpUtils.appendParam(params, 'query', query);
    params = HttpUtils.appendParam(params, 'sources', `${sources}`);
    params = HttpUtils.appendParam(params, 'location', `${location}`);
    return this.http.get<PlacesEntry[]>('/api/map/places/location', {params: params})
  }

  public placeDetails(placeId: string): Observable<PlacesEntry> {
    let params = {'placeId': placeId};
    return this.http.get<PlacesEntry>('/api/map/places/details', {params: params});
  }

  public putMarker(marker: any): void {
    this.markersSource.next(marker);
  }

  public getMarker(): Observable<any> {
    return this.markers$;
  }

  public putOlMarker(marker: RoutePointData): void {
    this.olMarkersSource.next(marker);
  }

  public getOlMarker(): Observable<RoutePointData> {
    return this.olMarkers$
  }

  public detailedPlace(place: PlacesEntry) {
    let params = new HttpParams();
    params = HttpUtils.appendParam(params, 'placeId', place.placeId);
    params = HttpUtils.appendParam(params, 'name', place.name);
    params = HttpUtils.appendParam(params, 'lat', place.lat);
    params = HttpUtils.appendParam(params, 'lng', place.lng);
    return this.http.get<PlacesEntry>('/api/map/places/detailed', {params: params});
  }
}
