import {Component, EventEmitter, OnDestroy, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {ChatService} from '../chat.service';
import {NotificationService} from '../../../notification/notification.service';
import {ChatVehicleListEntry} from '../chat-vehicle-list.entry';
import {Subscription} from 'rxjs';
import {DisplayInfoEntry} from '../model/display-info.entry';
import {IdValue} from '../../../model/id-value';
import {ChatWsStateService} from '../../../ws/chat-ws-state.service';

@Component({
  selector: 'app-chat-list',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './chat-list.component.html',
  styleUrls: ['./chat-list.component.scss']
})
export class ChatListComponent implements OnInit, OnDestroy {

  @Output()
  public openNewChat: EventEmitter<ChatVehicleListEntry> = new EventEmitter();

  @Output()
  public openDocs: EventEmitter<ChatVehicleListEntry> = new EventEmitter();

  public vehicles: ChatVehicleListEntry[];
  public filteredVehicles: ChatVehicleListEntry[];

  public displayInfo: Map<number, DisplayInfoEntry> = new Map<number, DisplayInfoEntry>();

  private displayInfoSubs: Subscription;

  constructor(private chatService: ChatService,
              private chatWsStateService: ChatWsStateService,
              private notification: NotificationService) {

  }

  public ngOnInit() {
    this.displayInfoSubs = this.chatWsStateService.displayInfo$.subscribe(
      (displayInfos) => {
        this.clearAndAdd(displayInfos);
        if (this.filteredVehicles != null) {
          this.sortVehicles();
        }
      }
    );
    this.loadList();
  }

  private clearAndAdd(infos: Map<number, DisplayInfoEntry>) {
    this.displayInfo.clear();
    for (const vehicleId of Object.keys(infos)) {
      const displayInfoEntry = infos[vehicleId];
      this.displayInfo.set(
        +vehicleId,
        displayInfoEntry
      );
    }
  }

  public openChat(vehicle: ChatVehicleListEntry): void {
    this.openNewChat.emit(vehicle);
  }

  public openScannedDocs(vehicle: ChatVehicleListEntry): void {
    this.openDocs.emit(vehicle);
  }

  public filterVehicles($event: any): void {
    if ($event != null) {
      this.filteredVehicles = this.vehicles
        .filter(v => v.plateNumber.toLowerCase().indexOf($event.toLowerCase()) !== -1
          || v.driverName.toLowerCase().indexOf($event.toLowerCase()) !== -1);
    }
    if (this.filteredVehicles != null) {
      this.sortVehicles();
    }
  }

  private loadList() {
    this.chatService.getChatVehicleList()
      .subscribe(
        (data) => {
          this.vehicles = data;
          this.filteredVehicles = data;
          if (this.filteredVehicles.length > 0) {
            this.sortVehicles();
          }
        },
        (err) => this.notification.loadingError()
      );
  }

  private sortVehicles() {
    let vehiclesWithMessages = this.filteredVehicles
      .filter(v => this.displayInfo.get(v.id) != null);
    let emptyVehicles =
      this.filteredVehicles.filter(v => this.displayInfo.get(v.id) == null);
    if (vehiclesWithMessages.length > 1) {
      this.filteredVehicles = this.sortVehiclesWithMessages(vehiclesWithMessages);
    } else {
      this.filteredVehicles = vehiclesWithMessages;
    }
    if (emptyVehicles.length > 1) {
      let items = this.sortEmptyVehicles(emptyVehicles);
      this.filteredVehicles = this.filteredVehicles.concat(items);
    } else {
      this.filteredVehicles = this.filteredVehicles.concat(emptyVehicles);
    }

  }

  private sortEmptyVehicles(vehicles: ChatVehicleListEntry[]): ChatVehicleListEntry[] {
    return vehicles.sort((v1, v2) => {
      if (v1.id > v2.id) {
        return -1;
      } else if (v1.id < v2.id) {
        return 1;
      }
      return 0;
    });
  }

  private sortVehiclesWithMessages(vehicles: ChatVehicleListEntry[]): ChatVehicleListEntry[] {
    let idSorter = (v1, v2) => {
      let message1 = this.displayInfo.get(v1.id).lastMessageId;
      let message2 = this.displayInfo.get(v2.id).lastMessageId;
      if (message1 > message2) {
        return -1;
      } else if (message1 < message2) {
        return 1;
      }
      return 0;
    };
    let high = vehicles
      .filter(it => this.displayInfo.get(it.id).newMessagesCount > 0)
      .sort(idSorter);
    let low = vehicles
      .filter(it => this.displayInfo.get(it.id).newMessagesCount === 0)
      .sort(idSorter);
    return high.concat(low);
  }

  public ngOnDestroy() {
    this.displayInfoSubs.unsubscribe();
  }

}
