import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {TachoTableConfigsForm} from './form/tacho-table-configs.form';

@Injectable()
export class TachoTableConfigsService {

  constructor(private http: HttpClient) {
  }

  public fetch(): Observable<TachoTableConfigsForm> {
    return this.http.get<TachoTableConfigsForm>('/api/configs/tacho');
  }

  public update(form: TachoTableConfigsForm): Observable<any> {
    return this.http.put('/api/configs/tacho', form);
  }
}
