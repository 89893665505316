import {AuthStorage} from '../core/auth/auth-storage';
import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {isClient} from '../core/model/profile';
import {AuthConfig} from '../core/auth/auth-config';
import {GlobalService} from '../core/global/global.service';
import {NotificationService} from '../core/notification/notification.service';

@Component({
  selector: 'app-index',
  template: ''
})
export class IndexComponent implements OnInit {

  constructor(private router: Router,
              private globalService: GlobalService,
              private notification: NotificationService) {
  }

  ngOnInit() {
    this.globalService.getLoggedProfile()
      .subscribe({
        next: (loggedUser) => {
          AuthStorage.updateUserSettings(loggedUser, false);
          isClient(AuthStorage.getUserRole().toString())
            ? this.router.navigate([AuthConfig.CLIENT_MAIN_PAGE])
            : this.router.navigate([AuthConfig.PARTNER_DEFAULT_PAGE]);
        },
        error: () => this.notification.loadingError()
      });
  }
}
