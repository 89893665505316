import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable()
export class ReportOrderingStateService {

  private loadOrderedFilesSource = new Subject<void>();
  public loadOrderedFiles$ = this.loadOrderedFilesSource.asObservable();

  constructor() {
  }

  public triggerLoadOrderedFiles(): void {
    this.loadOrderedFilesSource.next();
  }

}
