import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {TasksConfigsForm} from './model/tasks-configs.form';
import {TasksConfigsResponse} from './model/tasks-configs.response';
import {ClientTasksConfigsForm, ClientTasksConfigsFormInt} from './model/client-tasks-configs.form';
import {FormGroup} from '@angular/forms';

@Injectable()
export class TasksConfigsService {

  constructor(private http: HttpClient) {
  }

  public fetch(): Observable<TasksConfigsResponse> {
    return this.http.get<TasksConfigsResponse>('/api/configs/tasks');
  }

  public update(form: TasksConfigsForm) {
    return this.http.put('/api/configs/tasks', form);
  }

  public updateClientConfigs(form: FormGroup<ClientTasksConfigsFormInt>): Observable<void> {
    return this.http.put<void>('/api/configs/tasks-client', form.getRawValue());
  }
}
