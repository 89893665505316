import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ImportantInfoForm} from './model/important-info.form';

@Injectable()
export class ImportantInfoService {

  constructor(private http: HttpClient) {
  }

  public save(form: ImportantInfoForm): Observable<any> {
    let headers = new HttpHeaders();
    return this.http.post(`/api/info`, form, {headers: headers});
  }

  public fetchImportantInfo(groupId: number): Observable<ImportantInfoForm> {
    return this.http.get<ImportantInfoForm>(`/api/info?groupId=${groupId}`);
  }
}
