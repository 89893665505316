import {Directive} from '@angular/core';
import {ControlValueAccessor, NgControl} from '@angular/forms';

@Directive({
  selector: '[appTrimInputValue]'
})
export class TrimInputValueDirective {

  constructor(private ngControl: NgControl) {
    this.trimValue(ngControl.valueAccessor)
  }

  private trimValue(valueAccessor: ControlValueAccessor) {
    const original = valueAccessor.registerOnChange;
    valueAccessor.registerOnChange = (fn: (_: unknown) => void) => {
      return original.call(valueAccessor, (value: unknown) => {
        return fn(typeof value === 'string' ? value.trim() : value);
      });
    };
  }

}
