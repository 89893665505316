import {Injectable} from '@angular/core';
import {registerLocaleData} from '@angular/common';
import ltLocale from '@angular/common/locales/lt';
import enLocale from '@angular/common/locales/en';
import ruLocale from '@angular/common/locales/ru';
import deLocale from '@angular/common/locales/de';
import plLocale from '@angular/common/locales/pl';
import frLocale from '@angular/common/locales/fr';
import lvLocale from '@angular/common/locales/lv';
import czLocale from '@angular/common/locales/cs';
import esLocale from '@angular/common/locales/es';
import roLocale from '@angular/common/locales/ro';
import itLocale from '@angular/common/locales/it';
import {DAYS_OF_WEEK} from 'angular-calendar';

let moment = require('moment-timezone');

@Injectable()
export class LocaleService {

  constructor() {
  }

  public loadLocale(lang: string): void {
    switch (lang) {
      case 'lt':
        registerLocaleData(ltLocale);
        this.updateMomentLocale('lt')
        break;
      case 'ru':
        registerLocaleData(ruLocale);
        this.updateMomentLocale('ru')
        break;
      case 'de':
        registerLocaleData(deLocale);
        this.updateMomentLocale('de')
        break;
      case 'pl':
        registerLocaleData(plLocale);
        this.updateMomentLocale('pl')
        break;
      case 'fr':
        registerLocaleData(frLocale);
        this.updateMomentLocale('fr')
        break;
      case 'lv':
        registerLocaleData(lvLocale);
        this.updateMomentLocale('lv')
        break;
      case 'cz':
        registerLocaleData(czLocale);
        this.updateMomentLocale('cz')
        break;
      case 'es':
        registerLocaleData(esLocale);
        this.updateMomentLocale('es')
        break;
      case 'ro':
        registerLocaleData(roLocale);
        this.updateMomentLocale('ro')
        break;
      case 'it':
        registerLocaleData(itLocale);
        this.updateMomentLocale('it')
        break;
      case 'en':
      default:
        registerLocaleData(enLocale);
        this.updateMomentLocale('en')
        break;
    }
  }

  public setDefaultTimezone(timezone: string): void {
    if (timezone != null) {
      moment.tz.setDefault(timezone);
    }
  }

  private updateMomentLocale(lang: string): void {
    moment.updateLocale(lang, {
      week: {
        dow: DAYS_OF_WEEK.MONDAY,
        doy: 0
      }
    })
  }

}
