import {VehiclePosition} from '../vehicle-position';
import {MapSettings} from '../../../model/map.constants';
import {VehicleNoteStatusEntry} from './vehicle-note-status.entry';
import {DueService} from './due-service';
import {VehicleWarnings} from '../vehicle-warnings';

export class ConnectedTrailerEntry {

  public id: number;
  public deviceKey: string;
  public plateNumber: string;
  public status: string;
  public hasGPSDevice: boolean;
  public speed: number;

  public setFreezerTemperature: number;
  public actualFreezerTemperature: number;
  public supplyFreezerTemperature: number;
  public freezerEngineOn: boolean;
  public freezerMode: string;

  public secondCompartmentSetPoint: number;
  public secondCompartmentTemperature: number;
  public secondCompartmentSupplyTemperature: number;
  public secondCompartmentIsOn: boolean;
  public secondCompartmentFreezerMode: string;

  public firstSensorTemperature: number;
  public secondSensorTemperature: number;
  public thirdSensorTemperature: number;
  public fourthSensorTemperature: number;

  public freezerPowerMode: string;
  public ambientTemperature: number;
  public freezerRunMode: string;
  public freezerDieselHours: number;
  public freezerElectricHours: number;
  public freezerStandbyHours: number;
  public position: VehiclePosition;
  public direction: number;
  public externalPowerV: number;
  public batteryLevel: number;
  public serviceInRed: boolean;

  public noteStatuses: VehicleNoteStatusEntry[];
  public dueServices: DueService[];
  public warnings: VehicleWarnings;

  constructor(item: any) {
    this.id = item.id;
    this.deviceKey = item.deviceKey;
    this.plateNumber = item.plateNumber;
    this.status = item.status;
    this.hasGPSDevice = item.hasGPSDevice;
    this.speed = item.speed;

    this.setFreezerTemperature = item.setFreezerTemperature;
    this.actualFreezerTemperature = item.actualFreezerTemperature;
    this.supplyFreezerTemperature = item.supplyFreezerTemperature;
    this.freezerEngineOn = item.freezerEngineOn;
    this.freezerMode = item.freezerMode;

    this.secondCompartmentSetPoint = item.secondCompartmentSetPoint;
    this.secondCompartmentTemperature = item.secondCompartmentTemperature;
    this.secondCompartmentSupplyTemperature = item.secondCompartmentSupplyTemperature;
    this.secondCompartmentIsOn = item.secondCompartmentIsOn;
    this.secondCompartmentFreezerMode = item.secondCompartmentFreezerMode;

    this.firstSensorTemperature = item.firstSensorTemperature;
    this.secondSensorTemperature = item.secondSensorTemperature;
    this.thirdSensorTemperature = item.thirdSensorTemperature;
    this.fourthSensorTemperature = item.fourthSensorTemperature;

    this.freezerPowerMode = item.freezerPowerMode;
    this.freezerRunMode = item.freezerRunMode;
    this.ambientTemperature = item.ambientTemperature;
    this.freezerDieselHours = item.freezerDieselHours;
    this.freezerElectricHours = item.freezerElectricHours;
    this.freezerStandbyHours = item.freezerStandbyHours;
    this.position = item.position
      ? new VehiclePosition(Number(item.position.latitude), Number(item.position.longitude))
      : null;
    this.direction = item.direction;
    this.externalPowerV = item.externalPowerV;
    this.batteryLevel = item.batteryLevel;
    this.serviceInRed = item.serviceInRed;

    this.noteStatuses = item.noteStatuses;
    this.dueServices = item.dueServices;
    this.warnings = item.warnings;
  }

  public getIcon() {
    return MapSettings.ICONS['truck'][this.status] + '#rotate_' + this.direction;
  }

  public hasPosition(): boolean {
    return this.position != null;
  }

}
