import {Component, ViewEncapsulation} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {CookieOptions, CookieService} from 'ngx-cookie';
import {AppState} from '../../../app.state';
import {LocaleService} from '../../global/locale.service';
import {AuthStorage} from '../../auth/auth-storage';
import {Userpilot} from 'userpilot';
import {environment} from '../../../../environments/environment';
import {FM_LANG} from '../../global/cookies.constants';

@Component({
  selector: 'app-language-select',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./language-select.component.scss'],
  templateUrl: './language-select.component.html'
})
export class LanguageSelectComponent {

  public supportedLanguages: any[] = [
    {display: 'English', value: 'en', flag: 'gb'},
    {display: 'Lietuvių', value: 'lt', flag: 'lt'},
    {display: 'Deutsche', value: 'de', flag: 'de'},
    {display: 'Pусский', value: 'ru', flag: 'ru'},
    {display: 'Polski', value: 'pl', flag: 'pl'},
    {display: 'Français', value: 'fr', flag: 'fr'},
    {display: 'Latviski', value: 'lv', flag: 'lv'},
    {display: 'Italiano', value: 'it', flag: 'it'},
    {display: 'Český', value: 'cz', flag: 'cz'},
    {display: 'Română', value: 'ro', flag: 'ro'},
    {display: 'Español', value: 'es', flag: 'es'}
  ];
  public languageIcon: string;
  public isMenuCollapsed = false;

  constructor(private _state: AppState,
              private _translate: TranslateService,
              private cookieService: CookieService,
              private localeService: LocaleService) {

    this.languageIcon = this.getFlag();
    this._state.subscribe('menu.isCollapsed', (isCollapsed) => {
      this.isMenuCollapsed = isCollapsed;
    });
  }

  private getFlag(): string {
    return this.supportedLanguages
      .find(lang => lang.value === this._translate.currentLang).flag;
  }

  public selectLang(lang: any) {
    this._translate.use(lang.value);
    this.localeService.loadLocale(lang.value);
    let exp = new Date(2040, 12, 21);
    let cookieOptions = {expires: exp} as CookieOptions;
    this.cookieService.put(FM_LANG, lang.value, cookieOptions);
    this._state.notifyDataChanged('lang.changed', lang.value);
    this.languageIcon = lang.flag;
    Userpilot.identify(AuthStorage.getLoggedId().toString(), {
      locale_code: lang.value
    });
  }

  public showLanguages() {
    this.isMenuCollapsed = !this.isMenuCollapsed;
    this._state.notifyDataChanged('menu.isCollapsed', this.isMenuCollapsed);
  }
}
