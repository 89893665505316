import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, ErrorHandler, NgModule} from '@angular/core';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {SharedModule} from './core/shared.module';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {IsLoggedIn} from './core/auth/is-logged-in';
import {PagesModule} from './pages/pages.module';
import {CookieModule} from 'ngx-cookie';
import {ChatService} from './core/component/chat/chat.service';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {JwtInterceptor, JwtModule} from '@auth0/angular-jwt';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {AuthService} from './auth/auth.service';
import {AuthStorage} from './core/auth/auth-storage';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from '../environments/environment';
import {SubscribeService} from './core/notification/subscribe.service';
import {RefreshTokenInterceptor} from './auth/refrest-token-interceptor';
import {UpdateService} from './update.service';
import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';
import {Overlay} from '@angular/cdk/overlay';
import {AngularFireMessagingModule} from '@angular/fire/compat/messaging';
import {AngularFireModule} from '@angular/fire/compat';
import {MultiWindowModule} from 'ngx-multi-window';
import {DatePipe} from '@angular/common';
import {VersionCheckService} from './version-check.service';
import {ChatWsStateService} from './core/ws/chat-ws-state.service';
import {LoaderComponent} from './theme/components/loader/loader.component';
import {LoaderService} from './theme/components/loader/loader.service';
import {LoaderInterceptor} from './theme/components/loader/loader-interceptor';
import {
  CalendarDateFormatter,
  CalendarModule,
  CalendarMomentDateFormatter,
  DateAdapter,
  MOMENT
} from 'angular-calendar';
import {LocaleService} from './core/global/locale.service';
import {adapterFactory} from 'angular-calendar/date-adapters/moment';
import {Userpilot} from 'userpilot';
import * as Sentry from "@sentry/angular";
import {Router} from "@angular/router";

Userpilot.initialize('NX-6e217e3c');

let moment = require('moment-timezone');

export function httpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export function momentAdapterFactory() {
  return adapterFactory(moment);
}

export function tokenGetter() {
  return AuthStorage.accessToken();
}

if (environment.sentry) {
  Sentry.init({
    dsn: "https://e886d52587b88d2182a8126928a7d588@o4506779509653504.ingest.us.sentry.io/4508137552674816",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: ["localhost", environment.url],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

@NgModule({
  declarations: [
    AppComponent,
    LoaderComponent
  ],
  imports: [
    BrowserModule,
    SharedModule,
    NgbModule,
    CookieModule.forRoot(),
    HttpClientModule,
    AppRoutingModule,
    PagesModule,
    BrowserAnimationsModule,
    MultiWindowModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    JwtModule.forRoot({
        config: {
          tokenGetter: tokenGetter,
          allowedDomains: ['localhost'],
          disallowedRoutes: ['/oauth/token', '/i18n', '/password-remind']
        }
      }
    ),
    CalendarModule.forRoot(
      {
        provide: DateAdapter,
        useFactory: momentAdapterFactory
      },
      {
        dateFormatter: {
          provide: CalendarDateFormatter,
          useClass: CalendarMomentDateFormatter
        }
      }
    ),
    ServiceWorkerModule.register('global-sw.js', {enabled: environment.sw}),
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebase)
  ],
  providers: [
    IsLoggedIn,
    ChatService,
    SubscribeService,
    AuthService,
    JwtInterceptor,
    {
      provide: HTTP_INTERCEPTORS,
      useExisting: JwtInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RefreshTokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true
    },
    {
      provide: MOMENT,
      useValue: moment
    },
    UpdateService,
    MatSnackBar,
    Overlay,
    DatePipe,
    VersionCheckService,
    ChatWsStateService,
    LoaderService,
    LocaleService,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

}
