import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ChatVehicleListEntry} from './chat-vehicle-list.entry';
import {ChatService} from './chat.service';
import {NotificationService} from '../../notification/notification.service';
import {Subject, Subscription} from 'rxjs';
import {ScanListComponent} from './scan-list/scan-list.component';
import {GroupService} from '../group/group.service';
import {GroupEntry} from '../../model/group.entry';
import {TranslateService} from '@ngx-translate/core';
import {AuthStorage} from '../../auth/auth-storage';
import {environment} from '../../../../environments/environment';
import {takeUntil} from 'rxjs/operators';
import {SubscriptionUtils} from '../../commons/subscription.utils';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'app-chat-window',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit, OnDestroy {

  @ViewChild(ScanListComponent, {static: true}) scanList;

  public fileUri: string;

  public chatState = 'out';
  private openChatSubscription: Subscription;
  private openGroupChatSubscription: Subscription;
  private closeChatSubscription: Subscription;
  private messageCountSub: Subscription;
  private subSubject = new Subject<boolean>();

  public newChatInfo: ChatVehicleListEntry = new ChatVehicleListEntry(0, '', '', false);

  public isListActive = false;
  public isGroupActive = false;
  public isMessagesActive = false;
  public isScanListActive = false;
  public isGroupGenerated = false;

  public generatedTitle: string;

  public userGroup = AuthStorage.getUserGroup();
  public vehicleGroups: GroupEntry[] = [];
  public selectedGroup: GroupEntry;

  private blinkInterval: any = null;

  public isLoggedClient = AuthStorage.isLoggedClientEmployee();
  public canSendToAllVehicles = false;

  private environmentName: string = environment.name;

  constructor(private groupService: GroupService,
              private http: HttpClient,
              private chatService: ChatService,
              private notification: NotificationService,
              private translate: TranslateService) {
  }

  public ngOnInit() {
    this.http.get('/api/chat/file-uri', {responseType: 'text', headers: {skip: 'true'}})
      .pipe(takeUntil(this.subSubject))
      .subscribe((res) => this.fileUri = res);
    this.openChatSubscription = this.chatService.openChat$.subscribe({
      next: (data) => this.openChatForVehicle(data),
      error: (err) => this.notification.loadingError()
    });
    this.closeChatSubscription = this.chatService.closeChat$.subscribe(
      (data) => this.closeChat()
    );
    this.openGroupChatSubscription = this.chatService.openGroupChat$.subscribe({
      next: (data) => this.openGroupChat(data),
      error: (err) => this.notification.loadingError()
    });
    this.chatService.toggleChat().subscribe(
      (state) => {
        this.chatState = state ? 'out' : 'in';
        this.toggleChat();
      }
    )
    this.setupVehicleGroups();
  }

  private setupVehicleGroups() {
    let userRole = AuthStorage.getUserRole().toString();
    let roles: string[] = [
      'ROLE_CLIENT_SENIOR_MANAGER',
      'ROLE_CLIENT_EMPLOYEE_ADMIN'
    ];
    if (this.environmentName === 'HGPROD' && roles.indexOf(userRole) < 0) {
      if (this.userGroup
        && this.userGroup.vehicles
        && this.userGroup.vehicles.length > 0
        && this.userGroup.vehicles.length <= 200) {

        this.vehicleGroups = [new GroupEntry(
          this.userGroup.id,
          this.translate.instant('global.vehicle-group.user'),
          this.userGroup.vehicles)];
      }
    } else {
      this.canSendToAllVehicles = true;
      this.groupService.groups()
        .pipe(takeUntil(this.subSubject))
        .subscribe(
          groups => {
            this.vehicleGroups = groups;
            if (this.userGroup && this.userGroup.vehicles && this.userGroup.vehicles.length > 0) {
              this.vehicleGroups.unshift(new GroupEntry(
                this.userGroup.id,
                this.translate.instant('global.vehicle-group.user'),
                this.userGroup.vehicles));
            }
            this.vehicleGroups = this.vehicleGroups.filter(group => group.title !== 'client');
          },
          () => this.notification.loadingError()
        );
    }
  }

  public openChatForEvent($event: ChatVehicleListEntry): void {
    this.resetNewChatInfo();
    this.newChatInfo = $event;
    this.chatService.closeMessages();
    this.activateMessages();
  }

  public openDocsForEvent($event: ChatVehicleListEntry): void {
    this.resetNewChatInfo();
    this.newChatInfo = $event;
    this.chatService.closeMessages();
    this.activateScanList();
  }

  public activateList(): void {
    this.isMessagesActive = false;
    this.isGroupActive = false;
    this.isScanListActive = false;
    this.isListActive = true;
    this.closeGroupMessages();
  }

  public activateGroup(groupId: number): void {
    this.isGroupActive = true;
    this.isMessagesActive = false;
    this.isListActive = false;
    this.isScanListActive = false;
    this.selectedGroup = this.vehicleGroups.find(it => it.id === +groupId);
  }

  public activateMessages(): void {
    this.isMessagesActive = true;
    this.isListActive = false;
    this.isScanListActive = false;
    this.closeGroupMessages();
  }

  public activateScanList(): void {
    this.isMessagesActive = false;
    this.isListActive = false;
    this.closeGroupMessages();
    this.isScanListActive = true;
    this.chatService.triggerOpenScanList(this.newChatInfo);
  }

  public toggleChat() {
    this.chatState === 'out' ? this.openChat() : this.closeChat();
  }

  public closeMessages(): void {
    this.resetNewChatInfo();
    this.chatService.closeMessages();
    this.activateList();
  }

  public closeGroupMessages(): void {
    this.isGroupActive = false;
    this.selectedGroup = null;
    jQuery('#group').prop('selectedIndex', 0);
  }

  public closeChat(): void {
    this.chatState = 'out';
    this.resetNewChatInfo();
    this.closeGroupMessages();
    this.chatService.closeMessages();
    this.chatService.changeState(this.chatState);
    this.vehicleGroups = this.vehicleGroups.filter(group => {
      return group.id !== -1
    })
    this.isGroupGenerated = false;
    this.addGlobalClass();
  }

  private openChatForVehicle(vehicle: ChatVehicleListEntry): void {
    if (this.newChatInfo.id !== vehicle.id) {
      this.resetNewChatInfo();
      this.chatService.closeMessages();
      this.newChatInfo = vehicle;
      this.activateMessages();
      if (this.chatState === 'out') {
        this.chatState = 'in';
        this.chatService.changeState(this.chatState);
      }
    } else {
      this.chatService.closeMessages();
      this.resetNewChatInfo();
      this.chatState = 'out';
      this.chatService.changeState(this.chatState);
    }
    this.addGlobalClass();
  }

  private openGroupChat(vehiclesList: GroupEntry): void {
    this.vehicleGroups.push(vehiclesList)
    this.generatedTitle = vehiclesList.title;
    this.isGroupGenerated = true;
    this.chatState = 'in';
    this.chatService.changeState(this.chatState);
    this.activateGroup(vehiclesList.id)
    this.addGlobalClass();
  }

  private resetNewChatInfo(): void {
    this.newChatInfo = new ChatVehicleListEntry(0, '', '', false);
  }

  private openChat(): void {
    this.chatState = 'in';
    this.activateList();
    this.chatService.changeState(this.chatState);
    this.addGlobalClass();
  }

  private addGlobalClass() {
    let bodyClassList = document.body.classList;
    if (this.chatState === 'in') {
      bodyClassList.add('main--chat');
    } else if (this.chatState === 'out') {
      bodyClassList.remove('main--chat');
    }
  }

  public ngOnDestroy() {
    SubscriptionUtils.safeUnsubscribe(this.openChatSubscription);
    SubscriptionUtils.safeUnsubscribe(this.closeChatSubscription);
    SubscriptionUtils.safeUnsubscribe(this.messageCountSub);
    SubscriptionUtils.safeUnsubscribe(this.openGroupChatSubscription);
    SubscriptionUtils.safeUnsubscribeSubject(this.subSubject);
    clearInterval(this.blinkInterval);
  }
}
