import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {MapPOIEntry} from './model/map-poi.entry';
import {HttpClient, HttpParams} from '@angular/common/http';
import {PoiGroupMapEntry} from './model/poi-group-map.entry';
import {KmlUrisEntry} from './model/kml-uris.entry';
import {HttpUtils} from '../../../../../core/page/commons/http.utils';

@Injectable()
export class MapPOIService {

  constructor(private http: HttpClient) {
  }

  public getPOIGroups(language: string): Observable<PoiGroupMapEntry[]> {
    let params = new HttpParams();
    params = HttpUtils.appendParam(params, 'language', language)
    return this.http.get<PoiGroupMapEntry[]>('/api/poi/map-groups', {params: params, headers: {skip: 'true'}});
  }

  public getGroupPOIs(poiGroupId: number): Observable<MapPOIEntry[]> {
    return this.http.get<MapPOIEntry[]>('/api/poi/map-groups/' + poiGroupId, {headers: {skip: 'true'}});
  }

  public getKmlUris(): Observable<KmlUrisEntry> {
    return this.http.get<KmlUrisEntry>('/api/kml/uris', {headers: {skip: 'true'}});
  }
}
