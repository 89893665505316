export enum Type {
  RESTING, DRIVER_AVAILABLE, WORK, DRIVE
}

export class LatestActivityEntry {
  constructor(public type: Type,
              public latestActivityStart: string,
              public latestActivityDuration: string,
              public driveBeforeBreak: string,
              public drivingLeft: string,
              public nextDayRest: string) {
  }
}

export interface LatestActivityEntry {
  type: Type;
  latestActivityStart: string;
  latestActivityDuration: string;
}
