import {Injectable} from '@angular/core';
import {Subject, Observable} from 'rxjs';
import {TrackDetailEntry} from '../../model/track-detail.entry';
import {TrackEntry} from '../../../pages/client/vehicles/vehicle-track/track.entry';

interface TrackDetailInitiator {
  data: TrackDetailEntry;
  initiator: string;
}

interface GpsTimeInitiator {
  data: string;
  initiator: string;
}

@Injectable()
export class TrackMapEventsService {

  private appendRouteSource = new Subject<TrackEntry>();
  private appendRoute$ = this.appendRouteSource.asObservable();

  private appendAlternativeRouteSource = new Subject<Array<TrackDetailEntry>>();
  private appendAlternativeRoute$ = this.appendAlternativeRouteSource.asObservable();

  private clearRouteSource = new Subject<void>();
  private clearRoute$ = this.clearRouteSource.asObservable();

  private clearAlternativeRouteSource = new Subject<string>();
  private clearAlternativeRoute$ = this.clearAlternativeRouteSource.asObservable();

  private showTrackDetailOnMapSource = new Subject<TrackDetailInitiator>();
  public showTrackDetailOnMap$ = this.showTrackDetailOnMapSource.asObservable();

  private showChartDetailOnMapSource = new Subject<string>();
  public showChartDetailOnMap$ = this.showChartDetailOnMapSource.asObservable();

  private chartStateSource = new Subject<any>();
  public chartState$ = this.chartStateSource.asObservable();

  private chartFromTillSource = new Subject<any>();
  public chartFromTillState$ = this.chartFromTillSource.asObservable();

  private prepareTrackPDFSource = new Subject<any>();
  public prepareTrackPDF$ = this.prepareTrackPDFSource.asObservable();

  private trackPDFPreparationDoneSource = new Subject<void>();
  public trackPDFPreparationDone$ = this.trackPDFPreparationDoneSource.asObservable();

  private trackMapRouteClickedSource = new Subject<GpsTimeInitiator>();
  public trackMapRouteClicked$ = this.trackMapRouteClickedSource.asObservable();

  private drawChartSource = new Subject<void>();
  public drawChart$ = this.drawChartSource.asObservable();

  constructor() {
  }

  public routeAppends(): Observable<TrackEntry> {
    return this.appendRoute$;
  }

  public appendRoute(details: TrackEntry) {
    this.appendRouteSource.next(details);
  }

  public getTrackDetail(): Observable<TrackDetailInitiator> {
    return this.showTrackDetailOnMap$;
  }

  public showTrackDetail(detail: TrackDetailEntry, initiator: string) {
    this.showTrackDetailOnMapSource.next({
      data: detail,
      initiator: initiator
    });
  }

  public getChartDetail(): Observable<string> {
    return this.showChartDetailOnMap$;
  }

  public showChartDetail(detail: string) {
    this.showChartDetailOnMapSource.next(detail);
  }

  public routeClears(): Observable<void> {
    return this.clearRoute$;
  }

  public clearRoute() {
    this.clearRouteSource.next();
  }

  public alternativeRouteAppends(): Observable<Array<TrackDetailEntry>> {
    return this.appendAlternativeRoute$;
  }

  public appendAlternativeRoute(details: Array<TrackDetailEntry>) {
    this.appendAlternativeRouteSource.next(details);
  }

  public alternativeRouteClears(): Observable<string> {
    return this.clearAlternativeRoute$;
  }

  public clearAlternativeRoute(initiator: string) {
    this.clearAlternativeRouteSource.next(initiator);
  }

  public canChartOpen(state: boolean) {
    this.chartStateSource.next(state)
  }

  public canChart(): Observable<any> {
    return this.chartState$;
  }

  public canChartFromTill(state: any) {
    this.chartFromTillSource.next(state)
  }

  public getCanChartFromTill(): Observable<any> {
    return this.chartFromTillState$;
  }

  public prepareTrackPDFSubscription(): Observable<any> {
    return this.prepareTrackPDF$;
  }

  public prepareTrackPDF(trackInfo: TrackEntry, action: string) {
    this.prepareTrackPDFSource.next({trackInfo: trackInfo, action: action});
  }

  public trackPDFPreparationDoneSubscription(): Observable<any> {
    return this.trackPDFPreparationDone$;
  }

  public trackPDFPreparationDone() {
    this.trackPDFPreparationDoneSource.next();
  }

  public trackMapRouteClickedSubscription(): Observable<GpsTimeInitiator> {
    return this.trackMapRouteClicked$;
  }

  public trackMapRouteClicked(gpsUploadTime: string, initiator: string) {
    this.trackMapRouteClickedSource.next({
      data: gpsUploadTime,
      initiator: initiator
    });
  }

  public drawChartTrigger() {
    this.drawChartSource.next();
  }

  public drawChartListener(): Observable<any> {
    return this.drawChart$;
  }

}
