import {OlLegPoint} from './ol-leg-point';
import {v4 as UUID} from 'uuid';
import {TollsEntry} from '../../../pages/client/tasks/tasks-manager/tasks-queue/model/tolls/tolls.entry';

export class OlRouteLeg {
  constructor(public origin: OlLegPoint,
              public destination: OlLegPoint,
              public waypoints: OlLegPoint[],
              public polyline: string[],
              public distance: number,
              public duration: number,
              public tolls: TollsEntry,
              public uuid: string) {
  }

  public static empty() {
    return new OlRouteLeg(
      null,
      null,
      [],
      null,
      null,
      null,
      null,
      UUID()
    )
  }
}
