import {Injectable} from '@angular/core';
import {Subject, Observable} from 'rxjs';
import {TachoState} from '../../../pages/client/monitor/tacho/tacho-state';
import {HttpClient} from '@angular/common/http';
import {PanelTachoSettings} from './model/panel-tacho-settings';
import {LatestVehicleEntry} from '../latest-vehicles-info/model/latest-vehicle-entry';

@Injectable()
export class ControlPanelService {

  private checkedVehiclesSource = new Subject<Array<number>>();
  public checkedVehicles$ = this.checkedVehiclesSource.asObservable();

  private vehicleClickedSource = new Subject<number>();
  public vehicleClicked$ = this.vehicleClickedSource.asObservable();

  private vehicleInfoClickedSource = new Subject<LatestVehicleEntry>();
  public vehicleInfoClicked$ = this.vehicleInfoClickedSource.asObservable();

  private tachoClickedSource = new Subject<TachoState>();
  public tachoClicked$ = this.tachoClickedSource.asObservable();

  private selectVehicleIdSource = new Subject<number>();
  public selectVehicleId$ = this.selectVehicleIdSource.asObservable();

  private fitleredVehiclesSource = new Subject<Array<any>>();
  public filteredVehicles$ = this.fitleredVehiclesSource.asObservable();

  private resizePanelSource = new Subject<number>();
  public resizePanelState$ = this.resizePanelSource.asObservable();

  private markAllSource = new Subject<void>();
  public markAll$ = this.markAllSource.asObservable();

  constructor(private http: HttpClient) {
  }

  public checkedVehicles(vehicleIds: Array<number>) {
    this.checkedVehiclesSource.next(vehicleIds);
  }

  public clickedVehicle(vehicle: LatestVehicleEntry) {
    this.vehicleClickedSource.next(vehicle.id);
    this.vehicleInfoClickedSource.next(vehicle);
  }

  public clickedTacho(state: TachoState) {
    this.tachoClickedSource.next(state);
  }

  public selectVehicleId(id: number) {
    this.selectVehicleIdSource.next(id);
  }

  public filteredVehicles(vehicles: any[]) {
    this.fitleredVehiclesSource.next(vehicles);
  }

  public groups(vehicleId: number): Observable<string[]> {
    return this.http.get<string[]>('/api/vehicle-groups/groups/' + vehicleId);
  }

  public resizePanel(panelWidth: number) {
    this.resizePanelSource.next(panelWidth);
  }

  public markAll(): void {
    this.markAllSource.next();
  }

  public getTachoSettings(): Observable<PanelTachoSettings> {
    return this.http.get<PanelTachoSettings>('/api/configs/tacho-settings');
  }

}
