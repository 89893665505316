<div class="chat-container" id="chat-m-container">
  <form (ngSubmit)="filterMessages()" [formGroup]="filter" novalidate="novalidate" class="chat__filter form--soft">
    <div class="input-group">
      <a (click)="closeFilter()" class="chat-header__button" *ngIf="filterOn">
        <i class="ico-close"></i>
      </a>
      <app-date-time-picker id="from" class="chatDateFrom"
                            [controlName]="'dateFrom'"
                            [form]="filter"
                            [pickerType]="'DATE'"
                            [placement]="'bottom'"
                            [requiresSmallInput]="false"
                            [placeHolder]="'chat.messages.dateFrom' | translate"
                            (datePicked)="filterMessages()">
      </app-date-time-picker>
      <app-date-time-picker id="till" class="chatDateTill"
                            [controlName]="'dateTill'"
                            [form]="filter"
                            [pickerType]="'DATE'"
                            [placement]="'bottom-right'"
                            [requiresSmallInput]="false"
                            [placeHolder]="'chat.messages.dateTill' | translate"
                            (datePicked)="filterMessages()">
      </app-date-time-picker>
      <input type="text" name="textSearch" id="textSearch"
             formControlName="textSearch"
             class="form-control chatTextSearch"
             appAutocompleteOff />
      <button class="chat-header__button" role="button" type="submit">
        <i class="fa fa-search"></i>
      </button>
      <button class="chat-header__button datePicker" (click)="expandInput()">
        <i class="fa fa-calendar"></i>
      </button>
      <div class="files-filter">
        <input type="checkbox"
               class="graphic-control"
               id="hideAttachments"
               [checked]="hideMessagesWithFiles"
               (click)="applyFilesFilter($event.target.checked)">
        <label for="hideAttachments"
               title="{{'chat.messages.files-filter' | translate}}">
        </label>
      </div>
    </div>
  </form>

  <div class="chat__content filter-results message-list"
       *ngIf="filterOn"
       [ngClass]="{'results-visible': filterOn}"
       infinite-scroll
       [infiniteScrollUpDistance]="scrollUpDistance"
       [infiniteScrollDistance]="scrollDownDistance"
       [infiniteScrollThrottle]="throttle"
       [scrollWindow]="false"
       (scrolled)="loadMoreFilterMessages()">
    <div class="no-results" *ngIf="messagesToShow.length === 0">{{"chat.messages.no-messages" | translate}}</div>
    <div *ngFor="let message of messagesToShow" class="message">
      <div class="message-container" [ngClass]="{'message-container--driver': message.senderType == 'DRIVER', 'message-container--manager': message.senderType == 'MANAGER'}">
        <div class="msg__user-type">
          <i class="chat-user-img"
             [ngClass]="{'ico-wheel': message.senderType == 'DRIVER',
                         'ico-user': message.senderType == 'MANAGER'}"></i>
        </div>
        <div class="message-body init-message filtered-message" (click)="findAndScrollTo(message)">
          <div class="msg__heading">
            <div class="msg__user-info" title="{{message.sender}} {{message.senderPhoneNumber}}">
              <h6 class="msg__user-name">
                {{message.sender}}
              </h6>
              <p class="msg__user-phone">
                {{message.senderPhoneNumber}}
              </p>
            </div>
          </div>
          <div class="message-text">
            <pre>
              {{message.fileType === MessageFileType.VOICE_MESSAGE
                ? ('chat.messages.voiceMessage' | translate)
                : message.messageText}}
            </pre>
            <a class="message-text__action"
               *ngIf="message.file != ''"
               (click)="showFile(message, $event)">
              <i class="ico-paperclip"></i>
            </a>
          </div>
          <ng-container *ngIf="showingFile === message.id">
            <img id="image-{{message.id}}"
                 class="scan-doc collapse"
                 src="{{fileUri + message.file}}"
                 *ngIf="message.file != '' && isPicture(message)"
                 (click)="showModal(message.id)">
            <app-picture-modal *ngIf="modalVisible === message.id"
                               (close)="closeModal()"
                               (download)="downloadFile(message.vehicleId, message.fileId)"
                               (downloadAsPdf)="downloadPictureAsPdf(message.vehicleId, message.file, message.fileId)"
                               [isPDFDownloadVisible]="true"
                               [fileUri]="fileUri"
                               [content]="message.file"
                               [caption]="message.messageText">
            </app-picture-modal>
          </ng-container>
          <div class="messages-info">
            <div class="messages-info__item">
              <i *ngIf="message.sentOn != null"
                 class="ico-send"
                 title="{{'chat.messages.sentOn' | translate}}">
              </i>
              <span>{{message.sentOn | date: 'yyyy-MM-dd HH:mm'}}</span>
            </div>
            <div class="messages-info__item">
              <i *ngIf="message.receivedOn != null"
                 class="ico-mail"
                 title="{{'chat.messages.receivedOn' | translate}}">
              </i>
              <span>{{message.receivedOn | date: 'yyyy-MM-dd HH:mm'}}</span>
            </div>
            <div class="messages-info__item" *ngIf="didEmployeeSaw(message) && message.senderType == 'DRIVER'">
              <i class="ico-mail-open" title="{{'chat.messages.seen' | translate}}">
              </i>
              <span>{{getSeenOn(message) | date: 'yyyy-MM-dd HH:mm'}}</span>
            </div>
            <div class="messages-info__item" *ngIf="message.openedOn != null && message.senderType == 'MANAGER'">
              <i class="ico-mail-open" title="{{'chat.messages.seen' | translate}}">
              </i>
              <span>{{message.openedOn | date: 'yyyy-MM-dd HH:mm'}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="chat__content message-list" id="message-m-list"
       [ngClass]="{'message-list--file': file}"
       infinite-scroll
       [infiniteScrollUpDistance]="scrollUpDistance"
       [infiniteScrollDistance]="scrollDownDistance"
       [infiniteScrollThrottle]="throttle"
       [scrollWindow]="false"
       (scrolledUp)="getMessages()"
       (scrolled)="addMessages()">

    <div *ngFor="let message of messagesToShow" class="message">

      <span *ngIf="lastReadMessageId && message.id === lastReadMessageId" class="last-message-divider">
        {{'chat.messages.new-messages' | translate}}
      </span>

      <div class="message-container" [ngClass]="{'message-container--driver': message.senderType == 'DRIVER', 'message-container--manager': message.senderType == 'MANAGER'}">
        <div class="msg__user-type">
          <i *ngIf="message.drawNewBox"
             class="chat-user-img"
             [ngClass]="{'ico-wheel': message.senderType == 'DRIVER',
                         'ico-user': message.senderType == 'MANAGER'}"></i>
        </div>
        <div class="message-body"
             [ngClass]="{'init-message': message.drawNewBox, 'loaded-message': message.id === filteredMessage}"
             id="message-{{message.id}}">
          <div class="msg__heading" *ngIf="message.drawNewBox">
            <div class="msg__user-info" title="{{message.sender}} {{message.senderPhoneNumber}}">
              <h6 class="msg__user-name">
                {{message.sender}}
              </h6>
              <p class="msg__user-phone">
                {{message.senderPhoneNumber}}
              </p>
            </div>
            <div id="readBy-{{message.id}}" class="read-by" *ngIf="message.senderType == 'DRIVER'">
              <div *ngIf="didEmployeeSaw(message)">
                <span title="{{'chat.messages.readBy' | translate}}">{{getEmployeeName(message)}}</span>
                <a class="fa fa-flag faa-tada animated-hover"
                   (click)="markAsUnread(message)"></a>
              </div>
              <i class="cursor-pointer fa fa-eye"
                 *ngIf="message.employeeRead.length > 0"
                 title="{{getReadTooltip(message.employeeRead)}}" >
              </i>
            </div>
          </div>
          <div class="message-text">
            <pre>
              {{message.fileType === MessageFileType.VOICE_MESSAGE
                ? ('chat.messages.voiceMessage' | translate)
                : message.messageText}}
            </pre>
            <div class="driver-seen"
                 *ngIf="message.id == lastSeenMessage && message.senderType == 'MANAGER'">
              <i class="fa fa-eye"></i>
            </div>
            <a class="message-text__action"
               *ngIf="message.file != '' && (isPicture(message) || message.fileType === MessageFileType.VOICE_MESSAGE)"
               (click)="showFile(message)">
              <i [ngClass]="message.fileType === MessageFileType.VOICE_MESSAGE
                            ? 'fa fa-microphone'
                            : 'ico-paperclip'">
              </i>
            </a>
            <span class="message-text__action"
                  *ngIf="message.file != '' && !isPicture(message) && message.senderType == 'MANAGER'"
                  (click)="downloadFile(message.vehicleId, message.fileId)">
              <i class="fa fa-cloud-download"></i>
            </span>
          </div>
          <ng-container *ngIf="showingFile === message.id">
            <ng-container [ngSwitch]="message.fileType">
              <ng-container *ngSwitchCase="MessageFileType.DOCUMENT">
                <img id="image-{{message.id}}"
                     class="scan-doc collapse"
                     src="{{fileUri + message.file}}"
                     *ngIf="message.file != '' && isPicture(message)"
                     (click)="showModal(message.id)">
                <app-picture-modal *ngIf="modalVisible === message.id"
                                   (close)="closeModal()"
                                   (download)="downloadFile(message.vehicleId, message.fileId)"
                                   (downloadAsPdf)="downloadPictureAsPdf(message.vehicleId, message.file, message.fileId)"
                                   [isPDFDownloadVisible]="true"
                                   [fileUri]="fileUri"
                                   [content]="message.file"
                                   [caption]="message.messageText">
                </app-picture-modal>
              </ng-container>
              <ng-container *ngSwitchCase="MessageFileType.VOICE_MESSAGE">
                <div class="collapse" id="image-{{message.id}}">
                  <audio controls>
                    <source src="{{fileUri + message.file}}" type="audio/mpeg">
                    <source src="{{fileUri + message.file}}" type="audio/wav">
                    <source src="{{fileUri + message.file}}" type="audio/ogg">
                  </audio>
                </div>
              </ng-container>
            </ng-container>
          </ng-container>
          <div class="messages-info">
            <div class="messages-info__item">
              <i *ngIf="message.sentOn != null"
                 class="ico-send"
                 title="{{'chat.messages.sentOn' | translate}}">
              </i>
              <span>{{message.sentOn | date: 'yyyy-MM-dd HH:mm'}}</span>
            </div>
            <div class="messages-info__item">
              <i *ngIf="message.receivedOn != null"
                 class="ico-mail"
                 title="{{'chat.messages.receivedOn' | translate}}">
              </i>
              <span>{{message.receivedOn | date: 'yyyy-MM-dd HH:mm'}}</span>
            </div>
            <div class="messages-info__item" *ngIf="didEmployeeSaw(message) && message.senderType == 'DRIVER'">
              <i class="ico-mail-open" title="{{'chat.messages.seen' | translate}}">
              </i>
              <span>{{getSeenOn(message) | date: 'yyyy-MM-dd HH:mm'}}</span>
            </div>
            <div class="messages-info__item" *ngIf="message.openedOn != null && message.senderType == 'MANAGER'">
              <i class="ico-mail-open" title="{{'chat.messages.seen' | translate}}">
              </i>
              <span>{{message.openedOn | date: 'yyyy-MM-dd HH:mm'}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="isTabOffline()" class="alert alert-danger">
      <i class="ico-block sz-m mr-1"></i> <strong>{{'chat.messages.warning' | translate}}</strong>
      {{'chat.messages.tab-offline' | translate}}
    </div>
    <div *ngIf="inSwitzerland" class="alert alert-info switz" (click)="flushFiles()">
      <i class="fa fa-download"></i>
      <span class="ml-2">{{'chat.messages.switzerland' | translate}}</span>
    </div>
  </div>

  <div class="chat__input">
    <div class="chat-templates" *ngIf="messagesTemplatesVisible" id="chat-m-templates">
      <div *ngFor="let message of messagesTemplates"
           class="chat-templates__message"
           (click)="setMessage(message)">
         <span>{{message}}</span>
      </div>
      <div class="chat-templates__info--empty" *ngIf="messagesTemplates == null || messagesTemplates.length == 0">
        <small>{{'chat.messages.no-templates' | translate}}</small>
      </div>
      <div class="chat-templates__add" (click)="navigateToSettings()">
        <i class="fa fa-plus"></i>
        {{'chat.messages.create-templates' | translate}}
      </div>
    </div>
    <div class="uploaded-file" id="upload-m-file">
      <i class="ico-paperclip" *ngIf="file"></i>
      <input type="text" value="{{file.name}}" *ngIf="file" appAutocompleteOff>
      <i class="ico-close delete-file" (click)="removeFile()" *ngIf="file"></i>
    </div>
    <div class="input-group chat-text" id="chat-m-text">
      <textarea type="text"
                class="form-control"
                rows="1"
                id="text-m-input"
                placeholder="{{'chat.messages.text' | translate}}"
                maxlength="10000"
                (paste)="onPaste($event)"
                (keyup.shift.enter)="onKeyShiftEnter()"
                (keyup.enter)="onKeyEnter()"
                [(ngModel)]="newChatText">
      </textarea>
      <div class="input__buttons" id="text-buttons">
        <a class="input__button templates-toggle"
           title="{{'chat.messages.templates' | translate}}"
           (click)="showMessagesTemplates()"
           [ngClass]="{'input__button--selected': this.messagesTemplatesVisible}"
           id="messages-templates">
          <i class="fa fa-list-ul"></i>
        </a>
        <a class="input__button send" id="send-m-button"
           (click)="addChatItem()">
           <i class="fa fa-paper-plane"></i>
        </a>
        <div class="input__button fa fa-upload upload">
          <input id="upload"
                 class="file-upload-input"
                 type="file"
                 accept=".pdf,.txt,image/*,.xlsx,.xls,video/*"
                 (change)="fileChange(upload)"
                 #upload>
        </div>
      </div>
    </div>
  </div>
</div>
