import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {GroupEntry} from '../../model/group.entry';
import {IdValue} from '../../model/id-value';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class GroupService {

  constructor(private http: HttpClient) {
  }

  public groups(): Observable<GroupEntry[]> {
    return this.http.get<GroupEntry[]>('/api/group/groups');
  }

  public vehicleGroups(): Observable<IdValue<string>[]> {
    return this.http.get<IdValue<string>[]>('api/group/vehicle-groups');
  }

}
