<div class="picture-modal__container">
  <div class="picture-modal">
    <div class="picture-modal__previous" (click)="previousPicture()">
      <div class="ico-chevron-left modal-button navigation-icon" *ngIf="isArrowVisible" ></div>
    </div>
    <div class="picture-modal__next" *ngIf="isArrowVisible" (click)="nextPicture()">
      <div class="ico-chevron-right modal-button navigation-icon"></div>
    </div>

    <div class="picture-modal__caption">{{caption}}</div>

    <span class="picture-modal__close" *ngIf="isCloseVisible" (click)="closeModal()">
      <i class="ico-close"></i>
    </span>

    <div class="picture-modal__content">
      <pinch-zoom class="pinch"
                  *ngIf="isZoomAvailable"
                  backgroundColor="rgba(0,0,0,0.0)"
                  wheel="true">
        <img id="img-zoom"
             class="picture-modal-content img-animation rotate-{{rotation}}"
             src="{{getContent()}}">
      </pinch-zoom>
      <div id="img-no-zoom"
         *ngIf="!isZoomAvailable"
         class="picture-modal-content img-animation gridx"
         [ngStyle]="{'background-image':'url(' + getContent() + ')'}">
      </div>
    </div>

    <div class="picture-modal__buttons">
      <a class="modal-button" *ngIf="isRotateVisible" (click)="rotate()">
        <i class="fa fa-rotate-right"></i>
      </a>
      <a class="cursor-pointer modal-button" *ngIf="isDownloadVisible" (click)="downloadFile()">
        <i class="fa fa-cloud-download"></i>
      </a>
      <a class="modal-button" *ngIf="isPDFDownloadVisible" (click)="downloadFileAsPdf()">
        <i class="fa fa-file-pdf-o"></i>
      </a>
    </div>

  </div>
</div>
