import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class SMSCommandsService {

  constructor(private http: HttpClient) {
  }

  public sendCommand(vehicleId: number, command: any): Observable<any> {
    return this.http.put<any>('/api/command/' + vehicleId + '/' + command, {});
  }
}
