import {Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MessageEntry} from '../message.entry';
import {GlobalConstants} from '../../../global/global.constants';
import {NotificationService} from '../../../notification/notification.service';
import {GlobalService} from 'app/core/global/global.service';
import {Profile} from '../../../model/profile';
import {ChatUtils} from '../chat.utils';
import {GroupMessageService} from './group-message.service';
import {GroupEntry} from '../../../model/group.entry';
import {ChatService} from '../chat.service';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {SubscriptionUtils} from '../../../commons/subscription.utils';
import {Router} from '@angular/router';

@Component({
  selector: 'app-global-message',
  templateUrl: './group-message.component.html',
  styleUrls: ['../messages/chat-messages.component.scss']
})
export class GroupMessageComponent implements OnInit, OnDestroy {

  @ViewChild('upload', {static: true}) upload: ElementRef;

  @Input()
  public group: GroupEntry;

  @Input()
  public fileUri: string;

  private pictureExt = ['.png', '.jpg', '.gif', '.jpeg'];

  public messages: MessageEntry[] = [];
  public file: any;
  public modalVisible;

  public messagesTemplatesVisible = false;
  public messagesTemplates: string[];

  public loggedUser: Profile = <Profile>{};
  public newChatText = '';

  private subSubject = new Subject<boolean>();

  constructor(private notification: NotificationService,
              private globalService: GlobalService,
              private router: Router,
              private groupMessageService: GroupMessageService,
              private chatService: ChatService) {
  }

  public ngOnInit() {
    this.getLoggedProfile();
  }

  public addChatItem() {
    if (this.newChatText.trim() !== '' || this.file != null) {
      let sentOn = moment(new Date()).format('YYYY-MM-DD HH:mm');
      let newMessage = new MessageEntry(
          null,
          'MANAGER',
          this.loggedUser.fullName,
          null,
          null,
          sentOn,
          null,
          this.getMessageText(),
          '',
          null,
          null,
          false,
          ChatUtils.silentInterval(sentOn, this.messages),
        0,
        null);
      this.saveMessage(newMessage);
      if (window.innerWidth > GlobalConstants.MOBILE_WIDTH_BREAKPOINT) {
        this.resize();
      }
      this.newChatText = '';
    }
  }

  private saveMessage(newMessage: MessageEntry) {
    let vehicles = this.group == null ? [] : this.group.vehicles.map(vehicle => vehicle.id);
    if (this.file == null) {
      this.groupMessageService.sendMessages(vehicles, newMessage.messageText)
        .pipe(takeUntil(this.subSubject))
        .subscribe({
          next: (sentOn) => newMessage.sentOn = sentOn,
          error: () => this.notification.loadingError()
        });
      this.pushMessage(newMessage);
    } else {
      this.groupMessageService.saveMultipartMessage(
        this.group == null ? -1 : this.group.id,
        newMessage.messageText,
        this.file)
        .pipe(takeUntil(this.subSubject))
        .subscribe({
          next: (sentOn) => {
            newMessage.sentOn = sentOn;
            newMessage.file = ' ';
            this.removeFile();
          },
          error: (err) => {
            if (err.status === 415) {
              this.notification.error('global.error.unsupported-media-type');
            } else {
              this.notification.loadingError();
            }
          }
        });
      this.pushMessage(newMessage);
    }
  }

  private pushMessage(newMessage: MessageEntry) {
    this.messages.push(newMessage);
    ChatUtils.scrollDown('message-m-list');
  }

  public showMessagesTemplates(): void {
    this.messagesTemplatesVisible = !this.messagesTemplatesVisible;
    if (this.messagesTemplatesVisible) {
      this.chatService.fetchTemplateMessages(true)
        .pipe(takeUntil(this.subSubject))
        .subscribe({
          next: (messages) => {
            this.messagesTemplates = messages.sort();
            this.resize();
          },
          error: () => this.notification.loadingError()
        });
    }
    this.resize();
  }

  public setMessage(message: string): void {
    this.newChatText = message;
  }

  public navigateToSettings(): void {
    this.router.navigate(['/configs/data'], {state: {tab: 'chat'}});
  }

  private getLoggedProfile() {
    this.globalService.getLoggedProfile().subscribe(
      data => this.loggedUser = data,
      err => this.notification.loadingError()
    );
  }

  public showFile(message: MessageEntry, event?: any): void {
    if (event) {
      event.stopPropagation();
    }

    setTimeout(() => {
      let element = document.getElementById('image-' + message.id);
      if (element) {
        element.classList.toggle('collapse');
      }
    }, 200);
  }

  public showModal(id: number): void {
    this.modalVisible = id;
  }

  public closeModal(): void {
    this.modalVisible = null;
  }

  public fileChange(upload) {
    if (upload.files.length) {
      this.file = upload.files[0];
      this.resize();
    }
  }

  public removeFile(): void {
    this.file = null;
    this.upload.nativeElement.value = '';
    this.resize();
  }


  public isPicture(message: MessageEntry): boolean {
    let file = message.file;
    if (file.length > 3) {
      let extension = file.substring(file.lastIndexOf('.'), file.length);
      return this.pictureExt.includes(extension);
    } else {
      return false;
    }
  }

  private resize() {
    setTimeout(() => {
      ChatUtils.resize('message-g-list', 'text-g-input', 'text-buttons', 'upload-g-file', 'chat-g-templates');
    }, 0);
  }

  private getMessageText() {
    return this.newChatText !== '' ? this.newChatText : this.file.name;
  }

  public onPaste(event: any): void {
    const items = (event.clipboardData || event.originalEvent.clipboardData).items;
    for (const item of items) {
      if (item.type.indexOf('text') === 0) {
        let textarea = document.getElementById('text-g-input');
        textarea.style.height = '399px';
      }
    }
  }

  public ngOnDestroy() {
    SubscriptionUtils.safeUnsubscribeSubject(this.subSubject);
  }

}
