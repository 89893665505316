export enum NoticeType {
  FORM_FILLED = 'FORM_FILLED',
  EXTERNAL_TASK = 'EXTERNAL_TASK',
  SUB_TASK = 'SUB_TASK',
  BEHIND_SCHEDULE = 'BEHIND_SCHEDULE',
  OLD_STATUS = 'OLD_STATUS',
  FORM_DISCREPANCY = 'FORM_DISCREPANCY',
  NOTE_REMINDER = 'NOTE_REMINDER',
  SOS = 'SOS',
  TRIP_SHEET_FILLED = 'TRIP_SHEET_FILLED',
  EXPENSE_SHEET_FILLED = 'EXPENSE_SHEET_FILLED',
  VIOLATION = 'VIOLATION'
}
