import {Pageable} from '../pageable';
import {Sort} from '../sort';
import {Direction} from '../direction';
import {HttpParams} from '@angular/common/http';
import {HttpUtils} from './http.utils';


export class PageRestUtils {

  private constructor() {
  }

  public static appendPageableParams(pageable: Pageable, params: HttpParams): HttpParams {
    if (pageable.pageNumber !== Pageable.DEFAULT_PAGE_NUMBER) {
      params = HttpUtils.appendParam(params, 'page', String(pageable.pageNumber));
    }
    if (pageable.pageSize !== Pageable.DEFAULT_PAGE_SIZE) {
      params = HttpUtils.appendParam(params, 'size', String(pageable.pageSize));
    }
    if (pageable.sort) {
      params = this.appendSortParams(pageable.sort, params);
    }
    return params;
  }

  public static appendPageable(pageable: Pageable, params: HttpParams): HttpParams {
    if (pageable.pageNumber !== Pageable.DEFAULT_PAGE_NUMBER) {
      params = HttpUtils.appendParam(params, 'page', `${pageable.pageNumber}`);
    }
    if (pageable.pageSize !== Pageable.DEFAULT_PAGE_SIZE) {
      params = HttpUtils.appendParam(params, 'size', `${pageable.pageSize}`);
    }
    if (pageable.sort) {
      params = this.appendSortParams(pageable.sort, params);
    }
    return params;
  }

  private static appendSortParams(sort: Sort, params: HttpParams): HttpParams {
    sort.orders.forEach(order => {
      params = HttpUtils.appendParam(params, 'sort', `${order.property},${Direction[order.direction]}`);
    });
    return params;
  }

}
