import {Observable, Subject} from 'rxjs';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {QueueTailEntry} from './queueTailEntry';
import {debounceTime, switchMap} from 'rxjs/operators';

const TAILS_DEBOUNCE_TIME = 500;

@Injectable()
export class IntersectionsService {

  private intersectionStateSource = new Subject<boolean>();
  public intersectionState$ = this.intersectionStateSource.asObservable();

  private debouncedQueueTailSource = new Subject<number>();
  public queueTail$ = this.debouncedQueueTailSource.pipe(
    debounceTime(TAILS_DEBOUNCE_TIME),
    switchMap(vehicleId => this.getQueueTail(vehicleId))
  );

  private intersectionsSource = new Subject<number>();
  public intersections$ = this.intersectionsSource.asObservable();

  constructor(private http: HttpClient) {
  }

  public getIntersectionPolyline(vehicleId: number): Observable<string> {
    return this.http.get(`/api/tasks/${vehicleId}/intersection`, {responseType: 'text', headers: {skip: 'true'}});
  }

  public getQueueTail(vehicleId: number): Observable<QueueTailEntry> {
    return this.http.get<QueueTailEntry>(`/api/tasks/${vehicleId}/queue-tail`, {headers: {skip: 'true'}});
  }

  public triggerIntersectionState(isActive: boolean): void {
    this.intersectionStateSource.next(isActive);
  }

  public triggerQueueTail(vehicleId: number): void {
    this.debouncedQueueTailSource.next(vehicleId);
  }

  public triggerIntersections(vehicleId: number) {
    this.intersectionsSource.next(vehicleId);
  }
}
