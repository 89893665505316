export const environment = {
  production: true,
  url: 'https://uat.fleethand.com',
  versionCheckURL : 'https://uat.fleethand.com/version.json',
  google: {
    key: 'AIzaSyBeoV8aGwLnMQLMZ3Q4qbzU2F2a-8LaLK0'
  },
  sw: true,
  vapidPublicKey: 'BJ7rcGW2VpHc6Qxo-95CccvyM01S3I38mKjM_iHKcFDp3O0UIMI_jSQmEzdvwnd0zbhtdumOenC9b68g7immkfk',
  firebase: {
    apiKey: 'AIzaSyAXbuxtDPgf9cJ6ESRh6bwBUAwMdwrabs0',
    authDomain: 'fh-web-notifications.firebaseapp.com',
    databaseURL: 'https://fh-web-notifications.firebaseio.com',
    projectId: 'fh-web-notifications',
    storageBucket: '',
    messagingSenderId: '629659382011',
    appId: '1:629659382011:web:86bdef7076c2d2a8a2c05e'
  },
  captchaSiteKey: '6LfYwL0dAAAAADD11E7H8GSWlC38pW4RcZmjYi4S',
  name: 'UAT',
  sentry: true
};
