import {Injectable} from '@angular/core';
import {EMPTY, merge, Observable, timer} from 'rxjs';
import {LatestVehicleEntry} from './model/latest-vehicle-entry';
import {ControlPanelService} from '../control-panel/control-panel.service';
import {GlobalService} from '../../global/global.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {delay, map, retryWhen, share, switchMap} from 'rxjs/operators';
import {AuthStorage} from '../../auth/auth-storage';
import {HttpUtils} from '../../page/commons/http.utils';
import {TrackEntry} from '../../../pages/client/vehicles/vehicle-track/track.entry';
import {AssetsStateWebSocketService} from '../../ws/assets-state/assets-state-web-socket.service';
import {StatusEntry} from './model/status-entry';

@Injectable()
export class LatestVehiclesInfoService {

  private infos: Observable<LatestVehicleEntry[]>;
  private isClient: boolean;
  public showTrailers: Boolean = false;

  private static readonly REFRESH_INTERVAL = 10000;

  constructor(private http: HttpClient,
              private controlPanelService: ControlPanelService,
              private globalService: GlobalService,
              public assetsStateWsService: AssetsStateWebSocketService) {

    this.isClient = AuthStorage.isLoggedClientEmployee();
    this.infos = merge(
      this.globalService.newLatestSubs(), // todo ignas remov latest
      timer(0, 60000)
    ).pipe(
      switchMap(() => this.tryFetchVehicles(this.showTrailers, this.isClient)),
      retryWhen(delay(LatestVehiclesInfoService.REFRESH_INTERVAL)),
      share()
    );

  }

  public vehicleInfos(): Observable<LatestVehicleEntry[]> {
    if (this.assetsStateWsService.ASWSenabled) {
      return this.assetsStateWsService.latestVehicles$;
    } else {
      return this.infos;
    }
  }

  public setShowTrailers(showTrailers: Boolean): void {
    this.showTrailers = showTrailers;
  }

  private tryFetchVehicles(showTrailers: Boolean, isClient: boolean): Observable<LatestVehicleEntry[]> {
    return this.fetchVehicles(showTrailers, isClient);
  }

  private fetchVehicles(showTrailers: Boolean, isClient: boolean): Observable<LatestVehicleEntry[]> {
    let vehicleGroup = AuthStorage.getUserVehicleGroups();
    let params = HttpUtils.appendParam(new HttpParams(), 'showTrailers', showTrailers);
    if (isClient && vehicleGroup.length > 0) {
      return this.fetchByGroups(vehicleGroup.map(item => item.id), params);
    } else {
      let vehicleIds = isClient ? AuthStorage.getUserGroup().vehicles.map(it => it.id) : [];
      return this.fetchByVehicles(vehicleIds, params);
    }
  }

  private fetchByGroups(vehicleGroupIds, params: HttpParams) {
    return this.http.post(`/api/latest-vehicles/group`, vehicleGroupIds, {params: params})
      .pipe(map(this.toLatestVehicleEntries()));
  }

  private fetchByVehicles(vehicleIds, params: HttpParams) {
    return this.http.post(`/api/latest-vehicles`, vehicleIds, {params: params})
      .pipe(map(this.toLatestVehicleEntries()));
  }

  private toLatestVehicleEntries() {
    return res => {
      let entries = <LatestVehicleEntry[]>res;
      return entries.map(entry => new LatestVehicleEntry(entry));
    };
  }

  public partnerTrackInfos(): Observable<LatestVehicleEntry[]> {
    return this.http.get('/api/latest-vehicles/partner-track')
      .pipe(map(res => {
        let entries = <LatestVehicleEntry[]>res;
        return entries.map(entry => new LatestVehicleEntry(entry));
      }));
  }

  public periodGPSMoveData(id: number, filter: any): Observable<any> {
    let params = {
      'from': filter.timeFilter.from,
      'till': filter.timeFilter.till,
    };

    return this.http.get(`/api/vehicles/${id}/simple-track`, {params: params});
  }

  public getTrack(id: number, filter: any): Observable<TrackEntry> {
    let params = {
      'from': filter.timeFilter.from,
      'till': filter.timeFilter.till,
    };
    return this.http.get<TrackEntry>(`/api/vehicles/${id}/track`, {params: params});
  }

  public tapaStatus(vehicleId: number): Observable<StatusEntry> {
    return this.http.get<StatusEntry>(`/api/vehicles/${vehicleId}/tapa`);
  }
}
