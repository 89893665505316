import {Injectable} from '@angular/core';
import {Observable, Subject, BehaviorSubject} from 'rxjs';
import {ChatVehicleListEntry} from './chat-vehicle-list.entry';
import {map} from 'rxjs/operators';
import {HttpClient, HttpParams} from '@angular/common/http';
import {TranslateService} from '@ngx-translate/core';
import {PicturePdfRequest} from './scan-list/picture-pdf.request';
import {HttpUtils} from '../../page/commons/http.utils';
import {AssetsStateWebSocketService} from '../../ws/assets-state/assets-state-web-socket.service';
import {GroupEntry} from '../../model/group.entry';
import {TemplateMessageEntry} from './model/template-message.entry';

@Injectable()
export class ChatService {

  private chatStateSource = new BehaviorSubject<string>('out');
  public chatState$ = this.chatStateSource.asObservable();

  private openChatSource = new Subject<ChatVehicleListEntry>();
  public openChat$ = this.openChatSource.asObservable();

  private openGroupChatSource = new Subject<GroupEntry>();
  public openGroupChat$ = this.openGroupChatSource.asObservable();

  private closeChatSource = new Subject<any>();
  public closeChat$ = this.closeChatSource.asObservable();

  private closeMessagesSource = new Subject<any>();
  public closeMessages$ = this.closeMessagesSource.asObservable();

  private openScanListSource = new Subject<ChatVehicleListEntry>();
  private openScanList$ = this.openScanListSource.asObservable();

  private chatActiveSource = new Subject<boolean>();
  private chatActive$ = this.chatActiveSource.asObservable();

  private audio = new Audio();

  constructor(private http: HttpClient,
              private assetsStateWsService: AssetsStateWebSocketService,
              private translate: TranslateService) {

    this.audio.src = '/assets/sounds/message.mp3';
    this.audio.load();
  }

  public closeMessages() {
    this.closeMessagesSource.next(true);
  }

  public openChat(vehicleId: number,
                  plateNumber: string,
                  driverName: string,
                  tabOffline: boolean): void {
    this.openChatSource.next(new ChatVehicleListEntry(vehicleId, plateNumber, driverName, tabOffline));
  }

  public openGroupChat(vehiclesList: GroupEntry) {
    this.openGroupChatSource.next(vehiclesList);
  }

  public closeChat() {
    this.closeChatSource.next(true);
  }

  public changeState(state: string) {
    this.chatStateSource.next(state);
  }

  public getCurrentState(): string {
    return this.chatStateSource.getValue();
  }

  public playMessageSound() {
    this.audio.play();
  }

  public triggerChatToggle(chatActive: boolean) {
    this.chatActiveSource.next(chatActive);
  }

  public toggleChat(): Observable<boolean> {
    return this.chatActive$;
  }

  public getChatVehicleList(): Observable<ChatVehicleListEntry[]> {
    return this.http.get<ChatVehicleListEntry[]>(`/api/chat/vehicle-list?aswsEnabled=${this.assetsStateWsService.ASWSenabled}`);
  }

  public fetchTemplateMessages(isGroupMessages: boolean): Observable<string[]> {
    return this.http.get<string[]>(`/api/chat/template-messages?isGroupMessages=${isGroupMessages}`);
  }

  public fetchAllTemplateMessages(): Observable<TemplateMessageEntry[]> {
    return this.http.get<TemplateMessageEntry[]>('/api/chat/all-template-messages')
  }

  public saveTemplateMessage(message: TemplateMessageEntry): Observable<number> {
    return this.http.post<number>('/api/chat/save-template-message', message)
  }

  public deleteMessage(messageId: number): Observable<void> {
    return this.http.delete<void>(`/api/chat/template-message?messageId=${messageId}`)
  }

  public getChatFilesUri(): Observable<string> {
    return this.http.get('/api/chat/file-uri', {responseType: 'text', headers: {skip: 'true'}});
  }

  public isInSwitzerland(vehicleId: number): Observable<boolean> {
    return this.http.get<boolean>(`/api/chat/switzerland?vehicle=${vehicleId}`);
  }

  public flushFiles(vehicleId: number): Observable<any> {
    return this.http.get(`/api/chat/flush?vehicle=${vehicleId}`);
  }

  public generateToken(vehicleId: number, fileId: number): Observable<any> {
    return this.http.get(`/api/chat/${fileId}/download/token?vehicleId=${vehicleId}`);

  }

  public getPicturesAsPdf(token: string, request: PicturePdfRequest[], plateNumber: string): Observable<Blob> {
    let params = new HttpParams();
    params = HttpUtils.appendParam(params, 'locale', this.translate.currentLang);
    params = HttpUtils.appendParam(params, 'plateNumber', plateNumber);
    return this.http.post(`/api/chat-files/${token}/pictures-pdf/`, request, {
      responseType: 'blob',
      params: params
    })
      .pipe(map(res => <Blob>res));
  }

  public triggerOpenScanList(data: ChatVehicleListEntry) {
    this.openScanListSource.next(data);
  }

  public openScanList(): Observable<ChatVehicleListEntry> {
    return this.openScanList$;
  }

  public downloadFile(token: string, plateNumber: string): Observable<Blob> {
    return this.http.get(`/api/chat-files/${token}/${plateNumber}/download`, {responseType: 'blob'})
  }
}
