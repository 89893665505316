import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {LatestVehicleEntry} from '../latest-vehicles-info/model/latest-vehicle-entry';

@Injectable()
export class DispatcherService {

  private dispatcherStateSource = new BehaviorSubject<string>('none');
  public dispatcherState$ = this.dispatcherStateSource.asObservable();

  private dispatcherPreviewTaskSource = new Subject<LatestVehicleEntry>();
  private dispatcherPreviewTask$ = this.dispatcherPreviewTaskSource.asObservable();

  constructor() {
  }

  public dispatcherState(data: string): void {
    this.dispatcherStateSource.next(data);
  }

  public dispatchersStates(): Observable<string> {
    return this.dispatcherState$;
  }

  public dispatcherPreviewTask(data: LatestVehicleEntry) {
    this.dispatcherPreviewTaskSource.next(data);
  }

  public dispatcherPreviewedTask(): Observable<LatestVehicleEntry> {
    return this.dispatcherPreviewTask$;
  }
}
