import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {GeoHistoryFilter, SearchStatus} from './geo-history.filter';

@Injectable()
export class GeoHistoryService {

  constructor(private http: HttpClient) {
  }

  public orderGeoHistorySearch(filter: GeoHistoryFilter): Observable<number> {
    return this.http.post<number>('/api/geo-history', filter);
  }

  public fetchStatus(orderId: number): Observable<SearchStatus> {
    return this.http.get<SearchStatus>('/api/geo-history/status?orderId=' + orderId);
  }

  public fetchFoundVehicles(orderId: number): Observable<string[]> {
    return this.http.get<string[]>('/api/geo-history?orderId=' + orderId);
  }
}
