export enum TaskTypes {

  LOADING,
  UNLOADING,
  FUELLING,
  CUSTOMS,
  FERRY,
  TRAIN,
  TUNNEL,
  DOCUMENTS,
  DOCUMENT_EXCHANGE,
  MAIL,
  REST,
  REST_45H,
  PARKING,
  WASH,
  DRIVER_CHANGE,
  TRAILER_CHANGE,
  SERVICE,
  SERVICE_INNER,
  SERVICE_EXTERNAL,
  CONTAINER_CHANGE,
  CONTAINER_PICKUP,
  CONTAINER_DROPOFF,
  TRAILER_PICKUP,
  TRAILER_DROPOFF,
  PALLET_UNLOADING,
  PALLET_LOADING,
  RELOADING,
  REPACKAGING,
  CONTROL,
  VET_INSPECTION,
  BORDER_CROSSING,
  SCALES,
  OTHER

}

export let allSortedTaskTypes: string[] = [
  "LOADING",
  "UNLOADING",
  "FUELLING",
  "CUSTOMS",
  "FERRY",
  "TRAIN",
  "TUNNEL",
  "DOCUMENTS",
  "DOCUMENT_EXCHANGE",
  "MAIL",
  "REST",
  "REST_45H",
  "PARKING",
  "WASH",
  "DRIVER_CHANGE",
  "TRAILER_CHANGE",
  "SERVICE",
  "SERVICE_INNER",
  "SERVICE_EXTERNAL",
  "CONTAINER_CHANGE",
  "CONTAINER_PICKUP",
  "CONTAINER_DROPOFF",
  "TRAILER_PICKUP",
  "TRAILER_DROPOFF",
  "PALLET_UNLOADING",
  "PALLET_LOADING",
  "RELOADING",
  "REPACKAGING",
  "CONTROL",
  "VET_INSPECTION",
  "BORDER_CROSSING",
  "SCALES",
  "HAZARDOUS",
  "OTHER"
];
