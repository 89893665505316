<form [formGroup]="form" *ngIf="form">
  <div class="input-group" [ngClass]="{'narrow': narrow}">
    <input class="form-control picker-input"
           placeholder="{{getPlaceHolder()}}"
           autocomplete="{{autocomplete}}"
           name="dateInput"
           (blur)="changeStringToDateModel()"
           formControlName="{{controlName}}"
           [ngClass]="{'form-control-sm' : requiresSmallInput,
                       'required': isOrderForm && !isDateValid,
                       'narrow': narrow}">
    <ng-template #popContent>
      <div id="pop-out" class="calendar-popup">
        <ngb-datepicker *ngIf="pickerType === 'DATE_TIME' || pickerType === 'DATE'"
                        [minDate]="minDate"
                        [maxDate]="maxDate"
                        [(ngModel)]="date"
                        [ngModelOptions]="{standalone: true}"
                        id="date-picker"
                        (dateSelect)="dateSelected()">
        </ngb-datepicker>
        <div class="time-picker-row">
          <ngb-timepicker *ngIf="pickerType === 'TIME' || pickerType === 'DATE_TIME'"
                          [(ngModel)]="time"
                          (ngModelChange)="changeDateToString()"
                          [ngModelOptions]="{standalone: true}"
                          (click)="timePickerClicked()"
                          id="time-picker">
          </ngb-timepicker>
          <button class="form__action--tertiary" (click)="p.close()">
            {{'monitor.map.title.close' | translate}}
          </button>
        </div>
      </div>
    </ng-template>
    <button class="btn fa cursor-pointer input-group-addon"
            [ngClass]="{
              'fa-clock-o' : pickerType === 'TIME',
              'fa-calendar' : pickerType !=='TIME',
              'hidden': disabled,
              'icon-center' : iconCenter
             }"
            [ngbPopover]="popContent"
            type="button"
            popoverClass="date-pop-over"
            #p="ngbPopover"
            (click)="openCorrectMonth()"
            container="body">
    </button>
  </div>
</form>

