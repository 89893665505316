export class TasksConfigsForm {
  constructor(public enabledProPlanner: boolean,
              public enabledAddresses: boolean,
              public enabledDefaultWaypoints: boolean,
              public favoritePoisAlongRoute: boolean,
              public showTasksFormFirst: boolean,
              public enabledInfoCopy: boolean,
              public markedPaidRoadsTolls: boolean,
              public enabledOriginalSearchText: boolean,
              public sendDriverMessage: boolean,
              public additionalInfoIds: any) {
  }

  public static defaultValues(isClientEmployee: boolean): TasksConfigsForm {
    return new TasksConfigsForm(
      isClientEmployee,
      false,
      isClientEmployee,
      isClientEmployee,
      false,
      false,
      true,
      false,
      false,
      [-1, -2, -3, -4, -5, -6, -7]
    );
  }
}
